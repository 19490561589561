import React, { Component } from "react";
import { Table } from "semantic-ui-react";

class Strategy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    
    if (this.props.strategyHeader === "") return null;
    return (
      <Table
        compact="very"
        basic
        className={
          this.props.isPositiveStrategy ? "positiveborder" : "negativeborder"
        }
      >
        <Table.Header
          className={
            this.props.isPositiveStrategy
              ? "positivetableheader"
              : "negativetableheader"
          }
        >
          <Table.Row>
            <Table.HeaderCell colSpan="3">
              Trading Strategy - {this.props.strategyHeader}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.strategy.map((listValue, index) => {
            var style = "";
            if (this.props.isPositiveStrategy) {
              if (index === 0) {
                style = "positive";
              } else if (index === 1) style = "negative";
            } else {
              if (index === 0) {
                style = "negative";
              } else if (index === 1) style = "positive";
            }
            return (
              <Table.Row className={style} key={index}>
                <Table.Cell>
                  <b> {listValue}</b> <b className="bolditalicgreen">{index===1?this.props.triggerValue:""}</b>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }
}

export default Strategy;
