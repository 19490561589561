import React, { Component } from "react";
import { Modal, Button,  Header,Image } from "semantic-ui-react";
import { PieChart } from "react-easy-chart";

class CycleStatus extends Component {
  constructor(props) {
    super(props);
    this.state = this.props.data;
  }
  render() {
    if (this.props.data === undefined) return null;
    return <Modal size="tiny" trigger={// <Button basic>
          //   <PieChart
          //     size={80}
          //     data={[
          //       { key: "A", value: 25, color: "#da0000" },
          //       { key: "B", value: 25, color: "#da5858" },
          //       { key: "C", value: 25, color: "#9999da" },
          //       { key: "D", value: 25, color: "#5858da" }
          //     ]}
          //   />

          // </Button>
          <Button animated="vertical" basic>
            <Button.Content hidden>Cycle Status</Button.Content>
            <Button.Content visible>
              {/* <Icon name='shop' /> */}
          <Image src='../asset/images/cycle.jpg' size="tiny" />
            </Button.Content>
          </Button>} closeIcon>
        <Header icon="archive" content="Trading/Investing Cycle Status" />
        <Modal.Content>
          <PieChart labels styles={{ ".chart_text": { fontSize: "1.25em", fill: "#333" } }} data={this.props.data.objData} />
        </Modal.Content>
      </Modal>;
  }
}

export default CycleStatus;
