import React, { Component } from "react";
import {
  Grid,
  Dropdown,
  Modal,
  Button,
  Container,
  Icon,
  Dimmer,
  Loader,
  Segment,
  Radio,
  Header
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import moment from "moment";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";

import Report from "../Report";

import "react-datepicker/dist/react-datepicker.css";
//import 'ag-grid-root/dist/styles/ag-grid.css';
//import 'ag-grid-root/dist/styles/theme-fresh.css';

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
//var querystring = require("querystring");

const keys = require("../../../config/keys");
var udata = JSON.parse(window.sessionStorage.getItem("data"));
class DailyIndicator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      open: false,
      reportDate: moment(),
      selectedreporttype: "",
      colDefs: [
        {
          field: "code",
          headerName: "CODE",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true
        },
        { field: "name", headerName: "NAME" },
        { field: "exchange", headerName: "EXCHANGE" }
      ],
      rowSelection: "multiple",
      reportdata: [],
      reportCol: [
        {
          field: "ScripName",
          headerName: "Scrip Name",
          autoHeight: true,
          cellClass: "cell-wrap-text"
        },
        { field: "Close" },
        {
          field: "DailyCP",
          cellClassRules: {
            negative: function(params) {
              return !params.data.DailyCPIsPositive;
            },
            positive: function(params) {
              return params.data.DailyCPIsPositive;
            }
          }
        },
        {
          field: "Strategy",
          cellClassRules: {
            negative: function(params) {
              return !params.data.IsPositiveStrategy;
            },
            positive: function(params) {
              return params.data.IsPositiveStrategy;
            }
          }
        },
        {
          field: "StrategyA",
          cellClassRules: {
            negative: function(params) {
              return !params.data.IsPositiveStrategy;
            },
            positive: function(params) {
              return params.data.IsPositiveStrategy;
            }
          }
        },
        { field: "StrategyB" },
        {
          field: "WeeklyCP",
          cellClassRules: {
            negative: function(params) {
              return !params.data.WeeklyCPIsPositive;
            },
            positive: function(params) {
              return params.data.WeeklyCPIsPositive;
            }
          }
        },
        {
          field: "MonthlyCP",
          cellClassRules: {
            negative: function(params) {
              return !params.data.MonthlyCPIsPositive;
            },
            positive: function(params) {
              return params.data.MonthlyCPIsPositive;
            }
          }
        },
        {
          field: "LikelyTradingHighLow"
        }
      ],
      checked: false,
      radiovalue: ""
    };
  }
  componentDidMount() {
    udata = JSON.parse(window.sessionStorage.getItem("data"));
    var dt = moment();
    var fdate = moment(dt).format("DD/MM/YYYY");

    this.setState({
      reportDate: fdate,
      selectedReportDate: this.state.reportDate
    });
    axios
      .get(keys.ServicePath + "/api/GetFolders?userId=" + udata.data.Id)
      .then(res => {
        if (res.data.Success) {
          this.setState({ folders: res.data.folders });
        } else {
          console.log("else");
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  }
  show = () => {
    this.setState({ showLoader: true });
    var selectedscrip = [];
    var selectedscriptext = [];

    var hideTrendColumn = [];
    var fdate = moment(this.state.selectedReportDate).format("DD/MM/YYYY");

    this.setState({ hideTrendColumn: hideTrendColumn });
    this.gridApi.forEachNode(function(node) {
      if (node.isSelected()) {
        selectedscrip.push(node.data.code);
        selectedscriptext.push(node.data.name);
      }
    });

    var SelectedScripData = {
      scrips: selectedscrip,
      scripstext: selectedscriptext,
      dtDate: fdate,
      dbType: this.state.selectedreporttype,
      volumeindec: this.state.radiovalue
    };
    axios
      .post(
        keys.ServicePath + "/api/GetDailyIndicatorReport",
        SelectedScripData
      )
      .then(res => {
        if (res.data.Success) {
          this.setState({ reportdata: res.data.ReportData });
          this.setState({ open: true });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  close = () => this.setState({ open: false });
  hideloader = () => this.setState({ showLoader: false });
  handleChange = date => {
    this.setState({
      selectedReportDate: date,
      reportDate: moment(date).format("DD/MM/YYYY")
    });
  };
  handleradioChange = (e, { value }) => this.setState({ radiovalue: value });
  scripchnage = (e, { value }) => {
    this.setState({ selectedreporttype: value });
    axios
      .get(
        keys.ServicePath +
          "/api/GetScripForReport?type=" +
          value +
          "&userId=" +
          udata.data.Id
      )
      .then(res => {
        if (res.data.Success) {
          this.setState({ scriptlist: res.data.ScriptList });
        } else {
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  };
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }
  onSelectionChanged(event) {
    // var rowCount = event.api.getSelectedNodes().length;
    // window.alert("selection changed, " + rowCount + " rows selected");
  }

  render() {
    return (
      <Container>
        <Segment inverted attached="top" padded className="segmentheader">
          Report Criteria - Daily Indicator Report
        </Segment>
        <Segment attached padded className="whitebg">
          <Grid>
            <Grid.Row>
              <Grid.Column width={10}>
                <Dropdown
                  fluid
                  placeholder="Scrip"
                  search
                  selection
                  options={this.state.folders}
                  onChange={this.scripchnage}
                  value={this.state.selectedreporttype}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                Date :{" "}
                <DatePicker
                  className="ui input input"
                  dateFormat="DD/MM/YYYY"
                  selected={this.state.selectedReportDate}
                  onChange={this.handleChange}
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <Modal
                  size="fullscreen"
                  open={this.state.open}
                  onClose={this.close}
                  closeOnEscape={false}
                  closeOnDimmerClick={false}
                  closeIcon
                >
                  <Modal.Header>
                    {" "}
                    <Header as="h4" className="colorwhite">
                      {/* <Icon name="chart line" className="colorwhite" /> */}
                      Daily Indicator Report
                      <Header.Subheader className="colorwhite">
                        {this.state.selectedreporttype +
                          "| Date : " +
                          this.state.reportDate +
                          " | Type : " +
                          this.state.radiovalue.toUpperCase()}
                      </Header.Subheader>
                    </Header>
                  </Modal.Header>

                  <Modal.Content scrolling>
                    <Report
                      colDefs={this.state.reportCol}
                      rowdata={this.state.reportdata}
                      loader={this.hideloader}
                      hideColumns={this.state.hideTrendColumn}
                      sheetName={
                        this.state.reportDate + " | " + this.state.radiovalue
                      }
                      fileName={"Daily Indicator Report"}
                    />
                  </Modal.Content>
                </Modal>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className="grid_height_250  ag-theme-balham">
                  <AgGridReact
                    className="height240"
                    rowData={this.state.scriptlist}
                    enableSorting
                    floatingFilter={true}
                    enableFilter={true}
                    suppressColumnVirtualisation={true}
                    enableColResize={true}
                    columnDefs={this.state.colDefs}
                    onGridReady={this.onGridReady.bind(this)}
                    rowSelection={this.state.rowSelection}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Dimmer active={this.state.showLoader} inverted>
            <Loader size="massive" inverted>
              Generating Report
            </Loader>
          </Dimmer>
        </Segment>
        <Segment attached="bottom" padded className="whitebg">
          <Radio
            label="Positive"
            name="radioGroup"
            value="positive"
            checked={this.state.radiovalue === "positive"}
            onChange={this.handleradioChange}
          />
          <Radio
            label="Negative"
            name="radioGroup"
            value="negative"
            checked={this.state.radiovalue === "negative"}
            onChange={this.handleradioChange}
          />
          <Radio
            label="ALL"
            name="radioGroup"
            value="all"
            checked={this.state.radiovalue === "all"}
            onChange={this.handleradioChange}
          />
          <Button positive onClick={this.show} floated="right">
            <Icon name="file text" />
            Generate Report
          </Button>
        </Segment>
      </Container>
    );
  }
}

export default DailyIndicator;
