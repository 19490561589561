export const ADD = 'ADD';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SETUSERSTATE = 'SETUSERSTATE';
export const FETCHUSER = 'FETCHUSER';
export const USERAUTH = 'USERAUTH';
export const FETCHDESIGNATIONS = 'FETCHDESIGNATIONS';
export const FETCHUSERLIST = 'FETCHUSERLIST';
export const FETCHUSERBYID = 'FETCHUSERBYID';
export const UPLOADBSE = 'UPLOADBSE';
export const SPLITBONUS = 'SPLITBONUS';
export const SCRIPTLIST = 'SCRIPTLIST';
export const SCRIPTLISTNSE = 'SCRIPTLISTNSE';
export const SCRIPTLISTNSEOP = 'SCRIPTLISTNSEOP';
export const BSEDETAILS = 'BSEDETAILS';
export const NSEDETAILS = 'NSEDETAILS';
export const NSEOPDETAILS = 'NSEOPDETAILS';
export const LOADER = 'LOADER';
export const HIGHLOWTREND = 'HIGHLOWTREND';
export const ADDUSER = 'ADDUSER';
export const SAVEBROADCAST = "SAVEBROADCAST";

