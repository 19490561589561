import React from "react";
import PropTypes from "prop-types";

import { format } from "d3-format";
import { timeFormat } from "d3-time-format";

import { ChartCanvas, Chart } from "react-stockcharts";
import {
  BarSeries,
  CandlestickSeries,
  OHLCSeries,
  BollingerSeries,
  LineSeries,
  RSISeries,
  SARSeries
} from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import {
  CrossHairCursor,
 // EdgeIndicator,
  CurrentCoordinate,
  MouseCoordinateX,
  MouseCoordinateY
} from "react-stockcharts/lib/coordinates";

import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";
import {
  OHLCTooltip,
  MovingAverageTooltip,
  RSITooltip,
  SingleValueTooltip,
  BollingerBandTooltip
} from "react-stockcharts/lib/tooltip";
import {
  ema,
  rsi,
  sma,
  atr,
  bollingerBand,
  macd,
  elderImpulse,
  sar
} from "react-stockcharts/lib/indicator";
import { fitWidth } from "react-stockcharts/lib/helper";
import { last } from "react-stockcharts/lib/utils";

class CandleStickChartWithRSIIndicator extends React.Component {
  render() {
    const bbStroke = {
      top: "#964B00",
      middle: "#000000",
      bottom: "#964B00"
    };
    const accelerationFactor = 0.02;
    const maxAccelerationFactor = 0.2;
    const bbFill = "#4682B4";

    const ema26 = ema()
      .id(0)
      .options({ windowSize: 5 })
      .merge((d, c) => {
        d.ema26 = c;
      })
      .accessor(d => d.ema26);

    const ema12 = ema()
      .id(1)
      .options({ windowSize: 10 })
      .merge((d, c) => {
        d.ema12 = c;
      })
      .accessor(d => d.ema12);

    const smaVolume50 = sma()
      .id(3)
      .options({ windowSize: 50, sourcePath: "volume" })
      .merge((d, c) => {
        d.smaVolume50 = c;
      })
      .accessor(d => d.smaVolume50);

    const rsiCalculator = rsi()
      .options({ windowSize: 14 })
      .merge((d, c) => {
        d.rsi = c;
      })
      .accessor(d => d.rsi);

    const atr14 = atr()
      .options({ windowSize: 14 })
      .merge((d, c) => {
        d.atr14 = c;
      })
      .accessor(d => d.atr14);

    const bb = bollingerBand()
      .merge((d, c) => {
        d.bb = c;
      })
      .accessor(d => d.bb);

    const macdCalculator = macd()
      .options({
        fast: 12,
        slow: 26,
        signal: 9
      })
      .merge((d, c) => {
        d.macd = c;
      })
      .accessor(d => d.macd);

    const elderImpulseCalculator = elderImpulse()
      .macdSource(macdCalculator.accessor())
      .emaSource(ema12.accessor());

    const defaultSar = sar()
      .options({
        accelerationFactor,
        maxAccelerationFactor
      })
      .merge((d, c) => {
        d.sar = c;
      })
      .accessor(d => d.sar);

    const { type, data: initialData, width, ratio } = this.props;

    const calculatedData = defaultSar(
      elderImpulseCalculator(
        ema26(ema12(smaVolume50(rsiCalculator(atr14(bb(initialData))))))
      )
    );
    const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor(
      d => d.date
    );
    const { data, xScale, xAccessor, displayXAccessor } = xScaleProvider(
      calculatedData
    );

    const start = xAccessor(last(data));
    const end = xAccessor(data[Math.max(0, data.length - 150)]);
    const xExtents = [start, end];

    return (
      <ChartCanvas
        height={600}
        width={width}
        ratio={ratio}
        margin={{ left: 50, right: 70, top: 20, bottom: 30 }}
        type={type}
        seriesName="MSFT"
        data={data}
        xScale={xScale}
        xAccessor={xAccessor}
        displayXAccessor={displayXAccessor}
        xExtents={xExtents}
      >
        <Chart
          id={1}
          height={300}
          yExtents={[d => [d.high, d.low], ema26.accessor(), ema12.accessor()]}
          padding={{ top: 10, bottom: 20 }}
        >
          <XAxis
            axisAt="bottom"
            orient="bottom"
            showTicks={false}
            outerTickSize={0}
          />
          <YAxis axisAt="right" orient="right" ticks={5} />
          <MouseCoordinateY
            at="right"
            orient="right"
            displayFormat={format(".2f")}
          />
          {(this.props.chartType === undefined ||
            this.props.chartType === "Candlestick") && (
            <CandlestickSeries fill={d => d.close > d.open ? "#008000" : "#FF0000"}
           />
        )}
          {this.props.chartType === "ClosingPrice" && (
            <LineSeries yAccessor={d => d.close} strokeDasharray="Solid" />
          )}
          {this.props.chartType === "OHLC" && (
            <OHLCSeries
              // stroke={d => elderImpulseCalculator.stroke()[d.elderImpulse]}
            />
          )}
          <LineSeries yAccessor={ema26.accessor()} stroke={ema26.stroke()} />
          <LineSeries yAccessor={ema12.accessor()} stroke={ema12.stroke()} />
          {this.props.showStrategy === "Bollinger" && (
            <BollingerSeries
              yAccessor={d => d.bb}
              stroke={bbStroke}
              fill={bbFill}
            />
          )}
          <CurrentCoordinate
            yAccessor={ema26.accessor()}
            fill={ema26.stroke()}
          />
          <CurrentCoordinate
            yAccessor={ema12.accessor()}
            fill={ema12.stroke()}
          />
          {/* <EdgeIndicator
            itemType="last"
            orient="right"
            edgeAt="right"
            yAccessor={d => d.close}
            fill={d => (d.close > d.open ? "#008000" : "#FF0000")}
          /> */}
          {this.props.showStrategy === "SAR" && (
            <SARSeries yAccessor={d => d.sar} />
          )}
          {/* {this.props.chartType === "OHLC" && ( */}
          <OHLCTooltip origin={[-40, 0]} />){/* } */}
          {this.props.showEMA && (
            <MovingAverageTooltip
             // onClick={e => console.log(e)}
              origin={[-38, 15]}
              options={[
                {
                  yAccessor: ema26.accessor(),
                  type: "EMA",
                  stroke: ema26.stroke(),
                  windowSize: ema26.options().windowSize
                },
                {
                  yAccessor: ema12.accessor(),
                  type: "EMA",
                  stroke: ema12.stroke(),
                  windowSize: ema12.options().windowSize
                }
              ]}
            />
          )}
          {this.props.showStrategy === "Bollinger" && (
            <BollingerBandTooltip
              origin={[-38, 60]}
              yAccessor={d => d.bb}
              options={bb.options()}
            />
          )}
        </Chart>
        <Chart
          id={2}
          height={150}
          yExtents={[d => d.volume, smaVolume50.accessor()]}
          origin={(w, h) => [0, h - 400]}
        >
          <YAxis
            axisAt="left"
            orient="left"
            ticks={5}
            tickFormat={format(".2s")}
          />

          <MouseCoordinateY
            at="left"
            orient="left"
            displayFormat={format(".4s")}
          />

          {this.props.showStrategy === "Volumebar" && (
            <BarSeries
              yAccessor={d => d.volume}
              fill={d => (d.close > d.open ? "#008000" : "#FF0000")}
            />
          )}
          {/* <AreaSeries yAccessor={smaVolume50.accessor()} stroke={smaVolume50.stroke()} fill={smaVolume50.fill()} /> */}
        </Chart>
        {this.props.showRSI && (
          <Chart
            id={3}
            yExtents={[0, 100]}
            height={125}
            origin={(w, h) => [0, h - 250]}
          >
            <XAxis
              axisAt="bottom"
              orient="bottom"
              showTicks={false}
              outerTickSize={0}
            />
            <YAxis axisAt="right" orient="right" tickValues={[30, 50, 70]} />
            <MouseCoordinateY
              at="right"
              orient="right"
              displayFormat={format(".2f")}
            />

            <RSISeries yAccessor={d => d.rsi} />

            <RSITooltip
              origin={[-38, 15]}
              yAccessor={d => d.rsi}
              options={rsiCalculator.options()}
            />
          </Chart>
        )}

        {this.props.showATR && (
          <Chart
            id={8}
            yExtents={atr14.accessor()}
            height={125}
            origin={(w, h) => [0, h - 125]}
            padding={{ top: 10, bottom: 10 }}
          >
            <XAxis axisAt="bottom" orient="bottom" />
            <YAxis axisAt="right" orient="right" ticks={2} />

            <MouseCoordinateX
              at="bottom"
              orient="bottom"
              displayFormat={timeFormat("%Y-%m-%d")}
            />
            <MouseCoordinateY
              at="right"
              orient="right"
              displayFormat={format(".2f")}
            />

            <LineSeries yAccessor={atr14.accessor()} stroke={atr14.stroke()} />
            <SingleValueTooltip
              yAccessor={atr14.accessor()}
              yLabel={`ATR (${atr14.options().windowSize})`}
              yDisplayFormat={format(".2f")}
              /* labelStroke="#4682B4" - optional prop */ /* valueStroke={atr14.stroke()} - optional prop */ origin={[
                -40,
                15
              ]}
            />
            {this.props.showStrategy === "SAR" && (
              <SingleValueTooltip
                yLabel={`SAR (${accelerationFactor}, ${maxAccelerationFactor})`}
                yAccessor={d => d.sar}
                origin={[-40, 20]}
              />
            )}
          </Chart>
        )}
        {this.props.showATR && <CrossHairCursor />}
      </ChartCanvas>
    );
  }
}

CandleStickChartWithRSIIndicator.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  ratio: PropTypes.number.isRequired,
  type: PropTypes.oneOf(["svg", "hybrid"]).isRequired
};

CandleStickChartWithRSIIndicator.defaultProps = {
  type: "svg"
};
CandleStickChartWithRSIIndicator = fitWidth(CandleStickChartWithRSIIndicator);

export default CandleStickChartWithRSIIndicator;
