import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Header,
  Segment,
  Grid,
  Dimmer,
  Loader,
  // Message,
  // TextArea,
  Input,
  //Label,
  Button,
  Icon,
  Form
} from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";
//import DatePicker from "react-datepicker";
import * as actionCreators from "../../_store/action/index";
import moment from "moment";
import axios from "axios";

import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
//import htmlToDraft from "html-to-draftjs";

import "react-datepicker/dist/react-datepicker.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const keys = require("../../config/keys");

class EnvironmentSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      Message: "",
      Success: false,
      colDefs: [
        { field: "Id", hide: true },
        {
          field: "message",
          headerName: "Message",
          autoHeight: true,
          cellClass: "cell-wrap-text"
        },
        {
          field: "startdate",
          headerName: "Start Date",
          valueFormatter: data =>
            moment(data.value).format("DD/MM/YYYY H:mm:ss")
        }
      ],
      rowSelection: "single",
      editorState: EditorState.createEmpty(),
      selectedRow: {},
      selectedId: 0
    };
    this.props.state.bse = this.state;
  }
  componentDidMount() {
    axios
      .get(keys.ServicePath + "/api/GetBroadcastList")
      .then(res => {
        if (res.data.Success) {
          this.setState({ rowdata: res.data.data });
        } else {
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  }
  // componentWillReceiveProps(nextprops) {
  //   console.log(nextprops.state.login.data, nextprops);

  //   this.setState({ rowdata: nextprops.state.login.data });
  // }
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  onSelectionChanged = params => {
    this.gridApi = params.api;
    var selectedRows = this.gridApi.getSelectedRows();
    this.setState({
      selectedRow: selectedRows[0],
      selectedId: selectedRows[0].Id
    });
  };
  getFormData = () => {
    return {
      brodcastmessage: this.state
    };
  };
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
    //this.gridApi.rowHeight=40;
  };
  deleteBroadcast = () => {
    axios
      .get(
        keys.ServicePath + "/api/DeleteBroadcast?Id=" + this.state.selectedId
      )
      .then(res => {
        if (res.data.Success) {
          this.setState({ rowdata: res.data.data });
        } else {
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  };
  // onEditorStateChange = editorState => {
  //   console.log(editorState);
  //   this.setState({
  //     editorState
  //   });
  // };
  onEditorStateChange = editorState => {
    this.setState({
      editorState: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      message: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    });
  };
  render() {
    let data = this.props.state.login;
    //const { editorState } = this.state;

    return (
      <Container>
        {/* {data.Success && (
          <Message info>
            <Message.Header>{data.Header}</Message.Header>
            <p>{data.Message}</p>
          </Message>
        )} */}

        <Header as="h3" attached="top">
          Broadcast Messages
        </Header>
        <Segment color="orange" attached padded className="whitebg">
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Form>
                  <Editor
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={
                      this.onEditorStateChange // editorState={editorState}
                    }
                  />
                  {/* <textarea
                    value={draftToHtml(
                      convertToRaw(editorState.getCurrentContent())
                    )}
                  /> */}
                  {/* <TextArea
                  
                    rows={5}
                    placeholder="Tell us more"
                    name="message"
                    onChange={this.handleChange}
                    value={editorState}
                  /> */}
                </Form>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              {/* <Grid.Column>
                <Label>
                  {" "}
                  Date
                  <Label.Detail>
                    {" "}
                    <DatePicker
                      dateFormat="DD/MM/YYYY"
                      selected={this.state.startDate}
                      onChange={this.handleChange}
                    />
                  </Label.Detail>
                </Label>
              </Grid.Column> */}
              <Grid.Column width={10}>
                <Input
                  label="Expires in day(s)"
                  placeholder=""
                  name="expiredays"
                  onChange={this.handleChange}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button
                  icon
                  labelPosition="left"
                  primary
                  size="small"
                  onClick={() => this.props.saveBroadcast(this.getFormData())}
                >
                  <Icon name="save" />
                  Save Message
                </Button>
                <Button
                  icon
                  labelPosition="left"
                  negative
                  size="small"
                  onClick={this.deleteBroadcast}
                >
                  <Icon name="cancel" />
                  Delete Selected Message
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className="grid_height_500  ag-theme-balham">
                  <AgGridReact
                    rowData={this.state.rowdata}
                    enableSorting
                    floatingFilter={true}
                    enableFilter={true}
                    suppressColumnVirtualisation={true}
                    enableColResize={true}
                    columnDefs={this.state.colDefs}
                    onGridReady={this.onGridReady}
                    rowSelection={this.state.rowSelection}
                    onSelectionChanged={this.onSelectionChanged}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Dimmer active={data.showLoader} inverted>
            <Loader size="large">Uploading Data</Loader>
          </Dimmer>
        </Segment>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    state
  };
};
const mapDispatchToProps = dispatch => {
  return {
    saveBroadcast: objFormData =>
      dispatch(actionCreators.saveBroadcast(objFormData))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnvironmentSettings);
