import React from "react";
import { Redirect } from "react-router-dom";
import { Menu, Image, Dropdown, Icon, Modal, Header } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import "./menu.css";

// const  tagOptions = [
//   {
//     text: 'Important',
//     value: 'Important',
//     label: { color: 'red', empty: true, circular: true },
//   },
//   {
//     text: 'Announcement',
//     value: 'Announcement',
//     label: { color: 'green', empty: true, circular: true },
//   },

// ]

const keys = require("../../config/keys");
class MenuItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = { broadcast: [], messageread: false };
  }
  state = {};
  fetchBroadcast = () => {
    axios
      .get(keys.ServicePath + "/api/GetBroadcastListUsers")
      .then(res => {
        if (res.data.Success) {
          this.setState({
            broadcast: res.data.data
          });
          if (res.data.data.length > 0) {
            this.setState({ open: true, messageread: true });
          }
        } else {
          // console.log("else");
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  };
  componentDidMount() {
    var udata = JSON.parse(window.sessionStorage.getItem("data"));
    this.interval = setInterval(() => {
      var hour = new Date().getHours();
      if (hour >= 9 && hour < 16) {
        if (!this.state.messageread) {
          this.fetchBroadcast();
        }
      }
    }, 30000);
    if (udata.data.role !== "admin") {
      window.addEventListener("beforeunload", ev => {
        ev.preventDefault();

        var path =
          keys.ServicePath +
          "/api/Logout?userId=" +
          udata.data.Id +
          "&userName=" +
          udata.data.username;
        axios
          .get(path)
          .then(res => {})
          .catch(e => {
            console.log("catch error", JSON.stringify(e));
          });
        window.sessionStorage.setItem("isloggedin", false);
        //this.setState({ redirecttologin: true });
        return (ev.returnValue = "");
      });
    }
  }

  componentWillUnmount() {
    var udata = JSON.parse(window.sessionStorage.getItem("data"));

    if (udata.data.role !== "admin") {
      window.removeEventListener("beforeunload", ev => {
        ev.preventDefault();
        // alert("Unmount",ev.returnValue);
        return (ev.returnValue = "Are you sure you want to close?");
      });
    }
  }
  handleItemClick = (e, { name }) => this.setState({ activeItem: name });
  logout = () => {
    var udata = JSON.parse(window.sessionStorage.getItem("data"));

    var path =
      keys.ServicePath +
      "/api/Logout?userId=" +
      udata.data.Id +
      "&userName=" +
      udata.data.username;
    axios
      .get(path)
      .then(res => {})
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
    window.sessionStorage.setItem("isloggedin", false);
    this.setState({ redirecttologin: true });
  };
  close = () => {
    this.setState({ open: false });
  };
  render() {
    var iconstyle = {
      color: "white !important"
    };
    let isLoggedIn = window.sessionStorage.getItem("isloggedin");
    //let isGuest = window.sessionStorage.getItem("isGuest");
    let data = JSON.parse(window.sessionStorage.getItem("data"));

    //console.log("IsGuest",isGuest);
    if (this.state.redirecttologin) {
      return <Redirect to="/Login" />;
    } else if (isLoggedIn) {
      return (
        <div>
          <Menu
            fluid
            position="right"
            inverted
            pointing
            color="blue"
            className="menubg"
          >
            <Menu.Item name="Home" className="menulogo">
              <Image src="../asset/images/icon.png" width={30} height={30} />
              &nbsp;&nbsp;{" BAZAAR INDICATORS"}
            </Menu.Item>
            {/* <Menu.Item name="Stock">
          <NavLink to="/stockview">View</NavLink>
        </Menu.Item> */}
            <Dropdown item text="View">
              <Dropdown.Menu>
                <Dropdown.Item
                  className="ddwhite"
                  as={NavLink}
                  to={"/stockview/BSE"}
                >
                  BSE
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to={"/stockview/NSE"}>
                  NSE
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to={"/stockview/CURRENCY"}>
                  CURRENCY
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to={"/stockview/BIFolder"}>
                  BI Folder
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to={"/stockview/MyFolder"}>
                  User Folder
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {data.data.role === "admin" ? (
              <Menu.Item name="BSE">
                <NavLink to="/bse">BSE</NavLink>
              </Menu.Item>
            ) : null}
            {data.data.role === "admin" ? (
              <Menu.Item name="NSE">
                <NavLink to="/nse">NSE</NavLink>
              </Menu.Item>
            ) : null}
            <Menu.Item name="NSEOPTION">
              <NavLink to="/nseoption">NSE Option</NavLink>
            </Menu.Item>
            <Dropdown item simple text="Reports">
              <Dropdown.Menu>
                <Dropdown.Item className="ddwhite" onClick={this.show}>
                  <Icon name="dropdown" />
                  <span className="text">Daily Reports</span>
                  <Dropdown.Menu>
                    <Dropdown.Item as={NavLink} to={"/trendreport"}>
                      Trend Report
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to={"/volumeincdec"}>
                      Volume Increase/Decrease Report
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to={"/dailysummary"}>
                      Daily Summary Report
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item as={NavLink} to={"/dailyindicator"}>
                      Daily Indicator Report
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to={"/dailypivot"}>
                      Daily Pivot Report
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </Dropdown.Menu>
                </Dropdown.Item>
                <Dropdown.Item>Volume Increase/Decrease</Dropdown.Item>
                <Dropdown.Item>Summary Report</Dropdown.Item>
                <Dropdown.Item>Summary Report By Levels</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* <Menu.Item name="Messages">
              <NavLink to="/notifications">Notification</NavLink>
            </Menu.Item> */}
            {data.data.role === "admin" ? (
              <Menu.Item name="Messages">
                <NavLink to="/myfolder">My Folders</NavLink>
              </Menu.Item>
            ) : null}
            {data.data.role === "admin" ? (
              <Menu.Item name="Upload">
                <NavLink to="/Upload">Upload</NavLink>
              </Menu.Item>
            ) : null}
            {data.data.role === "admin" && (
              <Dropdown item text="Admin">
                <Dropdown.Menu>
                  <Dropdown.Item
                    className="ddwhite"
                    as={NavLink}
                    to={"/settings"}
                  >
                    Settings
                  </Dropdown.Item>
                  <Dropdown.Item className="ddwhite" as={NavLink} to={"/paths"}>
                    Path Settings
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="ddwhite"
                    as={NavLink}
                    to={"/changecode"}
                  >
                    Change Code
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="ddwhite"
                    as={NavLink}
                    to={"/broadcast"}
                  >
                    Broadcast
                  </Dropdown.Item>
                  <Dropdown.Item as={NavLink} to={"/usermanagement"}>
                    User Management
                  </Dropdown.Item>
                  <Dropdown.Item as={NavLink} to={"/userloginaudit"}>
                    Login Audit Report
                  </Dropdown.Item>
                  {/* <Dropdown.Item as={NavLink} to={"/theorykey"}>
                    Keys
                  </Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            )}
            <Menu.Item name="" position="right">
              {/* <Label circular color="orange" />
            <Button positive onClick={this.logout}>
              Logout
            </Button> */}
            </Menu.Item>
            {/* <Menu.Item
              name="upcomingEvents"
              content={<Icon name="bell" size="large" color="white" loading />}
            /> */}

            <Menu.Item name="Broadcast">
              <NavLink to="/notifications">
                <Icon
                  style={iconstyle}
                  name="bell"
                  size="large"
                  color="white"
                  loading
                  className="colorwhite"
                />
              </NavLink>
            </Menu.Item>
            <Menu.Item
              name="upcomingEvents"
              content={
                "Welcome " + data.data.FirstName + " " + data.data.LastName
              }
              onClick={this.props.userClick}
            />
            {/* <Popup
              trigger={
                <Menu.Item
                  name="upcomingEvents"
                  content={
                    "Welcome " + data.data.FirstName + " " + data.data.LastName
                  }
                />
              }
              content={
                <Button positive onClick={this.logout}>
                  Logout
                </Button>
              }
              on="click"
              position="top right"
            /> */}
          </Menu>

          {this.state.broadcast.length > 0 && (
            <Modal
              closeIcon
              open={this.state.open}
              onClose={
                this.close // onOpen={this.open}
              }
              size="small"
              closeOnEscape={true}
              closeOnDimmerClick={true}
            >
              <Header icon="chart line" content="Important" />
              <Modal.Content>
                {ReactHtmlParser(this.state.broadcast[0].message)}
              </Modal.Content>
            </Modal>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default MenuItems;
