import axios from "axios";
import * as actionTypes from "./actionTypes";
import moment from "moment";
const keys = require("../../config/keys");

export const login = objLogin => async dispatch => {
  var key = window.localStorage.getItem("regno") + moment();
  await axios
    .get(
      keys.ServicePath +
        "/api/Login?username=" +
        objLogin.username +
        "&password=" +
        objLogin.password +
        "&ip=" +
        key
    )
    .then(res => {
      if (res.data.Success && res.data.isValiduser) {
        window.sessionStorage.setItem("isloggedin", 1);
        window.sessionStorage.setItem(
          "isGuest",
          res.data.role === "admin" ? true : false
        );
        window.sessionStorage.setItem("data", JSON.stringify(res.data));
        dispatch({ type: actionTypes.LOGIN, payload: { isLoggedIn: 1 } });
      } else if (!res.data.Success && res.data.isValiduser) {
        window.sessionStorage.setItem("ValidUserMessage", res.data.Message);
        objLogin.ValidUserMessage = res.data.Message;
        dispatch({ type: actionTypes.LOGIN, objLogin });
      } else {
        window.sessionStorage.setItem(
          "ValidUserMessage",
          "Invalid Authentication"
        );
        objLogin.ValidUserMessage = "Invalid Authentication";

        //objLogin.BrodcastMessage
        dispatch({ type: actionTypes.LOGIN, objLogin });
      }
    })
    .catch(e => {
      console.log("catch error", JSON.stringify(e));
    });

  // if (username === "niks" && password === "nirmal") {
  //   window.sessionStorage.setItem("isloggedin", 1);
  //   window.sessionStorage.setItem("isGuest", false);
  //   dispatch({ type: actionTypes.LOGIN, payload: { isLoggedIn: 1 } });
  // } else if (username === "guest" && password === "guest@123") {
  //   window.sessionStorage.setItem("isloggedin", 1);
  //   window.sessionStorage.setItem("isGuest", true);
  //   dispatch({ type: actionTypes.LOGIN, payload: { isLoggedIn: 1 } });
  // } else {
  //   window.sessionStorage.setItem("isloggedin", 0);
  //   dispatch({
  //     type: actionTypes.LOGIN,
  //     payload: { isLoggedIn: 0, ErrorMessage: "Invalid User" }
  //   });
  // }
};

export const loginAuth = () => async dispatch => {
  const res = await axios.get("http://localhost:5000/api/current_user");
  dispatch({ type: actionTypes.USERAUTH, payload: res.data });
};

export const logout = () => {
  return {
    type: actionTypes.LOGOUT,
    isLoggedIn: false
  };
};

export const setUserState = objStateVal => {
  const { name, value } = objStateVal;
  return {
    type: actionTypes.SETUSERSTATE,
    name,
    value
  };
};
