import React, { Component } from "react";
import {
  Grid,
  Dropdown,
  Modal,
  Button,
  Container,
  Icon,
  Dimmer,
  Loader,
  Segment,
  Checkbox
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import moment from "moment";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";

import Report from "../Report";

import "react-datepicker/dist/react-datepicker.css";
//import 'ag-grid-root/dist/styles/ag-grid.css';
//import 'ag-grid-root/dist/styles/theme-fresh.css';

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
//var querystring = require("querystring");

const keys = require("../../../config/keys");

class TrendReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      open: false,
      reportDate: moment(),
      selectedreporttype: "",
      colDefs: [
        {
          field: "code",
          headerName: "CODE",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true
        },
        { field: "name", headerName: "NAME" },
        { field: "exchange", headerName: "EXCHANGE" }
      ],
      rowSelection: "multiple",
      reportdata: [],
      reportCol: [
        { field: "ScripName", headerName: "Scrip Name" },
        { field: "Close" },
        { field: "StrategyString" },
        { field: "RS14" },
        { field: "WeeklyCP" },
        { field: "MonthlyCP" },
        {
          field: "Immediate",
          cellClassRules: {
            negativecell: function(params) {
              console.log(params.data.ImmediateStatus);
              return params.data.ImmediateStatus.toLowerCase().includes(
                "negative"
              );
            },
            positivecell: function(params) {
              return params.data.ImmediateStatus.toLowerCase().includes(
                "positive"
              );
            }
          }
        },
        {
          field: "ShortTerm",
          cellClassRules: {
            negativecell: function(params) {
              return params.data.ShortTermStatus.toLowerCase() === "negative";
            },
            positivecell: function(params) {
              return params.data.ShortTermStatus.toLowerCase() === "positive";
            }
          }
        },
        {
          field: "MediumTerm",
          cellClassRules: {
            negativecell: function(params) {
              return params.data.MediumTermStatus.toLowerCase() === "negative";
            },
            positivecell: function(params) {
              return params.data.MediumTermStatus.toLowerCase() === "positive";
            }
          }
        },
        {
          field: "LongTerm",
          cellClassRules: {
            negativecell: function(params) {
              return params.data.LongTermStatus.toLowerCase() === "negative";
            },
            positivecell: function(params) {
              return params.data.LongTermStatus.toLowerCase() === "positive";
            }
          }
        }
      ],
      checked: false
    };
  }
  componentDidMount() {
    var dt = moment();
    var fdate = moment(dt).format("DD/MM/YYYY");

    this.setState({
      reportDate: fdate,
      selectedReportDate: this.state.reportDate
    });
    axios
      .get(keys.ServicePath + "/api/GetFolders")
      .then(res => {
        if (res.data.Success) {
          this.setState({ folders: res.data.folders });
        } else {
          console.log("else");
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  }
  show = () => {
    this.setState({ showLoader: true });
    var selectedscrip = [];
    var selectedTrend = [];
    var hideTrendColumn = [];
    var fdate = moment(this.state.selectedReportDate).format("DD/MM/YYYY");
    if (this.state.ALLchecked) selectedTrend.push("ALL");
    if (!this.state.ALLchecked) {
      if (this.state.ITchecked) {
        selectedTrend.push("Immediate");
      } else {
        hideTrendColumn.push("Immediate");
      }

      if (this.state.STchecked) {
        selectedTrend.push("Short");
      } else {
        hideTrendColumn.push("ShortTerm");
      }
      if (this.state.MTchecked) {
        selectedTrend.push("Medium");
      } else {
        hideTrendColumn.push("MediumTerm");
      }
      if (this.state.LTchecked) {
        selectedTrend.push("Long");
      } else {
        hideTrendColumn.push("LongTerm");
      }
    }

    this.setState({ hideTrendColumn: hideTrendColumn });
    this.gridApi.forEachNode(function(node) {
      if (node.isSelected()) {
        selectedscrip.push(node.data.code);
      }
    });

    var SelectedScripData = {
      scrips: selectedscrip,
      dtDate: fdate,
      dbType: this.state.selectedreporttype,
      trendType: selectedTrend
    };
    axios
      .post(keys.ServicePath + "/api/GetTrendReport", SelectedScripData)
      .then(res => {
        if (res.data.Success) {
          this.setState({ reportdata: res.data.ReportData });
          this.setState({ open: true });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  close = () => this.setState({ open: false });
  hideloader = () => this.setState({ showLoader: false });
  handleChange = date => {
    this.setState({
      selectedReportDate: date,
      reportDate: moment(date).format("DD/MM/YYYY")
    });
  };
  scripchnage = (e, { value }) => {
    this.setState({ selectedreporttype: value });
    axios
      .get(keys.ServicePath + "/api/GetScripForReport?type=" + value)
      .then(res => {
        if (res.data.Success) {
          this.setState({ scriptlist: res.data.ScriptList });
        } else {
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  };
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }
  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    window.alert("selection changed, " + rowCount + " rows selected");
  }

  toggleIT = () => {
    this.setState({ ITchecked: !this.state.ITchecked });

    if (this.state.ITchecked) {
      this.setState({
        ALLchecked: false
      });
    }
  };
  toggleST = () => this.setState({ STchecked: !this.state.STchecked });
  toggleMT = () => this.setState({ MTchecked: !this.state.MTchecked });
  toggleLT = () => this.setState({ LTchecked: !this.state.LTchecked });
  toggleALL = () =>
    this.setState({
      ALLchecked: !this.state.ALLchecked,
      ITchecked: !this.state.ALLchecked,
      STchecked: !this.state.ALLchecked,
      MTchecked: !this.state.ALLchecked,
      LTchecked: !this.state.ALLchecked
    });
  render() {
    return (
      <Container>
        <Segment inverted attached="top" padded className="segmentheader">
          Report Parameters
        </Segment>
        <Segment attached padded className="whitebg">
          <Grid>
            <Grid.Row>
              <Grid.Column width={10}>
                <Dropdown
                  fluid
                  placeholder="Scrip"
                  search
                  selection
                  options={this.state.folders}
                  onChange={this.scripchnage}
                  value={this.state.selectedreporttype}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                Date :{" "}
                <DatePicker
                  className="ui input input"
                  dateFormat="DD/MM/YYYY"
                  selected={this.state.selectedReportDate}
                  onChange={this.handleChange}
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <Modal
                  size="fullscreen"
                  open={this.state.open}
                  onClose={this.close}
                  closeOnEscape={false}
                  closeOnDimmerClick={false}
                  closeIcon
                >
                  <Modal.Header>Trend Report</Modal.Header>
                  <Modal.Content scrolling>
                    <Report
                      colDefs={this.state.reportCol}
                      rowdata={this.state.reportdata}
                      loader={this.hideloader}
                      hideColumns={this.state.hideTrendColumn}
                    />
                  </Modal.Content>
                </Modal>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className="grid_height_250  ag-theme-balham">
                  <AgGridReact
                    className="height240"
                    rowData={this.state.scriptlist}
                    enableSorting
                    floatingFilter={true}
                    enableFilter={true}
                    suppressColumnVirtualisation={true}
                    enableColResize={true}
                    columnDefs={this.state.colDefs}
                    onGridReady={this.onGridReady.bind(this)}
                    rowSelection={this.state.rowSelection}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Dimmer active={this.state.showLoader} inverted>
            <Loader size="massive" inverted>
              Generating Report
            </Loader>
          </Dimmer>
        </Segment>
        <Segment attached="bottom" padded className="whitebg">
          <Checkbox
            label="Immediate Term"
            onChange={this.toggleIT}
            checked={this.state.ITchecked}
          />
          <Checkbox
            label="Short Term"
            onChange={this.toggleST}
            checked={this.state.STchecked}
          />
          <Checkbox
            label="Medium Term"
            onChange={this.toggleMT}
            checked={this.state.MTchecked}
          />
          <Checkbox
            label="Long Term"
            onChange={this.toggleLT}
            checked={this.state.LTchecked}
          />
          <Checkbox
            label="ALL"
            onChange={this.toggleALL}
            checked={this.state.ALLchecked}
          />
          <Button positive onClick={this.show} floated="right">
            <Icon name="file text" />
            Generate Report
          </Button>
        </Segment>
      </Container>
    );
  }
}

export default TrendReport;
