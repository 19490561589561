import { Component } from "react";
import ReactHtmlParser from "react-html-parser";
export default class MessageRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return ReactHtmlParser(this.props.value);
  }
}
