import React, { Component } from "react";
import {
  Grid,
  //Modal,
  Button,
  Container,
  Icon,
  Dimmer,
  Loader,
  Segment,
  Input,
  Dropdown
} from "semantic-ui-react";

import moment from "moment";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";

import "react-datepicker/dist/react-datepicker.css";
//import 'ag-grid-root/dist/styles/ag-grid.css';
//import 'ag-grid-root/dist/styles/theme-fresh.css';

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
//var querystring = require("querystring");

const keys = require("../../config/keys");
var udata = JSON.parse(window.sessionStorage.getItem("data"));
class UserFolder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      open: false,
      reportDate: moment(),
      selectedreporttype: "",
      myfolders: [],
      colDefs: [
        {
          field: "code",
          headerName: "CODE",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: "agTextColumnFilter"
        },
        { field: "name", headerName: "NAME", filter: "agTextColumnFilter" },
        {
          field: "exchange",
          headerName: "EXCHANGE",
          filter: "agTextColumnFilter"
        },
        {
          field: "category",
          headerName: "CATEGORY",
          filter: "agTextColumnFilter"
        }
      ],
      usercolDefs: [
        { field: "Id", headerName: "ID", hide: true },
        {
          field: "FolderName",
          headerName: "Folder",
          rowGroup: true,
          hide: true,
          filter: "agTextColumnFilter"
        },
        { field: "CODE", headerName: "CODE", filter: "agTextColumnFilter" },
        { field: "name", headerName: "NAME", filter: "agTextColumnFilter" },
        {
          field: "exchange",
          headerName: "EXCHANGE",
          filter: "agTextColumnFilter"
        }
        // {
        //   field: "category",
        //   headerName: "CATEGORY",
        //   filter: "agTextColumnFilter"
        // },
        // {
        //   field: "industry",
        //   headerName: "INDUSTRY",
        //   filter: "agTextColumnFilter"
        // }
      ],
      rowSelection: "multiple",
      reportdata: [],
      checked: false,
      folders: []
    };
  }
  componentDidMount() {
    udata = JSON.parse(window.sessionStorage.getItem("data"));
    axios
      .get(keys.ServicePath + "/api/GetAllScrips")
      .then(res => {
        if (res.data.Success) {
          this.setState({ scriptlist: res.data.ScriptList });
        } else {
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });

    axios
      .get(keys.ServicePath + "/api/GetMyFolders?Id=" + udata.data.Id)
      .then(res => {
        if (res.data.Success) {
          this.setState({
            showLoader: false,
            myfolders: res.data.myfolders,
            folders: res.data.folders
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  show = () => {
    this.setState({ showLoader: true });
    var selectedscrip = [];
    this.gridApi.forEachNode(function(node) {
      if (node.isSelected()) {
        selectedscrip.push(node.data.code);
      }
    });

    var SelectedScripData = {
      scrips: selectedscrip,
      Id: udata.data.Id,
      folderName: this.state.foldername
    };
    axios
      .post(keys.ServicePath + "/api/SaveUserFolder", SelectedScripData)
      .then(res => {
        if (res.data.Success) {
          this.setState({ showLoader: false, myfolders: res.data.myfolders });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  close = () => this.setState({ open: false });
  hideloader = () => this.setState({ showLoader: false });
  handleChange = date => {
    this.setState({
      selectedReportDate: date,
      reportDate: moment(date).format("DD/MM/YYYY")
    });
  };
  foldernamechange = (e, { name, value }) => {
    this.setState({ foldername: value });
  };
  scripchnage = (e, { value }) => {
    this.setState({ selectedreporttype: value });
    axios
      .get(keys.ServicePath + "/api/GetScripForReport?type=" + value)
      .then(res => {
        if (res.data.Success) {
          this.setState({ scriptlist: res.data.ScriptList });
        } else {
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  };
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }
  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes().length;
    window.alert("selection changed, " + rowCount + " rows selected");
  }

  toggleIT = () => {
    this.setState({ ITchecked: !this.state.ITchecked });

    if (this.state.ITchecked) {
      this.setState({
        ALLchecked: false
      });
    }
  };
  toggleST = () => this.setState({ STchecked: !this.state.STchecked });
  toggleMT = () => this.setState({ MTchecked: !this.state.MTchecked });
  toggleLT = () => this.setState({ LTchecked: !this.state.LTchecked });
  toggleALL = () =>
    this.setState({
      ALLchecked: !this.state.ALLchecked,
      ITchecked: !this.state.ALLchecked,
      STchecked: !this.state.ALLchecked,
      MTchecked: !this.state.ALLchecked,
      LTchecked: !this.state.ALLchecked
    });
  render() {
    return (
      <Container>
        <Segment inverted attached="top" padded className="segmentheader">
          User Folders
        </Segment>
        <Segment attached padded className="whitebg">
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Input
                  fluid
                  label="Create New Folder"
                  placeholder=""
                  onChange={this.foldernamechange}
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <Dropdown
                  fluid
                  placeholder="My Folders"
                  search
                  selection
                  options={this.state.folders}
                  onChange={this.scripchnage}
                  value={this.state.selectedfolder}
                />
              </Grid.Column>
              {/* <Grid.Column width={3}>
                <Modal
                  size="fullscreen"
                  open={this.state.open}
                  onClose={this.close}
                  closeOnEscape={false}
                  closeOnDimmerClick={false}
                  closeIcon
                >
                  <Modal.Header>Trend Report</Modal.Header>
                  <Modal.Content scrolling>
                    <Report
                      colDefs={this.state.reportCol}
                      rowdata={this.state.reportdata}
                      loader={this.hideloader}
                      hideColumns={this.state.hideTrendColumn}
                    />
                  </Modal.Content>
                </Modal>
              </Grid.Column> */}
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <div className="grid_height_250  ag-theme-balham">
                  <AgGridReact
                    className="height240"
                    rowData={this.state.scriptlist}
                    enableSorting
                    floatingFilter={true}
                    enableFilter={true}
                    suppressColumnVirtualisation={true}
                    enableColResize={true}
                    columnDefs={this.state.colDefs}
                    onGridReady={this.onGridReady.bind(this)}
                    rowSelection={this.state.rowSelection}
                  />
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="grid_height_250  ag-theme-balham">
                  <AgGridReact
                    className="height240"
                    rowData={this.state.myfolders}
                    enableSorting
                    floatingFilter={true}
                    enableFilter={true}
                    suppressColumnVirtualisation={true}
                    enableColResize={true}
                    columnDefs={this.state.usercolDefs}
                    onGridReady={this.onGridReady.bind(this)}
                    rowSelection={"single"}
                    groupUseEntireRow={true}
                    animateRows={true}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Dimmer active={this.state.showLoader} inverted>
            <Loader size="massive" inverted>
              Saving
            </Loader>
          </Dimmer>
        </Segment>
        <Segment attached="bottom" className="whitebg padding5">
          <Button positive onClick={this.show}>
            <Icon name="file text" />
            Save Folder
          </Button>
        </Segment>
      </Container>
    );
  }
}

export default UserFolder;
