import React, { Component } from "react";
import { Input, Container, Grid, Divider, Table } from "semantic-ui-react";
import "../nse/nsestyle.css";

class FibboLevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      high: 0,
      low: 0,
      close: 0,
      symbol: "",
      Downtrendfibbo: [],
      Uptrendfibbo: []
    };
  }
  componentDidMount() {
    this.setState({
      high: this.props.high,
      low: this.props.low,
      close: this.props.close,
      symbol: this.props.symbol
    });
    this.CalculateFibboLevel();
  }
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
    if (this.state.high > 0 && this.state.low > 0) {
      this.CalculateFibboLevel();
    }
  };
  CalculateFibboLevel = () => {
    var high = parseFloat(this.state.high);
    var low = parseFloat(this.state.low);
    if (high <= 0 || low <= 0) {
      high = parseFloat(this.props.high);
      low = parseFloat(this.props.low);
    }
    // var levelValue = [0.236, 0.382, 0.5, 0.618, 1, 1.382, 1.618, 2];
    var rlevelValue = [0.236, 0.382, 0.5, 0.618, 0.764, 1, 1.382];
    var elevelValue = [0.236, 0.382, 0.5, 0.618, 1, 1.382, 1.618];
    var rlevelName = [
      "23.60%",
      "38.20%",
      "50.00%",
      "61.80%",
      "76.40%",
      "100%",
      "138.20%"
    ];
    var elevelName = [
      "23.60%",
      "38.20%",
      "50.00%",
      "61.80%",
      "100%",
      "138.20%",
      "161.80%"
    ];
    var objUptrend = [],
      objdowntrend = [];
    //for (var i = levelName.length - 1; i >= 0; i--) {
    for (var i = 0; i < rlevelName.length; i++) {
      //var rval = Number(low + (high - low) * levelValue[i], 2);
      var rval = Number(high - ((high - low) * rlevelValue[i]), 2);
      var exval = Number(high + ((high - low) * elevelValue[i]), 2);
      var obj = {
        rlabel: rlevelName[i],
        rvalue: Number(rval, 2),
        elabel: elevelName[i],
        evalue: Number(exval, 2)
      };

      objUptrend.push(obj);
    }

    this.setState({ Uptrendfibbo: objUptrend });

    for (var j = 0; j < rlevelName.length; j++) {
      var obj1 = {
        rlabel: rlevelName[j],
        rvalue: Number(low + ((high - low) * rlevelValue[j]), 2),
        elabel: elevelName[j],
        evalue: Number(low - ((high - low) * elevelValue[j]), 2)
      };

      objdowntrend.push(obj1);
    }
    this.setState({ Downtrendfibbo: objdowntrend });
  };
  render() {
    return (
      <Container fluid>
        <Grid divided>
          <Grid.Row>
            <Grid.Column>
              <Table basic="very">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Symbol</Table.HeaderCell>
                    <Table.HeaderCell>High</Table.HeaderCell>
                    <Table.HeaderCell>Low</Table.HeaderCell>
                    <Table.HeaderCell>Close</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>{this.state.symbol}</Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder="High"
                        onChange={this.handleChange}
                        name="high"
                        value={this.state.high}
                        className="alignright"
                      />
                      {/* {parseFloat(this.state.high).toFixed(2)} */}
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder="Low"
                        onChange={this.handleChange}
                        name="low"
                        value={this.state.low}
                        className="alignright"
                      />
                      {/* {parseFloat(this.state.low).toFixed(2)} */}
                    </Table.Cell>
                    <Table.Cell>
                      {parseFloat(this.state.close).toFixed(2)}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        <Grid columns={2} divided>
          <Grid.Row>
            <Grid.Column>
              <Table compact basic celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan={4}>UpTrend</Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell colSpan={2}>Reaction</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2}>Extention</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {this.state.Uptrendfibbo.map((listValue, index) => {
                    return (
                      <Table.Row key={index}>
                        <Table.Cell>{listValue.rlabel}</Table.Cell>
                        <Table.Cell>
                          {parseFloat(listValue.rvalue).toFixed(2)}
                        </Table.Cell>
                        <Table.Cell>{listValue.elabel}</Table.Cell>
                        <Table.Cell>
                          {parseFloat(listValue.evalue).toFixed(2)}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column>
              <Table compact basic celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan={4}>DownTrend</Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell colSpan={2}>Reaction</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2}>Extention</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {this.state.Downtrendfibbo.map((listValue, index) => {
                    return (
                      <Table.Row key={index}>
                        <Table.Cell>{listValue.rlabel}</Table.Cell>
                        <Table.Cell>
                          {parseFloat(listValue.rvalue).toFixed(2)}
                        </Table.Cell>
                        <Table.Cell>{listValue.elabel}</Table.Cell>
                        <Table.Cell>
                          {parseFloat(listValue.evalue).toFixed(2)}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {/* <Grid columns="two" divided>
          <Grid.Row>
            <Grid.Column align="center">
              <Input
                label="High"
                placeholder="high"
                onChange={this.handleChange}
                name="high"
                value={this.state.high}
                className="alignright"
              />
            </Grid.Column>

            <Grid.Column align="center">
              <Input
                label="Low"
                placeholder="Low"
                onChange={this.handleChange}
                name="low"
                value={this.state.low}
                className="alignright"
              />
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column align="center">UpTrend/Pullback</Grid.Column>
            <Grid.Column align="center">Down Trend/Reaction</Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column>
              {this.state.Uptrendfibbo.map((listValue, index) => {
                return (
                  <div className="row" key={index}>
                    <div className="col=md-11">
                      <Input
                        label={listValue.label}
                        value={parseFloat(listValue.value).toFixed(2)}
                        disabled
                        className="disabledOpacity alignright"
                      />
                    </div>
                  </div>
                );
              })}
            </Grid.Column>
            <Grid.Column>
              {this.state.Downtrendfibbo.map((listValue, index) => {
                return (
                  <div className="row" key={index}>
                    <div className="col=md-11">
                      <Input
                        label={listValue.label}
                        value={parseFloat(listValue.value).toFixed(2)}
                        disabled
                        className="disabledOpacity alignright"
                      />
                    </div>
                  </div>
                );
              })}
            </Grid.Column>
          </Grid.Row>
        </Grid> */}
      </Container>
    );
  }
}
export default FibboLevel;
