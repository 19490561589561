import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Table, TableCell } from "semantic-ui-react";

class HighLowStats extends Component {
  constructor(props) {
    super(props);
    this.state = this.props;
    
  }

  render() {
    return (
      <Container>
        <Table compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>High</Table.HeaderCell>
              <Table.HeaderCell>Low</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.data.map((listValue, index) => {
              return (
                <Table.Row key={index}>
                  <Table.Cell>{listValue.title}</Table.Cell>
                  <TableCell className="alignright">
                    {listValue.high > 0
                      ? Number(listValue.high, 2).toFixed(2)
                      : ""}
                  </TableCell>
                  <TableCell className="alignright">
                    {listValue.low > 0
                      ? Number(listValue.low, 2).toFixed(2)
                      : ""}
                  </TableCell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Container>
    );
  }
}
const mapStateToProps = state => {
  return {
    OpenIntOutlook: state
  };
};
const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HighLowStats);
