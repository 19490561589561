import * as actionTypes from "./actionTypes";
import axios from "axios";
const keys = require("../../config/keys");

export const scriptlist = form => async dispatch => {
  await axios
    .get(keys.ServicePath + "/api/ScriptList?scripttype=bse")
    .then(res => {
      if (res.data.Success) {
        var objProg = [];
        for (var i = 0; i < res.data.ScriptList[0].lstbse.length; i++) {
          var obj = {
            key: i + 1,
            //text: res.data.ScriptList[0].lstbse[i].Tables_in_bse,
            label: res.data.ScriptList[0].lstbse[i].name,
            value: res.data.ScriptList[0].lstbse[i].code
          };

          objProg.push(obj);
        }
        form.bsescriptname = objProg;
      } else {
        
      }
    })
    .catch(e => {
      console.log("catch error", JSON.stringify(e));
    });

  dispatch({ type: actionTypes.SCRIPTLIST, payload: form.bsescriptname });
};

export const scriptlistnse = form => async dispatch => {
  await axios
    .get(keys.ServicePath + "/api/ScriptList?scripttype=nse")
    .then(res => {
      if (res.data.Success) {
        var objProg = [];
        for (var i = 0; i < res.data.ScriptList[0].lstnse.length; i++) {
          var obj = {
            key: i + 1,
            //text: res.data.ScriptList[0].lstbse[i].Tables_in_bse,
            label: res.data.ScriptList[0].lstnse[i].name,
            value: res.data.ScriptList[0].lstnse[i].code
          };

          objProg.push(obj);
        }
        form.nsescriptname = objProg;
      } else {
        
      }
    })
    .catch(e => {
      console.log("catch error", JSON.stringify(e));
    });

  dispatch({ type: actionTypes.SCRIPTLISTNSE, payload: form.nsescriptname });
};

export const scriptlistnseop = form => async dispatch => {
  await axios
    .get(keys.ServicePath + "/api/ScriptList?scripttype=nseoption")
    .then(res => {
      if (res.data.Success) {
        var objProg = [];
        for (var i = 0; i < res.data.ScriptList[0].lstnseoption.length; i++) {
          var obj = {
            key: i + 1,
            //text: res.data.ScriptList[0].lstbse[i].Tables_in_bse,
            label: res.data.ScriptList[0].lstnseoption[i].name,
            value: res.data.ScriptList[0].lstnseoption[i].code
          };

          objProg.push(obj);
        }
        form.nseoptionscriptname = objProg;
      } else {
        console.log("else");
      }
    })
    .catch(e => {
      console.log("catch error", JSON.stringify(e));
    });

  dispatch({
    type: actionTypes.SCRIPTLISTNSEOP,
    payload: form.nseoptionscriptname
  });
};

export const bsescriptdetails = (form, selectedValue) => async dispatch => {
  var objProg = [];
  await axios
    .get(
      keys.ServicePath + "/api/GetbseScriptdetails?scriptname=" + selectedValue
    )
    // axios.get(keys.ServicePath + '/api/GetbseScriptdetails?scriptname=13')
    .then(res => {
      if (res.data.Success) {
        form.bsedetails = res.data.bsedetails;
        for (var i = 0; i < res.data.bsedetails.length; i++) {
          var obj = {
            date: new Date(Date.parse(res.data.bsedetails[i].ODate)),
            open: res.data.bsedetails[i].open,
            high: res.data.bsedetails[i].high,
            low: res.data.bsedetails[i].low,
            close: res.data.bsedetails[i].close,
            volume: res.data.bsedetails[i].volume
          };

          objProg.push(obj);
        }
        form.chartdetails = objProg;
      } else {
      }
    })
    .catch(e => {
      console.log("catch error", JSON.stringify(e));
    });
  dispatch({ type: actionTypes.BSEDETAILS, payload: form });
};

export const nsescriptdetails = (form, selectedValue) => async dispatch => {
  await      axios.get(keys.ServicePath + '/api/GetnseScriptdetails?scriptname=' + selectedValue)
//   axios
//     .get(keys.ServicePath + "/api/GetnseScriptdetails", {
//       params: {
//         scriptname: selectedValue
//       }
//     })
    .then(res => {
      if (res.data.Success) {
        form.nsedetails = res.data.nsedetails;
      } else {
      
      }
    })
    .catch(e => {
      console.log("catch error", JSON.stringify(e));
    });
  dispatch({ type: actionTypes.NSEDETAILS, payload: form });
};

export const nseoptionscriptdetails = (
  form,
  selectedValue
) => async dispatch => {
  await axios
    .get(
      keys.ServicePath +
        "/api/GetnseoptionScriptdetails?scriptname=" +
        selectedValue
    )
    .then(res => {
      if (res.data.Success) {
        form.nseoptiondetails = res.data.nseoptiondetails;
      } else {
        console.log("else");
      }
    })
    .catch(e => {
      console.log("catch error", JSON.stringify(e));
    });
  dispatch({ type: actionTypes.NSEOPDETAILS, payload: form });
};

export const highlowtrenddata = (
  pdate,
  pscript,
  btype,
  form
) => async dispatch => {
  await axios
    .get(
      keys.ServicePath +
        "/api/HighLowTrend?date=" +
        pdate +
        "&script=" +
        pscript +
        "&dbType=" +
        btype
    )
    .then(res => {
      if (res.data.Success) {
        form.highlowddata = res.data.highlowddata;
      } else {
        console.log("else");
      }
    })
    .catch(e => {
      console.log("catch error", JSON.stringify(e));
    });
  dispatch({ type: actionTypes.HIGHLOWTREND, payload: form.highlowddata });
};
