import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Sidebar, Image, Segment, Button, Grid } from "semantic-ui-react";
// import {
//   Container,
//   Header,
//   Segment,
//   Icon,
//   Button,
//   Dimmer,
//   Loader,
//   Message,
//   Label
// } from "semantic-ui-react";
import axios from "axios";
import MenuItems from "./MenuItems";
import Upload from "../upload/Upload";
import BSE from "../bse/bse";
import NSE from "../nse/nse";
import NSEOPTION from "../nseoption/nseoption";
import StockView from "../stockview/stock";
import EnvironmentSettings from "../admin/admin";
// import NewUI from "../NewUI/newui";
import TrendReport from "../Report/trend/TrendReport";
import UserManagement from "../admin/usermanagement";
import UserLoginAudit from "../admin/userloginaudit";
import Broadcast from "../admin/broadcast";
import TheoryKey from "../admin/theorykey";
import VolumeIncDec from "../Report/VolumeIncreaseDecrease/VolumeIncDec";
import DailySummary from "../Report/dailysummary/dailysummary";
import DailyIndicator from "../Report/DailyIndicator/dailyindicator";
import DailyPivot from "../Report/dailypivot/dailypivot";
import Notifications from "../userbroadcast/userbroadcast";
import ScriptPaths from "../admin/scripPaths";
import MyFolder from "../userfolder/userfolder";
import ChangeCode from "../admin/changecode";

//import StockView from "../stockview/view";

//import { Dropdown, Icon, Menu, Segment } from 'semantic-ui-react';

// const MenuAttached = () => (
//   //<Menu attached="top" inverted pointing secondary>

//   //</Menu>
// );
const keys = require("../../config/keys");
class MainMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      userdata: JSON.parse(window.sessionStorage.getItem("data"))
    };
  }

  userClick = () => this.setState({ visible: !this.state.visible });
  handleSidebarHide = () => this.setState({ visible: false });
  logout = () => {
    var udata = JSON.parse(window.sessionStorage.getItem("data"));

    var path =
      keys.ServicePath +
      "/api/Logout?userId=" +
      udata.data.Id +
      "&userName=" +
      udata.data.username;
    axios
      .get(path)
      .then(res => {})
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
    window.sessionStorage.setItem("isloggedin", false);
    this.setState({ isLoggedIn: false });
  };
  render() {
    let isLoggedIn =
      window.sessionStorage.getItem("isloggedin") === null
        ? false
        : window.sessionStorage.getItem("isloggedin");
    // console.log("Loggedin",isLoggedIn);
    // console.log("loggedin", (isLoggedIn === false), (isLoggedIn === 0),( isLoggedIn === "false"))
    if (
      isLoggedIn === false ||
      isLoggedIn === 0 ||
      isLoggedIn === "false" ||
      isLoggedIn === "0"
    ) {
      //console.log("entered false login")
      return (
        // <Redirect to="/Login" />
        <Redirect to="/" />
      );
    }

    return (
      <div className="fluid">
        <MenuItems pointing userClick={this.userClick} />
        <Sidebar.Pushable>
          <Sidebar
            as={Segment}
            icon="labeled"
            inverted
            className="menubg"
            onHide={this.handleSidebarHide}
            vertical
            visible={this.state.visible}
            direction="right"
            animation="scale down"
          >
            <Grid centered>
              <Grid.Row centered>
                <Grid.Column>
                  <Image
                    src="../asset/images/icon.png"
                    className="menulogo"
                    height={200}
                    width={200}
                  />
                </Grid.Column>
              </Grid.Row>
              {this.state.userdata && (
                <Grid.Row centered>
                  <Grid.Column>
                    {this.state.userdata.data.FirstName +
                      " " +
                      this.state.userdata.data.LastName}
                  </Grid.Column>
                </Grid.Row>
              )}
              {/* <Grid.Row centered>
                <Grid.Column>
                  {this.state.userdata.data.FirstName + " " + this.state.udata.data.LastName}
                </Grid.Column>
              </Grid.Row> */}
              <Grid.Row centered>
                <Button negative onClick={this.logout} fluid>
                  Logout
                </Button>
              </Grid.Row>
            </Grid>
          </Sidebar>
          <Sidebar.Pusher>
            <div className="app-body">
              <main className="main">
                <Switch>
                  <Route path="/bse" exact component={BSE} />
                  <Route path="/nse" exact component={NSE} />
                  <Route path="/stockview/:type" exact component={StockView} />
                  <Route path="/nseoption" exact component={NSEOPTION} />
                  <Route path="/Upload" exact component={Upload} />
                  <Route
                    path="/settings"
                    exact
                    component={EnvironmentSettings}
                  />
                  <Route path="/paths" exact component={ScriptPaths} />
                  <Route
                    path="/usermanagement"
                    exact
                    component={UserManagement}
                  />
                  <Route
                    path="/userloginaudit"
                    exact
                    component={UserLoginAudit}
                  />
                  <Route path="/broadcast" exact component={Broadcast} />
                  <Route
                    path="/notifications"
                    exact
                    component={Notifications}
                  />
                  <Route path="/myfolder" exact component={MyFolder} />
                  {/* <Route path="/newui" exact component={NewUI} /> */}
                  <Route path="/trendreport" exact component={TrendReport} />
                  <Route path="/volumeincdec" exact component={VolumeIncDec} />
                  <Route path="/dailysummary" exact component={DailySummary} />
                  <Route
                    path="/dailyindicator"
                    exact
                    component={DailyIndicator}
                  />
                  <Route path="/dailypivot" exact component={DailyPivot} />

                  <Route path="/theorykey" exact component={TheoryKey} />
                  <Route path="/changecode" exact component={ChangeCode} />
                </Switch>
              </main>
            </div>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    );
  }
}

export default MainMenu;
