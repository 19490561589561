import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Header,
  Segment,
  Icon,
  Button,
  Dimmer,
  Loader,
  Message,
  Dropdown,
  Input,
  Label
} from "semantic-ui-react";
import * as actionCreators from "../../_store/action/index";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

const options = [
  { key: "ignore", text: "Ignore this series", value: "ignore" },
  { key: "accept", text: "Update only this series", value: "accept" }
];
class EnvironmentSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      Message: "",
      Success: false
    };
    this.props.state.bse = this.state;
  }
  onBSEInputChange;
  handleChange = date => {
    this.setState({
      startDate: date,
      Datetoupload: moment(date).format("DD/MM/YYYY")
    });
  };
  getFormData() {}
  render() {
    let data = this.props.state.bse;
    return (
      <Container>
        {data.Success && (
          <Message info>
            <Message.Header>{data.Header}</Message.Header>
            <p>{data.Message}</p>
          </Message>
        )}

        <Header as="h3" attached="top">
          Environment Settings
        </Header>
        <Segment color="orange" attached padded>
          <div className="row">
            <div className="col-md-8">
              <Input
                //label="BSE"
                placeholder=""
                label={<Dropdown defaultValue="ignore" options={options} />}
                labelPosition="right"
              >
                {/* <Label>BSE</Label>
              
                <Label>
                  <Dropdown defaultValue="ignore" options={options} />
                </Label> */}
              </Input>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <Input
                //label="NSE"
                placeholder=""
                label={
                  <Dropdown
                    defaultValue="ignore"
                    options={options}
                    onChange={this.onBSEDDChange}
                  />
                }
                labelPosition="right"
              >
                <Label>NSE</Label>
                <input onChange={this.onBSEInputChange} />
                <Label>
                  <Dropdown defaultValue="ignore" options={options} />
                </Label>
              </Input>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Button icon labelPosition="left" primary size="small">
                <Icon name="settings" />
                Update Environment Settings
              </Button>
            </div>
          </div>
          <Dimmer active={data.showLoader} inverted>
            <Loader size="large">Uploading Data</Loader>
          </Dimmer>
        </Segment>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    state
  };
};
const mapDispatchToProps = dispatch => {
  return {
    uploadBSE: objFormData => dispatch(actionCreators.uploadBSE(objFormData))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnvironmentSettings);
