import * as actionTypes from '../action/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    Datetoupload: '',
    showLoader: false,
    Success: false,
    Message: ''

}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPLOADBSE:
            return updateObject(state.BSEUpload, action.payload.BSEUpload);
        case actionTypes.LOADER:
            return updateObject(state, action.payload);
        case actionTypes.SPLITBONUS:
            return updateObject(state, action.payload);
        default:
            return updateObject(state, initialState);
    }
}

export default reducer;