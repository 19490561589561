import React, { Component } from "react";
import {
  Container,
  Grid,
  Button,
  Modal,
  Header,
  Table,
  Responsive,
  Dropdown
} from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";

import axios from "axios";
import Select from "react-virtualized-select";

import ChartComponent from "../Common/ChartComponent";
import FibboLevel from "../Common/FibboLevel";
import BILevel from "../Common/biLevel";
import OpenIntOutlook from "../nseoption/openIntOutlook";
// import DeliveryQty from "../nseoption/deliveryQty";
import Pivot from "../Common/pivot/pivot";
import CycleStatus from "../Common/cyclestatus/CycleStatus";
import SplitBonus from "../Common/SplitBonus/SplitBonus";
import * as actionCreators from "../../_store/action/index";
import { connect } from "react-redux";
import "react-select/dist/react-select.css";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css";
import "../nse/nsestyle.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Strategy from "../Common/Strategy/Strategy";

const keys = require("../../config/keys");
// const days = [
//   { key: "5", value: "5", text: "5" },
//   { key: "6", value: "6", text: "6" },
//   { key: "7", value: "7", text: "7" }
// ];
class NSE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CompleteList: { lstbse: [], lstnse: [], lstnseoption: [] },
      DnHeaderName: "Bla bla bla",
      colDefs: [
        { field: "dtDate", filter: "agTextColumnFilter", headerName: "Date" },
        {
          field: "open",

          valueGetter: function decimal(params) {
            return Number(params.data.open, 2).toFixed(2);
          }
        },
        {
          field: "high",
          valueGetter: function decimal(params) {
            return Number(params.data.high, 2).toFixed(2);
          }
        },
        {
          field: "low",
          valueGetter: function decimal(params) {
            return Number(params.data.low, 2).toFixed(2);
          }
        },
        {
          field: "close",
          valueGetter: function decimal(params) {
            return Number(params.data.close, 2).toFixed(2);
          }
        },
        {
          field: "volume",
          headerName: "Vo.."
        },
        { field: "del_qty", headerName: "de.." }
      ],
      isindex: false,
      nsedetails: [],
      nsescriptname: [],
      nseoptionscriptname: [],
      selectednsescriptvalue: "Nifty50",
      rowSelection: "single",
      highlowddata: [],
      trendData: [],
      indicators: {},
      tradingrange: {},
      dashboardtype: "daily",
      swingdata: [],
      strategy: [],
      chartdetails: [],
      pivotCenterPoint: [],
      pivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      dpivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      wpivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      mpivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      qpivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      ypivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      high: 0.0,
      low: 0.0,
      close: 0.0,
      Downtrendfibbo: [],
      Uptrendfibbo: [],
      biLevels: {},
      IsFutur: false,
      width: 1024,
      avgVals: {
        open: 0,
        high: 0,
        low: 0,
        close: 0,
        volume: 0
      }
    };
  }

  componentDidMount() {
    axios
      .get(keys.ServicePath + "/api/ScriptList?scripttype=nse")
      .then(res => {
        if (res.data.Success) {
          var objProg = [];
          for (var i = 0; i < res.data.ScriptList[0].lstnse.length; i++) {
            var obj = {
              key: i + 1,
              //text: res.data.ScriptList[0].lstbse[i].Tables_in_bse,
              label: res.data.ScriptList[0].lstnse[i].name,
              value: res.data.ScriptList[0].lstnse[i].code
            };

            objProg.push(obj);
          }
          this.setState({ nsescriptname: objProg });
          this.updateValue(this.state.selectednsescriptvalue);
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
    //this.props.updateValue(this.state.selectednsescriptvalue);
    // this.updateValue(this.state.selectednsescriptvalue);
    //    this.props.fetchScriptList(this.state);
    //this.props.fetchScriptList(this.state);
    // var result = Object.keys(this.props.dashboarddata.scriptlist.nsescriptname).map(function(obj) {
    //     console.log(obj);
    //   }
    // );
    //this.setState({ nsescriptname:this.props.dashboarddata.scriptlist});
  }

  DashboardTypeClick = value => {
    this.setState({ dashboardtype: value });

    axios
      .get(
        keys.ServicePath +
          "/api/GetnseScriptdetails?scriptname=" +
          this.state.selectednsescriptvalue +
          "&type=" +
          value
      )
      .then(res => {
        if (res.data.Success) {
          this.setState({
            nsedetails: res.data.nsedetails,
            isindex: res.data.isIndex
          });
          this.gridColumnApi.setColumnVisible("volume", !res.data.isIndex);
          this.gridColumnApi.setColumnVisible("del_qty", !res.data.isIndex);
          this.gridApi
            .getModel()
            .getRow(0)
            .setSelected(true);
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  };
  updateValue = newValue => {
    this.setState({ selectednsescriptvalue: newValue });

    axios
      .get(keys.ServicePath + "/api/GetnseScriptdetails?scriptname=" + newValue)
      .then(res => {
        if (res.data.Success) {
          this.setState({
            nsedetails: res.data.nsedetails,
            isindex: res.data.isIndex
          });

          this.gridColumnApi.setColumnVisible("volume", !res.data.isIndex);
          this.gridColumnApi.setColumnVisible("del_qty", !res.data.isIndex);

          this.gridApi
            .getModel()
            .getRow(0)
            .setSelected(true);
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
    if (this.state.high > 0 && this.state.low > 0) {
      this.CalculateFibboLevel();
    }
  };
  CalculateFibboLevel = () => {
    var high = parseFloat(this.state.high);
    var low = parseFloat(this.state.low);
    var levelValue = [0.236, 0.382, 0.5, 0.618, 1, 1.382, 1.618, 2];
    var levelName = [
      "Level-1 (23.60%)",
      "Level-2 (38.20%)",
      "Level-3 (50.00%)",
      "Level-4 (61.80%)",
      "Extended (100%)",
      "Extended (138.20%)",
      "Extended (161.80%)",
      "Extended (200%)"
    ];
    var objUptrend = [],
      objdowntrend = [];
    for (var i = levelName.length - 1; i >= 0; i--) {
      var val = low + (high - low) * levelValue[i];
      alert(val);
      var obj = {
        label: levelName[i],
        value: Math.round(val, 2)
      };
      objUptrend.push(obj);
    }

    this.setState({ Uptrendfibbo: objUptrend });

    for (var j = 0; j < levelName.length; j++) {
      var obj1 = {
        label: levelName[j],
        value: Math.round(high - (low - high) * levelValue[j], 2)
      };
      objdowntrend.push(obj1);
    }
    this.setState({ Downtrendfibbo: objdowntrend });
  };
  onGridReady(params) {
    console.log(params);
    this.gridApi = params.api;

    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  avgValChange = (e, { value }) => {
    var vals = value.split("|");
    var obj = {
      open: vals[0],
      high: vals[1],
      low: vals[2],
      close: vals[3],
      volume: vals[4]
    };

    this.setState({ avgVals: obj, selectedavg: value });
  };
  onSelectionChanged() {
    var selectedRows = this.gridApi.getSelectedRows();
    let selectedNodes = this.gridApi.getSelectedNodes();
    let previousRow = this.gridApi.getRowNode(selectedNodes[0].childIndex + 1);
    console.log(previousRow.data.dtDate);
    this.setState({
      high: selectedRows[0].high,
      low: selectedRows[0].low,
      close: selectedRows[0].close,
      sdate: selectedRows[0].dtDate,
      previousRow: previousRow.data
    });
    var objProg = [];
    axios
      .get(
        keys.ServicePath +
          "/api/HighLowTrend?date=" +
          selectedRows[0].dtDate +
          "&script=" +
          this.state.selectednsescriptvalue +
          "&dbType=nse&close=" +
          selectedRows[0].close +
          "&type=" +
          this.state.dashboardtype
      )
      .then(res => {
        if (res.data.Success) {
          console.log(res.data);
          this.setState({
            highlowddata: res.data.highlowddata,
            trendData: res.data.trend,
            indicators: res.data.indicator,
            tradingrange: res.data.tradingrange,
            swingdata: res.data.swing,
            strategy: res.data.strategy,
            isPositiveStrategy: res.data.isPositiveStrategy,
            strategyHeader: res.data.strategyHeader,
            triggerValue: res.data.triggerValue,
            pivotCenterPoint: res.data.pivotCenterPoint,
            dpivotLevel: res.data.dailypivotLevel,
            wpivotLevel: res.data.weeklypivotLevel,
            mpivotLevel: res.data.monthlypivotLevel,
            qpivotLevel: res.data.quarterlypivotLevel,
            ypivotLevel: res.data.yearlypivotLevel,
            biLevels: res.data.biLevels,
            openIntOutlook: res.data.openIntOutlook,
            deliveryQty: res.data.deliveryQty,
            cycleStatus: res.data.cycleStatus,
            xdaysAvg: res.data.lastxdays,
            selectedavg: res.data.lastxdays[0].value
          });

          for (var i = 0; i < res.data.chartdata.length; i++) {
            var obj = {
              date: new Date(Date.parse(res.data.chartdata[i].dtDate)),
              open: res.data.chartdata[i].open,
              high: res.data.chartdata[i].high,
              low: res.data.chartdata[i].low,
              close: res.data.chartdata[i].close,
              volume: res.data.chartdata[i].volume
            };

            objProg.push(obj);
          }
          var vals = res.data.lastxdays[0].value.split("|");
          var obj1 = {
            open: vals[0],
            high: vals[1],
            low: vals[2],
            close: vals[3],
            volume: vals[4]
          };
          this.setState({
            avgVals: obj1,
            selectedavg: res.data.lastxdays[0].value
          });
          this.setState({ chartdetails: objProg });
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  }

  handleOnUpdate = (e, { width }) => this.setState({ width: width });

  render() {
    // console.log('props', this.props.dashboarddata.scriptlist)
    // console.log("props", this.state.nsescriptname);
    const databasescript = this.state.nsescriptname;
    //databasescript = this.props.dashboarddata.scriptlist;
    const IsMobile =
      this.state.width <= Responsive.onlyComputer.minWidth ? true : false;

    if (!IsMobile) {
      return (
        <Container fluid>
          <Grid columns={4}>
            <Grid.Row>
              <Grid.Column width={1} textAlign="right">
                NSE
              </Grid.Column>
              <Grid.Column width={5}>
                <Select
                  options={databasescript}
                  simpleValue
                  clearable
                  name="select"
                  value={this.state.selectednsescriptvalue}
                  onChange={this.updateValue}
                  searchable
                  labelKey="label"
                  valueKey="value"
                />
              </Grid.Column>
              <Grid.Column width={5} textAlign="center">
                <Button.Group>
                  <Button
                    color="red"
                    className={
                      this.state.dashboardtype === "daily" ? "" : "basic"
                    }
                    onClick={() => this.DashboardTypeClick("daily")}
                  >
                    Daily
                  </Button>
                  <Button
                    color="green"
                    className={
                      this.state.dashboardtype === "weekly" ? "" : "basic"
                    }
                    onClick={() => this.DashboardTypeClick("weekly")}
                  >
                    Weekly
                  </Button>
                  <Button
                    color="blue"
                    className={
                      this.state.dashboardtype === "monthly" ? "" : "basic"
                    }
                    onClick={() => this.DashboardTypeClick("monthly")}
                  >
                    Monthly
                  </Button>
                </Button.Group>
              </Grid.Column>

              <Grid.Column width={5}>
                <Modal
                  trigger={
                    <Button color="blue" size="small">
                      Split Bonus
                    </Button>
                  }
                  size="small"
                  closeIcon
                >
                  <Header icon="chart line" content="Split Bonus" />
                  <Modal.Content>
                    <SplitBonus
                      high={this.state.high}
                      low={this.state.low}
                      close={this.state.close}
                      symbol={this.state.selectednsescriptvalue}
                      sdate={this.state.sdate}
                      stype={"NSE"}
                      previousRow={this.state.previousRow}
                    />
                  </Modal.Content>
                </Modal>
                <Modal
                  trigger={
                    <Button color="blue" size="small">
                      Fibbo Level
                    </Button>
                  }
                  size="small"
                  closeIcon
                >
                  <Header icon="chart line" content="Fibbo Levels" />
                  <Modal.Content>
                    <FibboLevel
                      high={this.state.high}
                      low={this.state.low}
                      close={this.state.close}
                      symbol={this.state.selectednsescriptvalue}
                    />
                  </Modal.Content>
                </Modal>
                <Modal
                  trigger={
                    <Button color="blue" size="small">
                      BI Levels
                    </Button>
                  }
                  closeIcon
                  size="mini"
                >
                  <Header icon="chart line" content="BI Levels" />
                  <Modal.Content>
                    <BILevel data={this.state.biLevels} />
                  </Modal.Content>
                </Modal>

                <Modal
                  trigger={
                    <Button
                      color="blue"
                      size="small"
                      disabled={
                        !this.state.selectednsescriptvalue
                          .toLowerCase()
                          .includes("mnth")
                      }
                    >
                      OpenInt
                    </Button>
                  }
                  closeIcon
                  size="small"
                >
                  <Header icon="chart line" content="Open Interest Outlook" />
                  <Modal.Content>
                    <OpenIntOutlook
                      data={this.state.openIntOutlook}
                      strategyHeader={this.state.strategyHeader}
                      isPositiveStrategy={this.state.isPositiveStrategy}
                    />
                  </Modal.Content>
                </Modal>

                {/* <Modal trigger={<Button color="blue" size="small" disabled={this.state.deliveryQty == null ? true : false}>
                      DelQty
                    </Button>} closeIcon size="small">
                  <Header icon="chart line" content="Delivery Quantity" />
                  <Modal.Content>
                    <DeliveryQty data={this.state.deliveryQty} />
                  </Modal.Content>
                </Modal> */}
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Grid>
            <Grid.Row columns={3} className="paddingtop">
              <Grid.Column width={7}>
                <Grid.Row>
                  <Grid.Column>
                    <div className="grid_height_fix  ag-theme-balham">
                      <AgGridReact
                        className="ag-theme-balham"
                        rowData={this.state.nsedetails}
                        enableSorting
                        enableColResize={
                          true //suppressColumnVirtualisation={true}
                        }
                        columnDefs={this.state.colDefs}
                        onGridReady={this.onGridReady.bind(this)}
                        rowSelection={this.state.rowSelection}
                        rowMultiSelectWithClick={true}
                        onSelectionChanged={this.onSelectionChanged.bind(this)}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Table basic>
                      <Table.Body>
                        <Table.Row className="pivotsubheader">
                          <Table.Cell>Avg. Days</Table.Cell>
                          <Table.Cell>Open</Table.Cell>
                          <Table.Cell>High</Table.Cell>
                          <Table.Cell>Low</Table.Cell>
                          <Table.Cell>Close</Table.Cell>
                          <Table.Cell>Volume</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>
                            <Dropdown
                              placeholder="days"
                              compact
                              selection
                              options={this.state.xdaysAvg}
                              onChange={this.avgValChange}
                              value={this.state.selectedavg}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            {Number(this.state.avgVals.open).toFixed(2)}
                          </Table.Cell>
                          <Table.Cell>
                            {Number(this.state.avgVals.high).toFixed(2)}
                          </Table.Cell>
                          <Table.Cell>
                            {Number(this.state.avgVals.low).toFixed(2)}
                          </Table.Cell>
                          <Table.Cell>
                            {Number(this.state.avgVals.close).toFixed(2)}
                          </Table.Cell>
                          <Table.Cell>
                            {Number(this.state.avgVals.volume).toFixed(2)}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                </Grid.Row>
                {/* <Grid columns="equal"> */}
                <Grid columns="equal">
                  <Grid.Row stretched columns={3}>
                    <Grid.Column width={8}>
                      <Table color="orange" basic collapsing>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell collapsing />
                            <Table.HeaderCell collapsing>
                              Daily
                            </Table.HeaderCell>
                            <Table.HeaderCell collapsing>
                              Weekly
                            </Table.HeaderCell>
                            <Table.HeaderCell collapsing>
                              Monthly
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {this.state.highlowddata.map((listValue, index) => {
                            return (
                              <Table.Row key={index}>
                                <Table.Cell collapsing>
                                  {listValue.TrendType}
                                </Table.Cell>
                                <Table.Cell collapsing>
                                  {Number(listValue.Daily).toFixed(2)}
                                </Table.Cell>
                                <Table.Cell collapsing>
                                  {Number(listValue.Weekly).toFixed(2)}
                                </Table.Cell>
                                <Table.Cell collapsing>
                                  {Number(listValue.Monthly).toFixed(2)}
                                </Table.Cell>
                              </Table.Row>
                            );
                          })}
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <Table compact basic collapsing>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell colSpan={2}>
                              Indicators
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body>
                          <Table.Row>
                            <Table.Cell
                              className={
                                this.state.indicators.IsPositiveLowAvg
                                  ? "positive"
                                  : "negative"
                              }
                            >
                              {this.state.indicators.LowAverage}
                            </Table.Cell>

                            <Table.Cell
                              className={
                                this.state.indicators.IsPositiveHighAvg
                                  ? "positive"
                                  : "negative"
                              }
                            >
                              {this.state.indicators.HighAverage}
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                      <Table compact basic collapsing className="margintop0">
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell colSpan={2}>
                              Trading Range
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell collapsing className="positive">
                              {this.state.tradingrange.LowAverage}
                            </Table.Cell>
                            <Table.Cell collapsing className="negative">
                              {this.state.tradingrange.HighAverage}
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                    <Grid.Column width={3}>
                      <CycleStatus data={this.state.cycleStatus} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                {/* </Grid> */}
              </Grid.Column>
              <Grid.Column width={5}>
                <Grid columns="equal">
                  <Grid.Row>
                    <Grid.Column>
                      <Strategy
                        strategy={this.state.strategy}
                        isPositiveStrategy={this.state.isPositiveStrategy}
                        strategyHeader={this.state.strategyHeader}
                        triggerValue={this.state.triggerValue}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Table compact="very" basic className="positiveborder">
                        <Table.Header className="positivetableheader">
                          <Table.Row>
                            <Table.HeaderCell colSpan="3">
                              Trends
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {this.state.trendData.map((listValue, index) => {
                            if (listValue.State.includes("Negative")) {
                              return (
                                <Table.Row className="negative" key={index}>
                                  <Table.Cell collapsing>
                                    {listValue.Trend}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    {listValue.State}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    {Number(listValue.Calculated).toFixed(2)}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            } else {
                              return (
                                <Table.Row className="positive" key={index}>
                                  <Table.Cell collapsing>
                                    {listValue.Trend}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    {listValue.State}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    {Number(listValue.Calculated).toFixed(2)}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            }
                          })}
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns="equal">
                    <Grid.Column>
                      <Table compact basic className="positiveborder">
                        <Table.Header className="positivetableheader">
                          <Table.Row>
                            <Table.HeaderCell colSpan="3">
                              Additional Important Levels
                            </Table.HeaderCell>
                          </Table.Row>
                          {/* <Table.Row>
                                        <Table.HeaderCell textAlign='center'></Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>I</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>II</Table.HeaderCell>
                                    </Table.Row> */}
                        </Table.Header>
                        <Table.Body>
                          {this.state.swingdata.map((listValue, index) => {
                            if (index === 0) {
                              return (
                                <Table.Row className="negative" key={index}>
                                  <Table.Cell collapsing>
                                    {listValue.LevelType}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    {Number(listValue.Level1, 2).toFixed(2)}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    <b>
                                      {" "}
                                      {Number(listValue.Level2, 2).toFixed(2)}
                                    </b>
                                  </Table.Cell>
                                </Table.Row>
                              );
                            } else {
                              return (
                                <Table.Row className="positive" key={index}>
                                  <Table.Cell collapsing>
                                    {listValue.LevelType}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    {Number(listValue.Level1, 2).toFixed(2)}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    <b>
                                      {" "}
                                      {Number(listValue.Level2, 2).toFixed(2)}
                                    </b>
                                  </Table.Cell>
                                </Table.Row>
                              );
                            }
                          })}
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column width={4}>
                <Table>
                  <Table.Row>
                    <Table.Cell>Here Goes the broadcast message</Table.Cell>
                  </Table.Row>
                </Table>
                <Table>
                  <Table.Row>
                    <Table.Cell>
                      {this.state.chartdetails.length > 0 && (
                        <ChartComponent
                          type="hybrid"
                          chartdata={this.state.chartdetails}
                          width={350}
                          height={320}
                          showRSI={false}
                          showATR={false}
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                </Table>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Pivot
                  data={this.state}
                  showWeekly={true}
                  showMonthly={true}
                  showQuarterly={true}
                  showYearly={true}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Responsive
            onUpdate={this.handleOnUpdate}
            {...Responsive.onlyMobile}
            as={Button}
            content="Switch to desktop version"
            icon="desktop"
            labelPosition="left"
          />
        </Container>
      );
    } else {
      return (
        <Responsive
          onUpdate={this.handleOnUpdate}
          {...Responsive.onlyMobile}
          as={Button}
          content="Switch to desktop version"
          icon="desktop"
          labelPosition="left"
        />
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    dashboarddata: state
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchScriptList: objform => dispatch(actionCreators.scriptlistnse(objform)),
    fetchbsedetails: (objform, selectedValue) =>
      dispatch(actionCreators.nsescriptdetails(objform, selectedValue)),
    fetchScriptHighLowTrend: (pdate, pselectedValue, objform) =>
      dispatch(
        actionCreators.highlowtrenddata(pdate, pselectedValue, "nse", objform)
      )
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NSE);
