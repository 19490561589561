import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Header,
  Button,
  Modal,
  Container,
  Label,
  Grid,
  Table,
  Input
} from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";
import Select from "react-virtualized-select";
import DatePicker from "react-datepicker";
import * as actionCreators from "../../_store/action/index";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import axios from "axios";
import HistoricalData from "./historicalData";
//import OpenIntOutlook from "./openIntOutlook";
import Strategy from "../Common/Strategy/Strategy";
import Pivot from "../Common/pivot/pivot";
import DailyPivot from "../Common/pivot/nsepivot";
import Chart from "../Common/charts/CandleStickChart";
//import Chart from "../Common/ChartComponent";
//import ChartComponent from "../Common/ChartComponent";

import BlockUi from "react-block-ui";
import "react-block-ui/style.css";

import "react-select/dist/react-select.css";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "../nse/nsestyle.css";
//import "../../css/bootstrap.css"
const keys = require("../../config/keys");

const spcolDefs = [
  { field: "STRPRICE", headerName: "ST.PR." },
  {
    field: "OPEN",
    valueGetter: function decimal(params) {
      return Number(params.data.OPEN, 2).toFixed(2);
    }
  },
  {
    field: "HIGH",
    valueGetter: function decimal(params) {
      return Number(params.data.HIGH, 2).toFixed(2);
    }
  },
  {
    field: "LOW",
    valueGetter: function decimal(params) {
      return Number(params.data.LOW, 2).toFixed(2);
    }
  },
  {
    field: "CLOSE",
    valueGetter: function decimal(params) {
      return Number(params.data.CLOSE, 2).toFixed(2);
    }
  },
  { field: "OPENINT", headerName: "OP.INT" },
  { field: "TRDQTY", headerName: "VOLUME" }
];

class NSEOPTION extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CompleteList: { lstbse: [], lstnse: [], lstnseoption: [] },
      nseoptiondetails: [],
      nsescriptname: [],
      nseoptionscriptname: [],
      selectednseopscriptvalue: "NIFTY",
      lastTransactionDate: "",
      TransactionDate: moment(),
      lstexpiryDates: [],
      expiryDate: "",
      onGridReady: function(params) {
        params.columnApi.autoSizeColumns();
      },
      rowCallSelection: "single",
      rowPutSelection: "single",
      singleDetail: {},
      strategy: [],
      trendData: [],
      pivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      dpivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      wpivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      mpivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      qpivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      ypivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      optiontype: "Call",
      historicalDataCall: [],
      historicalDataPut: [],
      dimmeractive: false
    };
  }
  componentDidMount() {
    //this.props.fetchScriptList(this.state);
    this.setState({
      dimmeractive: true
    });
    axios
      .get(keys.ServicePath + "/api/ScriptList?scripttype=nseoption")
      .then(res => {
        if (res.data.Success) {
          var objProg = [];
          for (var i = 0; i < res.data.ScriptList[0].lstnseoption.length; i++) {
            var obj = {
              key: i + 1,
              //text: res.data.ScriptList[0].lstbse[i].Tables_in_bse,
              label: res.data.ScriptList[0].lstnseoption[i].name,
              value: res.data.ScriptList[0].lstnseoption[i].code
            };

            objProg.push(obj);
          }
          //          form.nseoptionscriptname = objProg;
          this.setState({ nseoptionscriptname: objProg });
          this.updateValue(this.state.selectednseopscriptvalue);
        }
      })
      .catch(e => {
        this.setState({ dimmeractive: false });
        console.log("catch error", JSON.stringify(e));
      });
  }

  handleChange = date => {
    this.setState({
      TransactionDate: date,
      lastTransactionDate: moment(date).format("DD/MM/YYYY")
    });
    this.fetchDetails(
      this.state.selectednseopscriptvalue,
      moment(date).format("DD/MM/YYYY")
    );
  };
  updateValue = newValue => {
    this.setState({ selectednseopscriptvalue: newValue });

    this.fetchDetails(newValue, this.state.lastTransactionDate);
  };
  // updateValueExpiryDate = newValue => {
  //     this.setState({ selectednseopscriptvalue: newValue });
  //     alert(newValue, this.setState.selectednseopscriptvalue);
  //     this.fetchDetails(newValue);
  // };
  changeExpiryDate = newValue => {
    this.setState({ expiryDate: newValue });

    this.fetchDetails(
      this.state.selectednseopscriptvalue,
      moment(this.state.TransactionDate).format("DD/MM/YYYY"),
      newValue
    );
  };

  fetchDetails(scrip, transactiondate, expiryDate) {
    if (scrip === undefined || scrip === "") return;
    this.setState({ dimmeractive: true });
    ///else if (transactiondate === undefined || transactiondate === "") return;
    var url = "";
    if (expiryDate === undefined || expiryDate === "") {
      url =
        keys.ServicePath +
        "/api/GetnseoptionScriptdetails?scriptname=" +
        scrip +
        "&transactionDate=" +
        transactiondate;
    } else {
      url =
        keys.ServicePath +
        "/api/GetnseoptionScriptdetails?scriptname=" +
        scrip +
        "&transactionDate=" +
        transactiondate +
        "&expiryDate=" +
        expiryDate;
    }

    axios
      .get(url)
      .then(res => {
        if (res.data.Success) {
          var objProg = [];

          this.setState({
            calldetails: res.data.calldetails,
            putdetails: res.data.putdetails,
            singleDetail: res.data.singleDetail,
            strategy: res.data.strategy,
            isPositiveStrategy: res.data.isPositiveStrategy,
            strategyHeader: res.data.strategyHeader,
            triggerValue: res.data.triggerValue,
            trendData: res.data.trend,

            dpivotLevel: res.data.dailypivotLevel,
            wpivotLevel: res.data.weeklypivotLevel,
            mpivotLevel: res.data.monthlypivotLevel,
            qpivotLevel: res.data.quarterlypivotLevel,
            ypivotLevel: res.data.yearlypivotLevel
          });

          this.setState({
            TransactionDate: moment(res.data.transactionDate, "DD-MM-YYYY"),
            lastTransactionDate: moment(this.state.TransactionDate).format(
              "DD/MM/YYYY"
            )
          });
          this.gridApi
            .getModel()
            .getRow(0)
            .setSelected(true);

          this.CallgridApi.getModel()
            .getRow(0)
            .setSelected(true);

          for (var i = 0; i < res.data.expiryDates.length; i++) {
            var obj = {
              key: i + 1,
              label: res.data.expiryDates[i].expirydate,
              value: res.data.expiryDates[i].expirydate
            };

            objProg.push(obj);
          }

          this.setState({
            expiryDate: objProg[0].value,
            lstexpiryDates: objProg,
            dimmeractive: false
          });
        }
      })
      .catch(e => {
        this.setState({ dimmeractive: false });
        console.log("catch error", JSON.stringify(e));
      });
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function(column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
    //this.gridApi.sizeColumnsToFit();
  }
  onGridReadyCall(params) {
    this.CallgridApi = params.api;
    this.CallgridColumnApi = params.columnApi;
    var allColumnIds = [];
    this.CallgridColumnApi.getAllColumns().forEach(function(column) {
      allColumnIds.push(column.colId);
    });
    this.CallgridColumnApi.autoSizeColumns(allColumnIds);
    //this.gridApi.sizeColumnsToFit();
  }

  onSelectionChangedCall(params) {
    this.gridApi = params.api;
    var selectedRows = this.gridApi.getSelectedRows();

    // var high = Math.max.apply(
    //   Math,
    //   selectedRows[0].HISTORICAL.map(function(highVal) {
    //     return highVal.HIGH;
    //   })
    // );
    // var low = Math.min.apply(
    //   Math,
    //   selectedRows[0].HISTORICAL.map(function(lowVal) {
    //     return lowVal.LOW;
    //   })
    // );

    var objHist = [],
      objHistChart = [];
    for (var i = 0; i < selectedRows[0].HISTORICAL.length; i++) {
      var obj = {
        date: new Date(Date.parse(selectedRows[0].HISTORICAL[i].DTDATE)),
        open: selectedRows[0].HISTORICAL[i].OPEN,
        high: selectedRows[0].HISTORICAL[i].HIGH,
        low: selectedRows[0].HISTORICAL[i].LOW,
        close: selectedRows[0].HISTORICAL[i].CLOSE,
        volume: selectedRows[0].HISTORICAL[i].TRDQTY
      };

      objHist.push(obj);
    }

    for (var ii = 0; ii < selectedRows[0].HISTORICALCHART.length; ii++) {
      var obj1 = {
        date: new Date(Date.parse(selectedRows[0].HISTORICALCHART[ii].DTDATE)),
        //date: selectedRows[0].HISTORICALCHART[ii].DTDATE,
        open: selectedRows[0].HISTORICALCHART[ii].OPEN,
        high: selectedRows[0].HISTORICALCHART[ii].HIGH,
        low: selectedRows[0].HISTORICALCHART[ii].LOW,
        close: selectedRows[0].HISTORICALCHART[ii].CLOSE,
        volume: selectedRows[0].HISTORICALCHART[ii].STRPRICE
      };

      objHistChart.push(obj1);
    }

    var high = Math.max.apply(
      Math,
      selectedRows[0].HISTORICALCHART.map(function(highVal) {
        return highVal.HIGH;
      })
    );
    var low = Math.min.apply(
      Math,
      selectedRows[0].HISTORICALCHART.map(function(lowVal) {
        return lowVal.LOW;
      })
    );

    this.setState({
      historicalData: selectedRows[0].HISTORICAL,

      selectedRowDataCall: selectedRows[0],
      selectedRowData: selectedRows[0],
      historicalDataCall: objHist,
      historicalDataCallChart: objHistChart,
      callhigh: high,
      calllow: low
    });
  }
  onSelectionChangedPut(params) {
    this.gridApi = params.api;
    var selectedRows = this.gridApi.getSelectedRows();

    var high = Math.max.apply(
      Math,
      selectedRows[0].HISTORICAL.map(function(highVal) {
        return highVal.HIGH;
      })
    );
    var low = Math.min.apply(
      Math,
      selectedRows[0].HISTORICAL.map(function(lowVal) {
        return lowVal.LOW;
      })
    );
    var objHist = [];
    for (var i = 0; i < selectedRows[0].HISTORICAL.length; i++) {
      var obj = {
        date: new Date(Date.parse(selectedRows[0].HISTORICAL[i].DTDATE)),
        open: selectedRows[0].HISTORICAL[i].OPEN,
        high: selectedRows[0].HISTORICAL[i].HIGH,
        low: selectedRows[0].HISTORICAL[i].LOW,
        close: selectedRows[0].HISTORICAL[i].CLOSE,
        volume: selectedRows[0].HISTORICAL[i].TRDQTY
      };

      objHist.push(obj);
    }

    var objHistChart = [];
    for (var iii = 0; iii < selectedRows[0].HISTORICALCHART.length; iii++) {
      var obj2 = {
        date: new Date(Date.parse(selectedRows[0].HISTORICALCHART[iii].DTDATE)),
        open: selectedRows[0].HISTORICALCHART[iii].OPEN,
        high: selectedRows[0].HISTORICALCHART[iii].HIGH,
        low: selectedRows[0].HISTORICALCHART[iii].LOW,
        close: selectedRows[0].HISTORICALCHART[iii].CLOSE,
        volume: selectedRows[0].HISTORICALCHART[iii].STRPRICE
      };

      objHistChart.push(obj2);
    }
    this.setState({
      historicalData: selectedRows[0].HISTORICAL,
      selectedRowDataPut: selectedRows[0],
      selectedRowData: selectedRows[0],
      high: high,
      low: low,
      historicalDataPut: objHist,
      historicalDataPutChart: objHistChart
    });
  }
  render() {
    const databasescript = this.state.nseoptionscriptname;
    return (
      <BlockUi tag="div" blocking={this.state.dimmeractive}>
        <Container fluid>
          <Grid>
            <Grid.Row>
              <Grid.Column width={3}>
                <Select
                  options={databasescript}
                  simpleValue
                  clearable
                  name="select"
                  value={this.state.selectednseopscriptvalue}
                  onChange={this.updateValue}
                  searchable
                  labelKey="label"
                  valueKey="value"
                />
              </Grid.Column>
              <Grid.Column width={1}>Expiry Date</Grid.Column>
              <Grid.Column width={2}>
                <Select
                  options={this.state.lstexpiryDates}
                  simpleValue
                  clearable
                  name="select"
                  value={this.state.expiryDate}
                  onChange={this.changeExpiryDate}
                  searchable
                  labelKey="label"
                  valueKey="value"
                />
              </Grid.Column>
              <Grid.Column width={1}>Transaction Date</Grid.Column>
              <Grid.Column width={2}>
                {/* <DatePicker
                  dateFormat="DD/MM/YYYY"
                  selected={this.state.TransactionDate}
                  onChange={this.handleChange}
                  className="form-control"
                /> */}
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  selected={this.state.TransactionDate}
                  onChange={this.handleChange}
                  shouldCloseOnSelect={true}
                  customInput={
                    <Input
                      placeholder="Date"
                      icon="calendar alternate"
                      iconPosition="left"
                    />
                  }
                />
              </Grid.Column>
              <Grid.Column width={7}>
                <Label.Group basic="true" size="large" color="blue">
                  <Label>
                    OP : {Number(this.state.singleDetail.open, 2).toFixed(2)}
                  </Label>
                  <Label>
                    HI : {Number(this.state.singleDetail.high, 2).toFixed(2)}
                  </Label>
                  <Label>
                    LO : {Number(this.state.singleDetail.low, 2).toFixed(2)}
                  </Label>
                  <Label>
                    CL : {Number(this.state.singleDetail.close, 2).toFixed(2)}
                  </Label>
                </Label.Group>
              </Grid.Column>
            </Grid.Row>
            {/* <Grid.Row>
            <Grid.Column width={6}>
              <Strategy
                strategy={this.state.strategy}
                isPositiveStrategy={this.state.isPositiveStrategy}
                strategyHeader={this.state.strategyHeader}
                triggerValue={this.state.triggerValue}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <Table compact="very" basic className="positiveborder">
                <Table.Header className="positivetableheader">
                  <Table.Row>
                    <Table.HeaderCell colSpan="3">Trends</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.trendData.map((listValue, index) => {
                    if (listValue.State.includes("Negative")) {
                      return (
                        <Table.Row className="negative" key={index}>
                          <Table.Cell collapsing>{listValue.Trend}</Table.Cell>
                          <Table.Cell collapsing>{listValue.State}</Table.Cell>
                          <Table.Cell collapsing>
                            {Number(listValue.Calculated).toFixed(2)}
                          </Table.Cell>
                        </Table.Row>
                      );
                    } else {
                      return (
                        <Table.Row className="positive" key={index}>
                          <Table.Cell collapsing>{listValue.Trend}</Table.Cell>
                          <Table.Cell collapsing>{listValue.State}</Table.Cell>
                          <Table.Cell collapsing>
                            {Number(listValue.Calculated).toFixed(2)}
                          </Table.Cell>
                        </Table.Row>
                      );
                    }
                  })}
                </Table.Body>
              </Table>
            </Grid.Column>
        
           
          </Grid.Row> */}
            <Grid.Row>
              <Grid.Column width={6}>
                <Table compact="very" basic>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        CALL OPTIONS
                        <Modal
                          trigger={
                            <Button color="blue" floated="right" size="mini">
                              Historical Data
                            </Button>
                          }
                          closeIcon
                          closeOnEscape={false}
                        >
                          <Header icon="chart line" content="Historical Data" />
                          <Modal.Content>
                            <HistoricalData
                              rowData={this.state.selectedRowDataCall}
                              high={this.state.callhigh}
                              low={this.state.calllow}
                            />
                          </Modal.Content>
                        </Modal>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <div className="grid_height_fix  ag-theme-balham">
                          <AgGridReact
                            className="ag-theme-balham"
                            rowData={
                              this.state.calldetails // rowData={this.props.dashboarddata.scriptlist.bsedetails} // floatingFilter
                            }
                            enableSorting
                            suppressColumnVirtualisation={true}
                            enableColResize={true}
                            columnDefs={spcolDefs}
                            onGridReady={this.onGridReadyCall.bind(this)}
                            rowSelection={this.state.rowCallSelection}
                            onSelectionChanged={this.onSelectionChangedCall.bind(
                              this
                            )}
                          />
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <Table color="orange" basic compact>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        {this.state.historicalDataCall.length > 0 && (
                          <div>
                            <Chart
                              type="hybrid"
                              data={this.state.historicalDataCallChart}
                            />
                            {/* <ChartComponent
                            type="hybrid"
                            chartdata={this.state.historicalDataCallChart}
                            width={350}
                            height={400}
                            showRSI={false}
                            showATR={false}
                          /> */}
                            {/* <Chart1
                          type="hybrid"
                          data={this.state.historicalDataCallChart}
                        /> */}
                          </div>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column width={3}>
                <DailyPivot
                  data={this.state.selectedRowDataCall}
                  className="marginbottom0"
                />
                <DailyPivot
                  data={this.state.selectedRowDataPut}
                  className="marginbottom0"
                />
                <Strategy
                  strategy={this.state.strategy}
                  isPositiveStrategy={this.state.isPositiveStrategy}
                  strategyHeader={this.state.strategyHeader}
                  triggerValue={this.state.triggerValue}
                />
                <Table
                  compact="very"
                  basic
                  className="positiveborder  nseoptionmargin"
                >
                  <Table.Header className="positivetableheader">
                    <Table.Row>
                      <Table.HeaderCell colSpan="3">Trends</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.trendData.map((listValue, index) => {
                      if (listValue.State.includes("Negative")) {
                        return (
                          <Table.Row className="negative" key={index}>
                            <Table.Cell collapsing>
                              {listValue.Trend}
                            </Table.Cell>
                            <Table.Cell collapsing>
                              {listValue.State}
                            </Table.Cell>
                            <Table.Cell collapsing>
                              {Number(listValue.Calculated).toFixed(2)}
                            </Table.Cell>
                          </Table.Row>
                        );
                      } else {
                        return (
                          <Table.Row className="positive" key={index}>
                            <Table.Cell collapsing>
                              {listValue.Trend}
                            </Table.Cell>
                            <Table.Cell collapsing>
                              {listValue.State}
                            </Table.Cell>
                            <Table.Cell collapsing>
                              {Number(listValue.Calculated).toFixed(2)}
                            </Table.Cell>
                          </Table.Row>
                        );
                      }
                    })}
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column width={6}>
                <Table compact="very" basic>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        PUT OPTIONS
                        <Modal
                          trigger={
                            <Button color="blue" floated="right" size="mini">
                              Historical Data
                            </Button>
                          }
                          closeIcon
                          closeOnEscape={false}
                        >
                          <Header icon="chart line" content="Historical Data" />
                          <Modal.Content>
                            <HistoricalData
                              rowData={this.state.selectedRowDataPut}
                              high={this.state.high}
                              low={this.state.low}
                            />
                          </Modal.Content>
                        </Modal>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <div className="grid_height_fix  ag-theme-balham">
                          <AgGridReact
                            className="ag-theme-balham"
                            rowData={
                              this.state.putdetails // rowData={this.props.dashboarddata.scriptlist.bsedetails} // floatingFilter
                            }
                            enableSorting
                            suppressColumnVirtualisation={true}
                            enableColResize={true}
                            columnDefs={spcolDefs}
                            onGridReady={this.onGridReady.bind(this)}
                            rowSelection={this.state.rowPutSelection}
                            onSelectionChanged={this.onSelectionChangedPut.bind(
                              this
                            )}
                          />
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <Table color="orange" basic compact>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        {this.state.historicalDataPut.length > 0 && (
                          <Chart
                            type="hybrid"
                            data={this.state.historicalDataPutChart}
                          />
                        )}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid.Row>
            <Grid.Column>
              <Pivot data={this.state} />
            </Grid.Column>
          </Grid.Row>
        </Container>
      </BlockUi>
    );
  }
}

const mapStateToProps = state => {
  return {
    dashboarddata: state
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchScriptList: objform =>
      dispatch(actionCreators.scriptlistnseop(objform)),
    fetchbsedetails: (objform, selectedValue) =>
      dispatch(actionCreators.nseoptionscriptdetails(objform, selectedValue))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NSEOPTION);
