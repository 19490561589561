import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Header,
  Segment,
  Dimmer,
  Loader,
  Message,
  Grid,
  Label
} from "semantic-ui-react";
import * as actionCreators from "../../_store/action/index";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
const keys = require("../../config/keys");

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      Message: "",
      Success: false,
      colDefs: [
        { field: "FullName", headerName: "NAME" },
        { field: "Email" },
        { field: "MobileNo", headerName: "Mobile" },
        { field: "city", headerName: "City" },
        { field: "username", headerName: "User Name" },
        { field: "password", headerName: "Password" },
        {
          field: "isLogged",
          headerName: "Login Status",
          cellStyle: function(params) {
            if (params.value === "ONLINE") {
              //mark police cells as red
              return { color: "white", backgroundColor: "#28af37" };
            } else {
              return { color: "white", backgroundColor: "#fe0000" };
            }
          }
        },
        {
          field: "LogInDate",
          headerName: "LogIn Date",
          valueFormatter: data =>
            moment(data.value).format("DD/MM/YYYY H:mm:ss")
        },
        { field: "isLocked", headerName: "User Locked" }
      ],
      rowSelection: "multiple",
      reportdata: [],
      summary: [],
      checked: false
    };
    this.props.state.bse = this.state;
  }
  componentDidMount() {
    axios
      .get(keys.ServicePath + "/api/GetLoginReports")
      .then(res => {
        if (res.data.Success) {
          
          this.setState({ rowdata: res.data.data, summary: res.data.summary });
          //          console.log(this.state.summary[0].Description);
        } else {
          console.log("else");
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  }
  handleChange = date => {
    this.setState({
      startDate: date,
      Datetoupload: moment(date).format("DD/MM/YYYY")
    });
  };
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  };
  render() {
    let data = this.props.state.bse;
    return (
      <Container>
        {data.Success && (
          <Message info>
            <Message.Header>{data.Header}</Message.Header>
            <p>{data.Message}</p>
          </Message>
        )}

        <Header as="h3" attached="top">
          User Management
        </Header>
        <Segment color="orange" attached padded className="whitebg">
          {/* <div className="row">
            <div className="col-md-8 grid_height_fix grid_height_fix  ag-theme-balham"> */}
          <Grid>
            {this.state.summary.length > 0 && (
              <Grid.Row>
                <Grid.Column>
                  

                  <Label circular color="black">
                    {this.state.summary[0].Description +
                      " - " +
                      this.state.summary[0].Value}
                  </Label>

                  <Label circular color="red">
                    {this.state.summary[1].Description +
                      " - " +
                      this.state.summary[1].Value}
                  </Label>
                  <Label circular color="green">
                    {this.state.summary[2].Description +
                      " - " +
                      this.state.summary[2].Value}
                  </Label>

                  <Label circular color="purple">
                    {this.state.summary[3].Description +
                      " - " +
                      this.state.summary[3].Value}
                  </Label>
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row>
              <Grid.Column>
                <div className="grid_height_600  ag-theme-balham">
                  <AgGridReact
                    rowData={this.state.rowdata}
                    enableSorting
                    floatingFilter={true}
                    enableFilter={true}
                    suppressColumnVirtualisation={true}
                    enableColResize={true}
                    columnDefs={this.state.colDefs}
                    onGridReady={this.onGridReady}
                    rowSelection={this.state.rowSelection}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {/* </div>
          </div> */}

          <Dimmer active={data.showLoader} inverted>
            <Loader size="large">Fetching Data</Loader>
          </Dimmer>
        </Segment>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    state
  };
};
const mapDispatchToProps = dispatch => {
  return {
    uploadBSE: objFormData => dispatch(actionCreators.uploadBSE(objFormData))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagement);
