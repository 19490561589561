import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Header,
  Segment,
  // Grid,
  // Dimmer,
  // Loader,
  Image,
  Icon,
  Feed
} from "semantic-ui-react";
//import { AgGridReact } from "ag-grid-react";
//import DatePicker from "react-datepicker";
import * as actionCreators from "../../_store/action/index";
import moment from "moment";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";

import { EditorState } from "draft-js";
import "react-datepicker/dist/react-datepicker.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MessageRenderer from "./messageRenderer";
const keys = require("../../config/keys");

class UserBroadcast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      Message: "",
      Success: false,
      rowdata: [],
      colDefs: [
        {
          field: "message",
          headerName: "Notification",
          autoHeight: true,
          cellRenderer: "messageRenderer",
          filter: "agTextColumnFilter",
          cellClass: "cell-wrap-text"
        },
        {
          field: "message",
          headerName: "Message",
          autoHeight: true,
          cellClass: "cell-wrap-text",
          hide: true
        },
        {
          field: "startdate",
          width: 30,
          headerName: "Date",
          filter: "agTextColumnFilter",
          valueFormatter: data =>
            moment(data.value).format("DD/MM/YYYY H:mm:ss")
        }
      ],
      frameworkComponents: { messageRenderer: MessageRenderer },
      rowSelection: "single",
      editorState: EditorState.createEmpty()
    };
    this.props.state.bse = this.state;
  }
  componentDidMount() {
    axios
      .get(keys.ServicePath + "/api/GetBroadcastList")
      .then(res => {
        if (res.data.Success) {
          this.setState({ rowdata: res.data.data });
        } else {
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
  };

  render() {
    // let data = this.props.state.login;
    //const { editorState } = this.state;

    return (
      <Container>
        <Header as="h3" attached="top">
          Notifications
        </Header>
        <div className="scrollingdiv">
          <Segment color="orange" attached padded className="whitebg">
            <Feed>
              {this.state.rowdata.map((listValue, index) => {
                return (
                  <Feed.Event>
                    <Feed.Label>
                      <Image
                        src="../asset/images/icon.png"
                        width={30}
                        height={30}
                      />
                    </Feed.Label>
                    <Feed.Content>
                      <Feed.Summary>
                        <a>Bharat Sadrani </a> posted on his page
                        <Feed.Date>{listValue.startdate}</Feed.Date>
                      </Feed.Summary>
                      <Feed.Extra text>
                        <Segment
                          color="blue"
                          attached
                          padded
                          className="whitebg"
                        >
                          {ReactHtmlParser(listValue.message)}
                        </Segment>
                      </Feed.Extra>
                      <Feed.Meta>
                        <Feed.Like>
                          <Icon name="like" />5 Likes
                        </Feed.Like>
                      </Feed.Meta>
                    </Feed.Content>
                  </Feed.Event>
                );
              })}
            </Feed>
          </Segment>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    state
  };
};
const mapDispatchToProps = dispatch => {
  return {
    saveBroadcast: objFormData =>
      dispatch(actionCreators.saveBroadcast(objFormData))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserBroadcast);
