import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Header,
  Segment,
  Grid,
  Dimmer,
  Loader,
  // Message,
  // TextArea,
  // Input,
  //Label,
  // Button,
  // Icon,
  // Form
} from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";
//import DatePicker from "react-datepicker";
import * as actionCreators from "../../_store/action/index";
//import moment from "moment";
import axios from "axios";

import "react-datepicker/dist/react-datepicker.css";

const keys = require("../../config/keys");

class TheoryKey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      Message: "",
      Success: false,
      colDefs: [
        {
          field: "scrip",
          headerName: "scrip",
          autoHeight: true,
          cellClass: "cell-wrap-text"
        },
        { field: "markrttype", headerName: "Market Type" },
        { field: "demandtype", headerName: "Demand Type" },
        { field: "stoploss", headerName: "Stoploss" },
        { field: "habes_aariya", headerName: "Habes/Aariya" }
      ],
      rowSelection: "single",
      
    };
    this.props.state.bse = this.state;
  }
  componentDidMount() {
    axios
      .get(keys.ServicePath + "/api/GetGangotriScripts?date=17-11-2018")
      .then(res => {
        if (res.data.Success) {
          this.setState({ rowdata: res.data.Gangotri });
        } else {
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  }
  componentWillReceiveProps(nextprops) {
    this.setState({ rowdata: nextprops.state.login.data });
  }
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  getFormData = () => {
    return {
      brodcastmessage: this.state
    };
  };
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
    //this.gridApi.rowHeight=40;
  };

  // onEditorStateChange = editorState => {
  //   console.log(editorState);
  //   this.setState({
  //     editorState
  //   });
  // };
 
  render() {
    let data = this.props.state.login;
    

    return (
      <Container>
        
        <Header as="h3" attached="top">
          Broadcast Messages
        </Header>
        <Segment color="orange" attached padded className="whitebg">
          <Grid>
           
            <Grid.Row>
              <Grid.Column>
                <div className="grid_height_500  ag-theme-balham">
                  <AgGridReact
                    rowData={this.state.rowdata}
                    enableSorting
                    floatingFilter={true}
                    enableFilter={true}
                    suppressColumnVirtualisation={true}
                    enableColResize={true}
                    columnDefs={this.state.colDefs}
                    onGridReady={this.onGridReady}
                    rowSelection={this.state.rowSelection}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Dimmer active={data.showLoader} inverted>
            <Loader size="large">Running Scanner</Loader>
          </Dimmer>
        </Segment>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    state
  };
};
const mapDispatchToProps = dispatch => {
  return {
    saveBroadcast: objFormData =>
      dispatch(actionCreators.saveBroadcast(objFormData))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TheoryKey);
