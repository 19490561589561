import React, { Component } from "react";
import { Button, Icon } from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.loader();
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.props.hideColumns.map((value, index) => {
      return this.gridColumnApi.setColumnVisible(value, false);
    });
  }
  export = () => {
    var params = {
      allColumns: true,
      fileName: this.props.fileName,
      sheetName: this.props.sheetName,
      exportMode: "xlsx"
    };

    this.gridApi.exportDataAsExcel(params);
  };
  exportCSV = () => {
    var params = {
      allColumns: true,
      fileName: this.props.fileName,
      sheetName: this.props.sheetName,
      columnSeparator: ","
    };

    this.gridApi.exportDataAsCsv(params);
  };
  render() {
    const { headerHeight } = this.props;
    // if (this.props.rowdata === undefined) return null;
    return (
      <div>
        <Button positive icon labelPosition="left" onClick={this.export}>
          <Icon name="file excel" />
          Export to Excel
        </Button>

        <Button positive icon labelPosition="left" onClick={this.exportCSV}>
          <Icon name="file excel" />
          Export to CSV
        </Button>
        <div className="grid_height_500  ag-theme-balham fullwidth">
          <AgGridReact
            className="grid_height_500"
            rowData={this.props.rowdata}
            enableSorting
            suppressColumnVirtualisation={true}
            enableColResize={true}
            columnDefs={this.props.colDefs}
            onGridReady={this.onGridReady.bind(this)}
            pagination={true}
            paginationAutoPageSize={true}
            headerHeight={headerHeight}
          />
        </div>
      </div>
    );
  }
}

export default Report;
