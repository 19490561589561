import { combineReducers } from 'redux';

import login from './login';
import bse from './bse';
import scriptlist from './scriptlist';


const rootReducer = combineReducers({

    login,
    bse,
    scriptlist
});

export default rootReducer;
