import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Table } from "semantic-ui-react";

class DeliveryQty extends Component {
  constructor(props) {
    super(props);
    this.state = this.props;
  }

  render() {
    return (
      <Container>
        <Table compact="very" basic>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Delivery Quantity</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        </Table>
        <Table compact="very" basic>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="2">
                Average Calculations
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Average 5 Days Delivery</Table.Cell>
              <Table.Cell>{this.state.data.Avg5DayDel}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Average 5 Days Close</Table.Cell>
              <Table.Cell>{this.state.data.Avg5DayClose}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Delivery to Volume %</Table.Cell>
              <Table.Cell>{this.state.data.DeltoVolPer}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Table compact="very" basic>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="2">
                Delivery (Quantity)
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Current Day Delivery</Table.Cell>
              <Table.Cell>{this.state.data.CurrentDel}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Previous Day Delivery</Table.Cell>
              <Table.Cell>{this.state.data.PreviousDel}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Increase/Decrease %</Table.Cell>
              <Table.Cell>{this.state.data.DelIncDecPer}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Table compact="very" basic>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="2">Volume (Quantity)</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Current Day Volume</Table.Cell>
              <Table.Cell>{this.state.data.CurrentVolume}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Previous Day Volume</Table.Cell>
              <Table.Cell>{this.state.data.PreviousVOlume}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Increase/Decrease %</Table.Cell>
              <Table.Cell>{this.state.data.VolIncDecPer}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Container>
    );
  }
}
const mapStateToProps = state => {
  return {
    OpenIntOutlook: state
  };
};
const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryQty);
