import React, { Component } from "react";
import AdSense from "react-adsense";
import { Redirect } from "react-router-dom";
import {
  // Advertisement,
  Label,
  Button,
  // Card,
  Popup,
  Container,
  Divider,
  Image,
  Responsive,
  Tab,
  //Transition,
  Icon,
  Segment,
  Header,
  Dropdown,
  Message,
  Item
} from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import * as actionCreators from "../../_store/action/index";
//import Adsense from '../Common/adsense/adsense';
//import { machineId } from "node-machine-id";
import axios from "axios";

import { connect } from "react-redux";
//var DeviceInfo = require("react-native-device-info");
//var macaddress = require("macaddress");
// var deviceDetect = require("device-detect")();
// var uniqid = require("uniqid");
// const machineUuid = require("machine-uuid");
// var address = require("address");
////const os = require("os");
// import ReCAPTCHA from "react-google-recaptcha";
// var Recaptcha = require("react-recaptcha");
//const AsyncHoc = makeAsyncScriptLoader(URL)(ComponentNeedsScript);
const keys = require("../../config/keys");
//const readfileDescendantFallback = require("readfile-directory-index-fallback");
let rerenders = 0;
class LoginAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      animationin: "swing up",
      animationout: "swing down",
      loginopen: true,
      duration: 600,
      fireRerender: rerenders,
      callback: "not fired",
      captchaValue: "[empty]",
      load: true,
      expired: "false",
      isVerified: false,
      selectedCity: "",
      Message: "",
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      selectedState: "",
      ValidUserMessage: "",
      MessageType: "",
      MessageHeader: "",
      signup: false,
      ip: "",
      canRegister: false,
      otpopen: false,
      otp: "",
      VerifyOTP: ""
    };
  }

  readTextFile(file) {
    // console.log(file);
    var rawFile = new XMLHttpRequest();
    rawFile.open("GET", file, false);
    rawFile.onreadystatechange = function() {
      // console.log(rawFile);
      if (rawFile.readyState === 4) {
        if (rawFile.status === 200 || rawFile.status === 0) {
          var allText = rawFile.responseText;
          alert(allText);
        }
      }
    };
  }

  componentDidMount() {
    axios
      .get(keys.ServicePath + "/api/GetCities")
      .then(res => {
        if (res.data.Success) {
          this.setState({
            states: res.data.states,
            cities: res.data.cities,
            rawdata: res.data.rawdata,
            selectedState: "Gujarat"
          });
          this.statechangedInitial("Gujarat");
        } else {
          // console.log("else");
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  }

  handleCaptchaChange = value => {
    this.setState({ captchaValue: value });
  };
  asyncScriptOnLoad = () => {
    this.setState({ callback: "called!" });
    //console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef);
  };

  statechangedInitial = value => {
    //console.log(value);
    this.setState({ selectedState: value });
    var finaldata = [];
    for (var j = 0; j < this.state.rawdata.length - 1; j++) {
      if (this.state.rawdata[j].text === value) {
        finaldata.push({
          key: this.state.rawdata[j].key,
          text: this.state.rawdata[j].value,
          value: this.state.rawdata[j].value
        });
      }
    }

    this.setState({ cities: finaldata });
  };
  statechanged = (e, { value }) => {
    this.setState({ selectedState: value });
    var finaldata = [];
    for (var j = 0; j < this.state.rawdata.length - 1; j++) {
      if (this.state.rawdata[j].text === value) {
        finaldata.push({
          key: this.state.rawdata[j].key,
          text: this.state.rawdata[j].value,
          value: this.state.rawdata[j].value
        });
      }
    }

    this.setState({ cities: finaldata });
  };
  citychanged = (e, { value }) => {
    this.setState({ selectedCity: value });
  };

  getFormData() {
    return {
      login: this.state
    };
  }
  login = () => {
    axios
      .get(
        keys.ServicePath +
          "/api/Login?username=" +
          this.state.username +
          "&password=" +
          this.state.password +
          "&ip=" +
          this.state.ip
      )
      .then(res => {
        if (res.data.Success) {
          window.sessionStorage.setItem("isloggedin", 1);
          window.sessionStorage.setItem(
            "isGuest",
            res.data.role === "admin" ? true : false
          );
          window.sessionStorage.setItem("data", res.data);
        } else {
          this.setState({ ValidUserMessage: "Invalid Authentication" });
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  };

  getFormDataPost() {
    if (this.state.otp !== this.state.VerifyOTP) {
      this.setState({
        Message: "Invalid OTP",
        MessageType: "Error",
        MessageHeader: "Validation"
      });
    } else if (
      this.state.firstname === "" ||
      this.state.lastname === "" ||
      this.state.mobile === "" ||
      this.state.email === "" ||
      this.state.selectedCity === "" ||
      this.state.selectedState === ""
    ) {
      this.setState({
        Message: "All fields are mandatory",
        MessageType: "Error",
        MessageHeader: "Validation"
      });
    } else {
      this.props.saveUser({ login: this.state });
    }
  }

  getFormDataPostOTP() {
    if (
      this.state.firstname === "" ||
      this.state.lastname === "" ||
      this.state.mobile === "" ||
      this.state.email === "" ||
      this.state.selectedCity === "" ||
      this.state.selectedState === ""
    ) {
      this.setState({
        Message: "All fields are mandatory",
        MessageType: "Error",
        MessageHeader: "Validation"
      });
      //this.props.saveUser({ login: this.state });
    } else {
      //this.props.saveUser({ login: this.state });

      //this.props.generateOTP({ login: this.state });
      return {
        login: this.state
      };
    }
  }

  verifyCallback = response => {
    if (response) {
      this.setState({ isVerified: true });
      // console.log(response);
    }
  };
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value, ValidUserMessage: "", Message: "" });
  };
  handleOTPChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  cancelVerify = () => {
    this.setState({
      firstname: "",
      otpopen: false,
      ValidUserMessage: "",
      Message: ""
    });
  };
  register = () => {
    this.setState({
      //loginopen: false
      signup: true
    });
  };

  enableSubmitButton = () => {
    this.setState({
      IsbtnSave: true
    });
  };
  disableSubmitButton = () => {
    this.setState({
      IsbtnSave: false
    });
  };
  render() {
    const logindata = this.state;
    let isLoggedin =
      window.sessionStorage.getItem("isloggedin") === null
        ? "0"
        : window.sessionStorage.getItem("isloggedin");
    // console.log(ValidUserMessage);

    if (isLoggedin.toString() === "1") {
      return <Redirect to="/stockview/NSE" />;
    }
    const panes = [
      {
        menuItem: {
          // key: "users",
          // icon: "add user",
          // content: "Register"
          content: (
            <Header as="h2" fluid>
              {/* <Icon name="add user" color="green" /> */}
              <Image src="../asset/images/BI.jpg" height={75} width={75} />
              <Header.Content>
                Registration
                <Header.Subheader>
                  Username and password will be sent on the given Mobile No
                </Header.Subheader>
              </Header.Content>
            </Header>
          )
        },

        render: () => (
          <Tab.Pane attached={true} className="padding30 noborder">
            <Form
              inverted
              onValid={this.disableSubmitButton}
              onInvalid={this.enableSubmitButton}
              onSubmit={() => this.getFormDataPost()}
            >
              {this.state.Message !== "" && (
                <Message
                  // warning={this.state.MessageType === "Error"}
                  // positive={this.state.MessageType !== "Error"}
                  color={this.state.MessageType === "Error" ? "red" : "green"}
                  header={this.state.MessageHeader}
                  list={this.state.Message.split("|")}
                >
                  {/* <Message.Header>{this.state.MessageHeader}</Message.Header>
                  {this.state.Message} */}
                </Message>
              )}
              {!this.state.otpopen && (
                <Form.Group widths="equal">
                  <Form.Field inline>
                    <Popup
                      trigger={
                        <Form.Input
                          className="inputborder"
                          fluid
                          icon="user"
                          iconPosition="left"
                          type="text"
                          name="firstname"
                          //label="First Name"
                          placeholder="First Name "
                          value={this.state.firstname}
                          onChange={this.handleChange}
                          required
                        />
                      }
                      content="Enter First Name"
                      on="focus"
                      inverted
                      color="grey"
                    />
                  </Form.Field>
                  <Form.Field inline>
                    <Popup
                      trigger={
                        <Form.Input
                          className="inputborder"
                          fluid
                          icon="user"
                          iconPosition="left"
                          type="text"
                          name="lastname"
                          // label="Last Name"
                          placeholder="Last Name "
                          value={this.state.lastname}
                          onChange={this.handleChange}
                          required
                        />
                      }
                      content="Enter Last Name"
                      on="focus"
                      inverted
                      color="grey"
                    />
                  </Form.Field>
                </Form.Group>
              )}{" "}
              {!this.state.otpopen && (
                <Form.Group widths="equal">
                  <Form.Input
                    className="inputborder"
                    fluid
                    icon="mail"
                    iconPosition="left"
                    type="text"
                    name="email"
                    // label="Email"
                    placeholder="Email"
                    value={this.state.email}
                    required
                    onChange={this.handleChange}
                  />

                  <Form.Input
                    className="inputborder"
                    fluid
                    icon="tablet"
                    iconPosition="left"
                    type="text"
                    name="mobile"
                    // label="Mobile"
                    required
                    placeholder="Mobile"
                    value={this.state.mobile}
                    onChange={this.handleChange} // }} //   minLength: "Password must have minimum 6 character" //     "Use both numbers and alphabets in password.", //   isAlphanumeric: // validationErrors={{ // validations={{ isAlphanumeric: true, minLength: 6 }}
                  />
                </Form.Group>
              )}{" "}
              {!this.state.otpopen && (
                <Form.Group widths="equal">
                  <Form.Field>
                    <Dropdown
                      placeholder="Select State"
                      fluid
                      search
                      selection
                      options={this.state.states}
                      onChange={this.statechanged}
                      value={this.state.selectedState}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Dropdown
                      placeholder="Select City"
                      fluid
                      search
                      selection
                      options={this.state.cities}
                      onChange={this.citychanged}
                      value={this.state.selectedCity}
                    />
                  </Form.Field>
                </Form.Group>
              )}
              {/* <Form.Group widths="equal">
                  <Form.Field>
                    <Recaptcha
                      sitekey={keys.TEST_SITE_KEY}
                      theme="light"
                      verifyCallback={this.verifyCallback}
                    />
                  </Form.Field>
                </Form.Group> */}
              {this.state.otpopen && (
                <div>
                  <Form.Group widths="equal">
                    <Form.Input
                      fluid
                      icon="mail"
                      iconPosition="left"
                      type="text"
                      name="VerifyOTP"
                      // label="Email"
                      placeholder="Enter OTP"
                      required
                      onChange={this.handleOTPChange}
                    />
                  </Form.Group>
                  <Button.Group fluid red>
                    <Button type="submit" positive icon labelPosition="left">
                      <Icon name="key" />
                      {"Verify & Proceed for Registration"}
                    </Button>
                    <Button.Or />
                    <Button
                      type="button"
                      negative
                      icon
                      labelPosition="right"
                      onClick={this.cancelVerify}
                    >
                      <Icon name="remove" />
                      Cancel
                    </Button>
                  </Button.Group>
                </div>
              )}
              {!this.state.otpopen && (
                <Button.Group fluid red>
                  <Button
                    icon
                    disabled={this.state.IsbtnSave}
                    // disabled={true}
                    type="button"
                    positive
                    labelPosition="left"
                    onClick={() =>
                      this.props.generateOTP(this.getFormDataPostOTP())
                    }
                  >
                    <Icon name="key" />
                    Signup
                  </Button>
                  <Button.Or />
                  <Button
                    type="button"
                    negative
                    icon
                    labelPosition="right"
                    onClick={this.cancelVerify}
                    disabled
                  >
                    <Icon name="remove" />
                    Cancel
                  </Button>
                </Button.Group>
              )}
            </Form>
          </Tab.Pane>
        )
      },
      {
        menuItem: {
          content: (
            <Header as="h2" fluid>
              <Icon name="key" />
              <Header.Content>Login</Header.Content>
            </Header>
          )
        },
        render: () => (
          <Tab.Pane attached={true} className="padding30 noborder">
            {" "}
            <Form>
              {/* {"Your IP : " + this.state.ip} */}

              <Form.Field inline>
                {/* <label>Username</label> */}
                <Popup
                  wide="very"
                  trigger={
                    <Form.Input
                      icon="user"
                      fluid
                      iconPosition="left"
                      type="text"
                      name="username"
                      placeholder="Username"
                      value={logindata.username}
                      onChange={this.handleChange}
                    />
                  }
                  content="Enter username!!!"
                  on="focus"
                  inverted
                  color="grey"
                />
              </Form.Field>
              <Form.Field inline>
                {/* <label>Password</label> */}
                <Popup
                  wide="very"
                  trigger={
                    <Form.Input
                      icon="eye"
                      iconPosition="left"
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={logindata.password}
                      onChange={this.handleChange}
                      fluid
                    />
                  }
                  content="Enter your password!!!"
                  on="focus"
                  inverted
                  color="grey"
                />
              </Form.Field>
              <Divider section hidden />
              <Responsive
                as={Button}
                {...Responsive.onlyMobile}
                content="Coming Soon for mobile version"
                icon="mobile alternate"
                labelPosition="left"
                fluid
              />
              <Responsive
                {...Responsive.onlyComputer}
                as={Button}
                content="Login"
                icon="send"
                labelPosition="left"
                onClick={() => this.props.loginClick(this.getFormData())}
                //onClick={this.login}
                positive
                fluid
              />
            </Form>
          </Tab.Pane>
        )
      }
    ];
    //console.log("not entered", isLoggedin === 1);
    return (
      <Container>
        <Responsive as={Container} minWidth={Responsive.onlyTablet.minWidth}>
          <Divider section hidden />
          <Container className="whitebg">
            <Tab
              menu={{ secondary: true, pointing: true, color: "red" }}
              panes={panes}
            />
            <Container className="padding2">
              <Label basic color="red" size="medium">
                {
                  ' As trading in Stock Market is speculative in nature and involves a high degree if volatility and risk, it is advisable to understand the levels of risk as well as to do research before using the Indicators provided by "BAZAAR INDICATORS".'
                }
                <br />
                {
                  "Essentially, these are only guidelines which would help traders & investors in educating themseleves."
                }
                <br />
                <br />
                {
                  'The Indicators and notes provided by "BAZAAR INDICATORS" do not guarantee performances.Hence, its owner & developers are not responsible for any losses or damagwes, monetary or otherwise,              that may result from the content of "BAZAAR INDICATORS".'
                }
              </Label>
            </Container>
          </Container>

          {this.state.ValidUserMessage !== "" && (
            <Message color="red">
              <Message.Header>Authentication</Message.Header>
              {this.state.ValidUserMessage}
            </Message>
          )}
          {/* <Advertisement unit="leaderboard">
            <AdSense.Google
              client="ca-pub-4591861188995436"
              slot="4640466102"
              responsive="true"
            />
          </Advertisement> */}
        </Responsive>
        <Responsive
          as={Segment}
          content="Coming Soon For Mobile version"
          icon="mobile"
          labelPosition="left"
          {...Responsive.onlyMobile}
        >
          <Image src="../asset/images/BI.jpg" size="small" rounded />
          <br />
          <Segment color="blue">Coming Soon For Mobile version</Segment>
        </Responsive>
      </Container>
    );
  }
}
const mapStateToProps = state => {
  // console.log(state);
  return {
    state
  };
};
const mapDispatchToProps = dispatch => {
  return {
    loginClick: form => dispatch(actionCreators.login(form.login)),
    saveUser: objFormData =>
      dispatch(actionCreators.saveUser(objFormData.login)),
    generateOTP: objFormData =>
      dispatch(actionCreators.generateOTP(objFormData.login))
  };
  //handleChange: (e) => dispatch(actionCreators.setUserState(e.target))
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginAuth);
