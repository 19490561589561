import React, { Component } from "react";
import { Table, Grid } from "semantic-ui-react";

class AverageDays extends Component {
  constructor(props) {
    super(props);
    this.state = this.props;
  }
  render() {
    return (
      <Grid columns="equal">
        <Grid.Column>
          <Table basic>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Avg. Days</Table.HeaderCell>
                <Table.HeaderCell>Open</Table.HeaderCell>
                <Table.HeaderCell>High</Table.HeaderCell>
                <Table.HeaderCell>Low</Table.HeaderCell>
                <Table.HeaderCell>Close</Table.HeaderCell>
                <Table.HeaderCell>Volume</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {this.props.data.map(
                (listValue, index) => {
                  var vals = listValue.value.split("|");
                  var obj = {
                    open: vals[0],
                    high: vals[1],
                    low: vals[2],
                    close: vals[3],
                    volume: vals[4]
                  };
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>{listValue.text}</Table.Cell>
                      <Table.Cell>{Number(obj.open).toFixed(2)}</Table.Cell>
                      <Table.Cell>{Number(obj.high).toFixed(2)}</Table.Cell>
                      <Table.Cell>{Number(obj.low).toFixed(2)}</Table.Cell>
                      <Table.Cell>{Number(obj.close).toFixed(2)}</Table.Cell>
                      <Table.Cell>{Number(obj.volume).toFixed(0)}</Table.Cell>
                    </Table.Row>
                  );
                }
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    );
  }
}

export default AverageDays;
