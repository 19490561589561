import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Header,
  Segment,
  Icon,
  Button,
  Dimmer,
  Loader,
  Message,
  Input,
  Divider
} from "semantic-ui-react";

import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
const keys = require("../../config/keys");

class PathSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      Message: "",
      Success: false,
      bse: "",
      bsegrossdata: "",
      nse: "",
      nsemto: "",
      currencynse: "",
      currencymcx: "",
      currencynsenew: "",
      FOandOP: ""
    };
  }
  componentDidMount() {
    axios
      .get(keys.ServicePath + "/api/GetPaths")
      .then(res => {
        if (res.data.Success) {
          this.setState({
            bse: res.data.paths[0].bse,
            bsegrossdata: res.data.paths[0].bsegrossdata,
            nse: res.data.paths[0].nse,
            nsemto: res.data.paths[0].nsemto,
            currencynse: res.data.paths[0].currencynse,
            currencymcx: res.data.paths[0].currencymcx,
            FOandOP: res.data.paths[0].FOandOP,
            currencynsenew: res.data.paths[0].currencynsenew
          });
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  }

  handleChange = (e, { name, value }) => {
    if (name === "bse") this.setState({ bse: value });
    else if (name === "nse") this.setState({ nse: value });
    else if (name === "bsegrossdata")
      this.setState({
        bsegrossdata: value
      });
    else if (name === "nsemto")
      this.setState({
        nsemto: value
      });
    else if (name === "currencynse")
      this.setState({
        currencynse: value
      });
    else if (name === "currencymcx")
      this.setState({
        currencymcx: value
      });
    else if (name === "FOandOP")
      this.setState({
        FOandOP: value
      });
    else if (name === "currencynsenew")
      this.setState({
        currencynsenew: value
      });
  };
  getFormData = () => {
    return {
      paths: {
        bse: this.state.bse,
        bsegrossdata: this.state.bsegrossdata,
        nse: this.state.nse,
        nsemto: this.state.nsemto,
        currencynse: this.state.currencynse,
        currencymcx: this.state.currencymcx,
        FOandOP: this.state.FOandOP
      }
    };
  };
  saveData = () => {
    var paths = {
      bse: this.state.bse,
      bsegrossdata: this.state.bsegrossdata,
      nse: this.state.nse,
      nsemto: this.state.nsemto,
      currencynse: this.state.currencynse,
      currencymcx: this.state.currencymcx,
      currencynsenew: this.state.currencynsenew,
      FOandOP: this.state.FOandOP
    };
    axios
      .post(keys.ServicePath + "/api/UpdatePaths", paths)
      .then(res => {
        if (res.data.Success) {
          this.setState({
            bse: res.data.paths[0].bse,
            bsegrossdata: res.data.paths[0].bsegrossdata,
            nse: res.data.paths[0].nse,
            nsemto: res.data.paths[0].nsemto,
            currencynse: res.data.paths[0].currencynse,
            currencymcx: res.data.paths[0].currencymcx,
            FOandOP: res.data.paths[0].FOandOP,
            currencynsenew: res.data.paths[0].currencynsenew,
            Message: res.data.Message,
            Success: res.data.Success
          });
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  };
  render() {
    let data = this.state;
    console.log(data);
    return (
      <Container>
        {data.Success && (
          <Message info>
            <Message.Header>{data.Header}</Message.Header>
            <p>{data.Message}</p>
          </Message>
        )}

        <Header as="h3" attached="top">
          Path Settings
        </Header>
        <Segment color="orange" attached padded className="whitebg">
          <Divider horizontal>
            <Header as="h4">
              <Icon name="tag" />
              BSE
            </Header>
          </Divider>

          <div className="row">
            <div className="col-md-8">
              <Input
                label="BSE Bhavcopy"
                placeholder=""
                labelPosition="left"
                fluid
                name="bse"
                value={this.state.bse}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <Input
                label="BSE Data"
                placeholder=""
                labelPosition="left"
                fluid
                name="bsegrossdata"
                value={this.state.bsegrossdata}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <Divider horizontal>
            <Header as="h4">
              <Icon name="tag" />
              NSE
            </Header>
          </Divider>
          <div className="row">
            <div className="col-md-8">
              <Input
                label="NSE"
                placeholder=""
                labelPosition="left"
                fluid
                value={this.state.nse}
                name="nse"
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <Input
                label="NSE MTO"
                placeholder=""
                labelPosition="left"
                fluid
                value={this.state.nsemto}
                name="nsemto"
                onChange={this.handleChange}
              />
            </div>
          </div>

          <Divider horizontal>
            <Header as="h4">
              <Icon name="tag" />
              Currency
            </Header>
          </Divider>
          <div className="row">
            <div className="col-md-8">
              <Input
                label="Currency NSE"
                placeholder=""
                labelPosition="left"
                fluid
                name="currencynse"
                value={this.state.currencynse}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <Input
                label="Currency MCX"
                placeholder=""
                labelPosition="left"
                fluid
                name="currencymcx"
                value={this.state.currencymcx}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <Input
                label="Currency NSE New"
                placeholder=""
                labelPosition="left"
                fluid
                name="currencynsenew"
                value={this.state.currencynsenew}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <Divider horizontal>
            <Header as="h4">
              <Icon name="tag" />
              FO & OP
            </Header>
          </Divider>
          <div className="row">
            <div className="col-md-8">
              <Input
                label="FO & OP"
                placeholder=""
                labelPosition="left"
                fluid
                value={this.state.FOandOP}
                name="FOandOP"
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Button
                icon
                labelPosition="left"
                primary
                size="small"
                onClick={this.saveData}
              >
                <Icon name="settings" />
                Update Path Settings
              </Button>
            </div>
          </div>
          <Dimmer active={data.showLoader} inverted>
            <Loader size="large">Uploading Data</Loader>
          </Dimmer>
        </Segment>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    state
  };
};
const mapDispatchToProps = dispatch => {
  return {
    //savePath: objFormData => dispatch(actionCreators.savePath(objFormData))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PathSettings);
