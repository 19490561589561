import * as actionTypes from '../action/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    CompleteList: {
        lstbse: [],
        lstnse: [],
        lstnseoption: []
    },
    bsescriptname: [],
    bsedetails: [],
    nsescriptname: [],
    nseoptionscriptname: [],
    selectedbsescriptvalue: ''

}
const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.SCRIPTLIST:
            return updateObject(state.bsescriptname, action.payload);
        case actionTypes.SCRIPTLISTNSE:
            return updateObject(state.nsescriptname, action.payload);
        case actionTypes.SCRIPTLISTNSEOP:
            return updateObject(state.nseoptionscriptname, action.payload);
        case actionTypes.BSEDETAILS:
            return updateObject(state, action.payload);
        case actionTypes.NSEDETAILS:
            return updateObject(state, action.payload);
        case actionTypes.NSEOPDETAILS:
            return updateObject(state, action.payload);
        case actionTypes.HIGHLOWTREND:
            return updateObject(state.highlowddata, action.payload);
        default:
            return updateObject(state, initialState);
    }
}

export default reducer;