import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

class HistoricalData extends Component {
  constructor(props) {
    super(props);
    // this.state = this.props;

    this.state = {
      rowData: this.props.rowData,
      high: this.props.high,
      low: this.props.low,
      spHistDefs: [
        { field: "STRDTDATE", headerName: "DATE", width: 100 },
        {
          field: "OPEN",
          width: 100,
          valueGetter: function decimal(params) {
            return Number(params.data.OPEN, 2).toFixed(2);
          }
        },
        {
          field: "HIGH",
          width: 100,
          valueGetter: function decimal(params) {
            return Number(params.data.HIGH, 2).toFixed(2);
          },
          cellClassRules: {
            negativecell: function(params) {
              
              return (
                Number(params.data.HIGH, 2).toFixed(2) ===
                Number(props.high, 2).toFixed(2)
              );
            }
          }
        },
        {
          field: "LOW",
          width: 100,
          valueGetter: function decimal(params) {
            return Number(params.data.LOW, 2).toFixed(2);
          },
          cellClassRules: {
            positivecell: function(params) {

              return (
                Number(params.data.LOW, 2).toFixed(2) ===
                Number(props.low, 2).toFixed(2)
              );
            }
          }
        },
        {
          field: "CLOSE",
          width: 100,
          valueGetter: function decimal(params) {
            return Number(params.data.CLOSE, 2).toFixed(2);
          }
        },
        { field: "OPENINT", headerName: "OPEN INT", width: 100 },
        { field: "TRDQTY", headerName: "VOLUME", width: 100 }
      ]
    };
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    //var allColumnIds = [];
    // this.gridColumnApi.getAllColumns().forEach(function(column) {
    //   allColumnIds.push(column.colId);
    // });
    // this.gridColumnApi.autoSizeColumns(allColumnIds);
    //this.gridApi.sizeColumnsToFit();
  }
  ExportClick = () => {
    var params = {
      skipHeader: false,
      columnGroups: false,
      skipFooters: true,
      allColumns: true,
      onlySelected: false,
      suppressQuotes: false,
      fileName: "HistoricalData_" + this.state.rowData.STRPRICE + ".csv"
    };
    this.gridApi.exportDataAsCsv(params);
  };
  render() {
    return <div className="container">
        <div className="row">
          <div className="col-md-11" />
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-default">
              <div className="panel-heading">
                <div className="row">
                  <div className="col-md-12">
                    Stike Price : <b>{this.state.rowData.STRPRICE}</b> &emsp; OP : <b
                    >
                      {Number(this.state.rowData.OPEN, 2).toFixed(2)}
                    </b> &emsp; HI : <b>
                      {Number(this.state.rowData.HIGH, 2).toFixed(2)}
                    </b> &emsp; LO : <b>
                      {Number(this.state.rowData.LOW, 2).toFixed(2)}
                    </b> &emsp; CL : <b>
                      {Number(this.state.rowData.CLOSE, 2).toFixed(2)}
                    </b> &emsp; OP INT : <b>{this.state.rowData.OPENINT}</b> &emsp; VOL : <b
                    >
                      {this.state.rowData.TRDQTY}
                    </b>
                    &emsp;
                  </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="height400  ag-theme-balham">
                <AgGridReact className="ag-theme-balham" rowData={this.state.rowData.HISTORICAL} enableSorting suppressColumnVirtualisation={true} enableColResize={true} columnDefs={this.state.spHistDefs} onGridReady={this.onGridReady.bind(this)} rowSelection={this.state.rowSelection} />
                </div>
              </div>
              <div className="panel-footer">
                <Button color="green" floated="right" onClick={this.ExportClick}>
                  <Icon name="file excel outline" />
                  Export to CSV
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>;
  }
}
const mapStateToProps = state => {
  return {
    historicaldata: state
  };
};
const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoricalData);
