import * as actionTypes from "../action/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  username: "",
  password: "",
  isLoggedIn: false
};
const reducer = (state = initialState, action) => {
  //console.log(action)

  switch (action.type) {
    case actionTypes.LOGIN:
      return updateObject(state, action.objLogin);
    case actionTypes.LOGOUT:
      return updateObject(state, { isLoggedIn: 0 });
    case actionTypes.SETUSERSTATE:
      return updateObject(state, state);
    case actionTypes.ADDUSER:
      return updateObject(state, action.objUser);
    case actionTypes.SAVEBROADCAST:
      return updateObject(state, action.result);
    default:
      return updateObject(state, state);
  }
};

export default reducer;
