import React, { Component } from "react";
import {
  Container,
  Grid,
  Button,
  Modal,
  Header,
  Table,
  Dropdown,
  Input,
  Icon
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import Select from "react-virtualized-select";
import moment from "moment";
import axios from "axios";
import ChartComponent from "../Common/ChartComponent";
import FibboLevel from "../Common/FibboLevel";
import BILevel from "../Common/biLevel";
import OpenIntOutlook from "../nseoption/openIntOutlook";
import DeliveryQty from "../nseoption/deliveryQty";
import Pivot from "../Common/pivot/pivot";
import { connect } from "react-redux";
import Strategy from "../Common/Strategy/Strategy";

import AverageDays from "../Common/AvgDays/avgDays";
import HighLowStats from "../Common/AllTimeHigh/highlowstats";
import ReactHtmlParser from "react-html-parser"; // } //   htmlparser2 //   convertNodeToElement, //   processNodes, //, {
//import { PieChart } from "react-easy-chart";

import BlockUi from "react-block-ui";
import "react-block-ui/style.css";

import "react-datepicker/dist/react-datepicker.css";
import "react-select/dist/react-select.css";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css";

const keys = require("../../config/keys");
const udata = JSON.parse(window.sessionStorage.getItem("data"));
class Stock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isindex: false,
      nsedetails: [],
      selectedscript: "Nifty50|NSE|Index",
      rowSelection: "single",
      highlowddata: [],
      trendData: [],
      indicators: {},
      tradingrange: {},
      dashboardtype: "daily",
      swingdata: [],
      strategy: [],
      chartdetails: [],
      pivotCenterPoint: [],
      pivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      dpivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      wpivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      mpivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      qpivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      ypivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      high: 0.0,
      low: 0.0,
      close: 0.0,
      Downtrendfibbo: [],
      Uptrendfibbo: [],
      biLevels: {},
      IsFutur: false,
      width: 1024,
      selectedfolder: "NIFTY",
      avgVals: { open: 0, high: 0, low: 0, close: 0, volume: 0 },
      scriptDetails: { dtDate: "", ODate: moment() },
      scripDate: moment(),
      menutype: props.match.params.type,
      broadcast: [],
      alltimehighlow: [],
      open: false,
      dimmeractive: true
    }; // scriptname: [],
  }
  fetchBroadcast = () => {
    axios
      .get(keys.ServicePath + "/api/GetBroadcastListUsers")
      .then(res => {
        if (res.data.Success) {
          this.setState({
            broadcast: res.data.data
          });
          if (res.data.data.length > 0) {
            this.setState({
              open: true
            });
          }
        } else {
          // console.log("else");
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  };
  fetchData = () => {
    axios
      .get(
        keys.ServicePath +
          "/api/GetLiveStock?scrip=" +
          this.state.selectedscript.split("|")[0]
      )
      .then(res => {
        if (res.data.Success) {
          this.setState({
            livedata: res.data.LiveData,
            latestdata: res.data.NSEUpdated
          });
        } else {
          // console.log("else");
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  };
  componentDidMount() {
    var path = keys.ServicePath + "/api/GetStockFolders";
    console.log(this.state.menutype);
    if (
      this.state.menutype === "NSE" ||
      this.state.menutype === "BSE" ||
      this.state.menutype === "CURRENCY"
    ) {
      path =
        keys.ServicePath + "/api/GetFoldersScrips?type=" + this.state.menutype;
    } else if (this.state.menutype === "MyFolder") {
      path = keys.ServicePath + "/api/GetUserFolders?userId=" + udata.data.Id;
    }
    axios
      .get(path)
      .then(res => {
        console.log(res.data);

        if (res.data.Success) {
          this.setState(
            {
              folders: res.data.Folders,
              selectedfolder:
                this.state.menutype === "NSE" || this.state.menutype === "BSE"
                  ? this.state.menutype
                  : res.data.Folders[0].folder,
              rawscript: res.data.ScriptList,

              scriptname: res.data.ScriptList,
              scriptDetails: res.data.ScriptDetails,
              scripDate: moment(res.data.ScriptDetails.ODate)
            },
            () => {
              console.log("1", res.data.ScriptDetails);
              var sscript = "Nifty50|NSE|Index";
              if (this.state.menutype === "NSE") {
                sscript = "Nifty50|NSE|Index";
              } else if (this.state.menutype === "BSE") {
                sscript = "1|BSE|Index";
              } else if (this.state.menutype === "CURRENCY") {
                sscript = "MCXUSDINR NEAR|CURRENCY|MCX";
              } else {
                sscript = res.data.ScriptList[0].value;
              }
              this.setState({ selectedscript: sscript }, () => {
                this.GetScripTrenDetails();
              });
              //
            }
          );
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
        this.setState({ dimmeractive: false });
      });
    this.interval = setInterval(() => {
      var hour = new Date().getHours();
      if (hour >= 9 && hour < 16) {
        this.fetchData();
      }
    }, 2000);
    this.fetchData();
    //this.fetchBroadcast();
  }
  handleChange = date => {
    this.setState(
      {
        scripDate: date
      },
      () => {
        this.GetScripTrenDetails();
      }
    );
  };
  close = () => this.setState({ open: false });

  componentWillReceiveProps(nextProps, prevProps) {
    if (nextProps.match.params.type != null) {
      console.log("InStock Folder", nextProps.match.params.type);
      var path = keys.ServicePath + "/api/GetStockFolders";
      this.setState({
        menutype: nextProps.match.params.type,
        dimmeractive: true
      });
      if (
        nextProps.match.params.type === "NSE" ||
        nextProps.match.params.type === "BSE" ||
        nextProps.match.params.type === "CURRENCY"
      ) {
        path =
          keys.ServicePath +
          "/api/GetFoldersScrips?type=" +
          nextProps.match.params.type;
      } else if (nextProps.match.params.type === "MyFolder") {
        path = keys.ServicePath + "/api/GetUserFolders?userId=" + udata.data.Id;
      }

      axios
        .get(path)
        .then(res => {
          if (res.data.Success) {
            this.setState(
              {
                folders: res.data.Folders,
                selectedfolder:
                  nextProps.match.params.type === "NSE" ||
                  nextProps.match.params.type === "BSE" ||
                  nextProps.match.params.type === "CURRENCY"
                    ? nextProps.match.params.type
                    : res.data.Folders[0],
                rawscript: res.data.ScriptList,

                scriptname: res.data.ScriptList,
                scriptDetails: res.data.ScriptDetails,
                scripDate: moment(res.data.ScriptDetails.ODate)
              },
              () => {
                var sscript = "Nifty50|NSE|Index";
                if (nextProps.match.params.type === "NSE") {
                  sscript = "Nifty50|NSE|Index";
                } else if (nextProps.match.params.type === "BSE") {
                  sscript = "1|BSE|Index";
                } else if (nextProps.match.params.type === "CURRENCY") {
                  sscript = "MCXUSDINR NEAR|CURRENCY|MCX";
                } else {
                  sscript = res.data.ScriptList[0].value;
                }

                this.setState({ selectedscript: sscript }, () => {
                  this.GetScripTrenDetails();
                  // if (
                  //   nextProps.match.params.type === "NSE" ||
                  //   nextProps.match.params.type === "BSE" ||
                  //   nextProps.match.params.type === "CURRENCY"
                  // ) {
                  //   this.GetScripTrenDetails();
                  // }
                });
                //
              }
            );
          } else {
            this.setState({ dimmeractive: false });
          }
        })
        .catch(e => {
          this.setState({ dimmeractive: false });
          console.log("catch error", JSON.stringify(e));
        });
    }
  }
  frequencyChange = (e, { name, value }) => {
    this.setState({ dashboardtype: value, dimmeractive: true }, () => {
      this.GetScripTrenDetails();
    });
  };
  GetScripTrenDetails() {
    var objProg = [];

    axios
      .get(
        keys.ServicePath +
          "/api/HighLowTrend?date=" +
          //this.state.scriptDetails.dtDate +
          moment(this.state.scripDate).format("DD/MM/YYYY") +
          "&script=" +
          this.state.selectedscript.split("|")[0] +
          "&dbType=" +
          this.state.selectedscript.split("|")[1].toLowerCase() +
          "&close=" +
          this.state.scriptDetails.close +
          "&type=" +
          this.state.dashboardtype
      )
      .then(res => {
        if (res.data.Success) {
          this.setState({
            highlowddata: res.data.highlowddata,
            trendData: res.data.trend,
            indicators: res.data.indicator,
            tradingrange: res.data.tradingrange,
            swingdata: res.data.swing,
            strategy: res.data.strategy,
            isPositiveStrategy: res.data.isPositiveStrategy,
            strategyHeader: res.data.strategyHeader,
            triggerValue: res.data.triggerValue,
            pivotCenterPoint: res.data.pivotCenterPoint,
            dpivotLevel: res.data.dailypivotLevel,
            wpivotLevel: res.data.weeklypivotLevel,
            mpivotLevel: res.data.monthlypivotLevel,
            qpivotLevel: res.data.quarterlypivotLevel,
            ypivotLevel: res.data.yearlypivotLevel,
            biLevels: res.data.biLevels,
            openIntOutlook: res.data.openIntOutlook,
            deliveryQty: res.data.deliveryQty,
            cycleStatus: res.data.cycleStatus,
            xdaysAvg: res.data.lastxdays,
            selectedavg: res.data.lastxdays[0].value,
            scriptDetails: res.data.ScriptDetails,
            alltimehighlow: res.data.alltimehighlow
          });

          for (var i = 0; i < res.data.chartdata.length; i++) {
            var obj = {
              date: new Date(Date.parse(res.data.chartdata[i].dtDate)),
              open: res.data.chartdata[i].open,
              high: res.data.chartdata[i].high,
              low: res.data.chartdata[i].low,
              close: res.data.chartdata[i].close,
              volume: res.data.chartdata[i].volume
            };

            objProg.push(obj);
          }
          var vals = res.data.lastxdays[0].value.split("|");
          var obj1 = {
            open: vals[0],
            high: vals[1],
            low: vals[2],
            close: vals[3],
            volume: vals[4]
          };
          this.setState({
            avgVals: obj1,
            selectedavg: res.data.lastxdays[0].value
          });

          this.setState({ chartdetails: objProg, dimmeractive: false }); //,
        } else {
          //console.log("else");
          this.setState({ dimmeractive: false });
        }
      })
      .catch(e => {
        this.setState({ dimmeractive: false });
        console.log("catch error", JSON.stringify(e));
      });
  }

  folderchange = newvalue => {
    this.setState({ selectedfolder: newvalue }, () => {
      var folderscrips = this.state.rawscript.filter(function(scrips) {
        return scrips.folder === newvalue;
      });

      this.setState(
        { scriptname: folderscrips, selectedscript: folderscrips[0].value },
        () => {
          this.GetScripTrenDetails();
        }
      );
    });
  };
  chartChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  scripchnage = (e, { value, text }) => {
    this.setState({ selectedscript: value, dimmeractive: true }, () => {
      axios
        .get(keys.ServicePath + "/api/GuestScriptChange?scripValue=" + value)
        .then(res => {
          if (res.data.Success) {
            this.setState(
              {
                scriptDetails: res.data.ScriptDetails,
                scripDate: moment(res.data.ScriptDetails.ODate)
              },
              () => {
                this.GetScripTrenDetails();
              }
            );
          }
        })
        .catch(e => {
          console.log("catch error", JSON.stringify(e));
        });
    });
  };
  handleDismiss = () => {
    this.setState({ broadcast: [] });

    // setTimeout(() => {
    //   this.setState({ visible: true })
    // }, 2000)
  };
  render() {
    return (
      <BlockUi tag="div" blocking={this.state.dimmeractive}>
        <Container fluid>
          <Grid className="padding5">
            <Grid.Row>
              {(this.state.menutype === "BIFolder" ||
                this.state.menutype === "MyFolder") && (
                <Grid.Column width={2}>
                  {/* <Dropdown
                  placeholder="Select Script"
                  fluid
                  search
                  selection
                  options={this.state.folders}
                  onChange={this.folderchange}
                  value={this.state.selectedfolder}
                /> */}
                  <Select
                    options={this.state.folders}
                    simpleValue
                    value={this.state.selectedfolder}
                    onChange={this.folderchange}
                    searchable
                    labelKey="text"
                    valueKey="value"
                  />
                </Grid.Column>
              )}
              {this.state.menutype !== "BIFolder" &&
                this.state.menutype !== "MyFolder" && (
                  <Grid.Column
                    width={1}
                    textAlign="right"
                    verticalAlign="middle"
                  >
                    <label>{this.state.menutype}</label>
                  </Grid.Column>
                )}
              <Grid.Column width={4}>
                <Dropdown
                  placeholder="Select Script"
                  fluid
                  search
                  selection
                  options={this.state.scriptname}
                  onChange={this.scripchnage}
                  value={this.state.selectedscript}
                />
              </Grid.Column>
              <Grid.Column width={2}>
                <Dropdown
                  placeholder="Chart Type"
                  fluid
                  search
                  selection
                  options={keys.enums.frequency}
                  onChange={this.frequencyChange}
                  value={this.state.dashboardtype}
                  name="dashboardtype"
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <Modal
                  trigger={
                    <Button color="blue" size="small">
                      BI Levels
                    </Button>
                  }
                  closeIcon
                  size="mini"
                  closeOnEscape={true}
                  closeOnDimmerClick={true}
                >
                  <Header icon="chart line" content="BI Levels" />
                  <Modal.Content>
                    <BILevel data={this.state.biLevels} />
                  </Modal.Content>
                </Modal>
                <Modal
                  trigger={
                    <Button color="blue" size="small">
                      Pivot
                    </Button>
                  }
                  closeIcon
                  size="small"
                  closeOnEscape={true}
                  closeOnDimmerClick={true}
                >
                  <Header icon="chart line" content="Pivot" />
                  <Modal.Content>
                    <Pivot
                      menutype={this.state.menutype}
                      data={this.state}
                      showDaily={false}
                      showWeekly={true}
                      showMonthly={true}
                      showQuarterly={true}
                      showYearly={true}
                    />
                  </Modal.Content>
                </Modal>
                <Modal
                  trigger={
                    <Button color="blue" size="small">
                      Fibbo Level
                    </Button>
                  }
                  size="small"
                  closeIcon
                  closeOnEscape={true}
                  closeOnDimmerClick={true}
                >
                  <Header icon="chart line" content="Fibbo Levels" />
                  <Modal.Content>
                    <FibboLevel
                      high={this.state.scriptDetails.high}
                      low={this.state.scriptDetails.low}
                      close={this.state.scriptDetails.close}
                      symbol={this.state.selectedscript}
                    />
                  </Modal.Content>
                </Modal>

                <Modal
                  trigger={
                    <Button
                      color="blue"
                      size="small"
                      disabled={
                        !this.state.selectedscript
                          .toLowerCase()
                          .includes("mnth")
                      }
                    >
                      OpenInt
                    </Button>
                  }
                  closeIcon
                  size="small"
                  closeOnEscape={true}
                  closeOnDimmerClick={true}
                >
                  <Header icon="chart line" content="Open Interest Outlook" />
                  <Modal.Content>
                    <OpenIntOutlook
                      data={this.state.openIntOutlook}
                      strategyHeader={this.state.strategyHeader}
                      isPositiveStrategy={this.state.isPositiveStrategy}
                    />
                  </Modal.Content>
                </Modal>

                <Modal
                  trigger={
                    <Button
                      color="blue"
                      size="small"
                      disabled={this.state.deliveryQty == null ? true : false}
                    >
                      DelQty
                    </Button>
                  }
                  closeIcon
                  size="small"
                  closeOnEscape={true}
                  closeOnDimmerClick={true}
                >
                  <Header icon="chart line" content="Delivery Quantity" />
                  <Modal.Content>
                    <DeliveryQty data={this.state.deliveryQty} />
                  </Modal.Content>
                </Modal>

                <Button.Group color="blue">
                  <Dropdown button text="Others" floating>
                    <Dropdown.Menu>
                      <Modal
                        trigger={<Dropdown.Item text="Average Days" />}
                        closeIcon
                        size="small"
                        closeOnEscape={true}
                        closeOnDimmerClick={true}
                      >
                        <Header icon="chart line" content="Average Days" />
                        <Modal.Content>
                          <AverageDays data={this.state.xdaysAvg} />
                        </Modal.Content>
                      </Modal>

                      <Modal
                        trigger={<Dropdown.Item text="High Low Stats" />}
                        closeIcon
                        size="mini"
                        closeOnEscape={true}
                        closeOnDimmerClick={true}
                      >
                        <Header icon="chart line" content="High Low Stats" />
                        <Modal.Content>
                          <HighLowStats data={this.state.alltimehighlow} />
                        </Modal.Content>
                      </Modal>

                      {/* <Modal
                      trigger={<Dropdown.Item text="Cycle Status" />}
                      closeIcon
                      size="small"
                      closeOnEscape={false}
                      closeOnDimmerClick={false}
                    >
                      <Header icon="chart line" content="Cycle Status" />
                      <Modal.Content>
                       
                      </Modal.Content>
                    </Modal> */}
                    </Dropdown.Menu>
                  </Dropdown>
                  {this.state.broadcast.length > 0 && (
                    <Modal
                      trigger={<Dropdown.Item text="High Low Stats" />}
                      closeIcon
                      open={this.state.open}
                      onClose={
                        this.close // onOpen={this.open}
                      }
                      size="small"
                      closeOnEscape={true}
                      closeOnDimmerClick={true}
                    >
                      <Header icon="chart line" content="Important" />
                      <Modal.Content>
                        {ReactHtmlParser(this.state.broadcast[0].message)}
                      </Modal.Content>
                    </Modal>
                  )}
                </Button.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={5}>
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Table color="orange" basic compact>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell colSpan={2}>
                              <DatePicker
                                dateFormat="DD/MM/YYYY"
                                selected={this.state.scripDate}
                                onChange={this.handleChange}
                                shouldCloseOnSelect={true}
                                customInput={
                                  <Input
                                    placeholder="Date"
                                    icon="calendar alternate"
                                    iconPosition="left"
                                  />
                                }
                              />
                              {/* {this.state.scriptDetails.dtDate} */}
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>Open</Table.Cell>
                            <Table.Cell>
                              {" "}
                              {Number(this.state.scriptDetails.open).toFixed(
                                this.state.menutype === "CURRENCY" ? 4 : 2
                              )}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>High</Table.Cell>
                            <Table.Cell>
                              {" "}
                              {Number(this.state.scriptDetails.high).toFixed(
                                this.state.menutype === "CURRENCY" ? 4 : 2
                              )}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Low</Table.Cell>
                            <Table.Cell>
                              {Number(this.state.scriptDetails.low).toFixed(
                                this.state.menutype === "CURRENCY" ? 4 : 2
                              )}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Close</Table.Cell>
                            <Table.Cell>
                              {Number(this.state.scriptDetails.close).toFixed(
                                this.state.menutype === "CURRENCY" ? 4 : 2
                              )}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Volume</Table.Cell>
                            <Table.Cell>
                              {!this.state.selectedscript
                                .toLowerCase()
                                .includes("index")
                                ? Number(
                                    this.state.scriptDetails.volume
                                  ).toFixed(0)
                                : "-"}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Delivery Qty</Table.Cell>
                            <Table.Cell>
                              {!this.state.selectedscript
                                .toLowerCase()
                                .includes("index")
                                ? Number(
                                    this.state.menutype === "CURRENCY"
                                      ? this.state.scriptDetails.trd_qty
                                      : this.state.scriptDetails.del_qty
                                  ).toFixed(0)
                                : "-"}
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                    <Grid.Column>
                      <Table color="orange" basic compact className="nomargin">
                        <Table.Header>
                          <Table.Row>
                            {this.state.livedata && (
                              <Table.HeaderCell colSpan={2}>
                                {/* {this.state.scriptDetails.dtDate} */}

                                <span>{this.state.livedata.ltP}</span>
                                <span className="inr">{" INR"}</span>

                                <span
                                  className={
                                    this.state.livedata.iislPtsChange < 0
                                      ? "datachangenegative"
                                      : "datachangepositive"
                                  }
                                >
                                  {" " + this.state.livedata.iislPtsChange}
                                </span>
                                <span
                                  className={
                                    this.state.livedata.iislPercChange < 0
                                      ? "datachangenegative"
                                      : "datachangepositive"
                                  }
                                >
                                  {" (" +
                                    this.state.livedata.iislPercChange +
                                    "%) "}
                                </span>

                                {this.state.livedata.iislPercChange < 0 && (
                                  <Icon
                                    className="datachangenegative"
                                    name="arrow down"
                                  />
                                )}
                                {this.state.livedata.iislPercChange > 0 && (
                                  <Icon
                                    className="datachangepositive"
                                    name="arrow up"
                                  />
                                )}

                                {/* { <svg version="1.1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" xmlns: xlink="http://www.w3.org/1999/xlink"><path d="M6,0.002L0 6.002 4.8 6.002 4.8 11.9996 7.2 11.9996 7.2 6.002 12 6.002z"></path>
                            </svg>
                           } */}
                                {/* {this.state.scriptDetails.dtDate} */}
                              </Table.HeaderCell>
                            )}
                          </Table.Row>
                        </Table.Header>
                      </Table>
                      {this.state.dashboardtype === "daily" && (
                        <Table
                          basic
                          compact="very"
                          className={
                            this.state.dpivotLevel.IsPositive
                              ? "positiveborder "
                              : "negativeborder "
                          }
                        >
                          <Table.Header>
                            <Table.Row className="pivotHeader">
                              <Table.HeaderCell colSpan="2">
                                Daily Pivot
                              </Table.HeaderCell>
                              <Table.HeaderCell
                                colSpan="2"
                                className={
                                  this.state.dpivotLevel.IsPositive
                                    ? "positivecell"
                                    : "negativecell"
                                }
                              >
                                {Number(
                                  this.state.dpivotLevel.CenterPoint
                                ).toFixed(
                                  this.state.menutype === "CURRENCY" ? 4 : 2
                                )}
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row className="pivotsubheader">
                              <Table.Cell colSpan={2}>Support</Table.Cell>
                              <Table.Cell colSpan={2}>Resist</Table.Cell>
                            </Table.Row>
                            {this.state.dpivotLevel.objpivotlevel.map(
                              (listValue, index) => {
                                return (
                                  <Table.Row key={index}>
                                    <Table.Cell>
                                      {listValue.SupportName}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {Number(listValue.Support).toFixed(
                                        this.state.menutype === "CURRENCY"
                                          ? 4
                                          : 2
                                      )}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {listValue.ResistanceName}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {Number(listValue.Resistance).toFixed(
                                        this.state.menutype === "CURRENCY"
                                          ? 4
                                          : 2
                                      )}
                                    </Table.Cell>
                                  </Table.Row>
                                );
                              }
                            )}
                          </Table.Body>
                        </Table>
                      )}
                      {this.state.dashboardtype === "weekly" && (
                        <Table
                          basic
                          compact="very"
                          className={
                            this.state.wpivotLevel.IsPositive
                              ? "positiveborder "
                              : "negativeborder "
                          }
                        >
                          <Table.Header>
                            <Table.Row className="pivotHeader">
                              <Table.HeaderCell colSpan="2">
                                Weekly Pivot
                              </Table.HeaderCell>
                              <Table.HeaderCell
                                colSpan="2"
                                className={
                                  this.state.wpivotLevel.IsPositive
                                    ? "positivecell"
                                    : "negativecell"
                                }
                              >
                                {Number(
                                  this.state.wpivotLevel.CenterPoint
                                ).toFixed(
                                  this.state.menutype === "CURRENCY" ? 4 : 2
                                )}
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row className="pivotsubheader">
                              <Table.Cell colSpan={2}>Support</Table.Cell>
                              <Table.Cell colSpan={2}>Resist</Table.Cell>
                            </Table.Row>
                            {this.state.wpivotLevel.objpivotlevel.map(
                              (listValue, index) => {
                                return (
                                  <Table.Row key={index}>
                                    <Table.Cell>
                                      {listValue.SupportName}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {Number(listValue.Support).toFixed(
                                        this.state.menutype === "CURRENCY"
                                          ? 4
                                          : 2
                                      )}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {listValue.ResistanceName}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {Number(listValue.Resistance).toFixed(
                                        this.state.menutype === "CURRENCY"
                                          ? 4
                                          : 2
                                      )}
                                    </Table.Cell>
                                  </Table.Row>
                                );
                              }
                            )}
                          </Table.Body>
                        </Table>
                      )}
                      {this.state.dashboardtype === "monthly" && (
                        <Table
                          basic
                          compact="very"
                          className={
                            this.state.mpivotLevel.IsPositive
                              ? "positiveborder "
                              : "negativeborder "
                          }
                        >
                          <Table.Header>
                            <Table.Row className="pivotHeader">
                              <Table.HeaderCell colSpan="2">
                                Monthly Pivot
                              </Table.HeaderCell>
                              <Table.HeaderCell
                                colSpan="2"
                                className={
                                  this.state.mpivotLevel.IsPositive
                                    ? "positivecell"
                                    : "negativecell"
                                }
                              >
                                {Number(
                                  this.state.mpivotLevel.CenterPoint
                                ).toFixed(
                                  this.state.menutype === "CURRENCY" ? 4 : 2
                                )}
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row className="pivotsubheader">
                              <Table.Cell colSpan={2}>Support</Table.Cell>
                              <Table.Cell colSpan={2}>Resist</Table.Cell>
                            </Table.Row>
                            {this.state.mpivotLevel.objpivotlevel.map(
                              (listValue, index) => {
                                return (
                                  <Table.Row key={index}>
                                    <Table.Cell>
                                      {listValue.SupportName}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {Number(listValue.Support).toFixed(
                                        this.state.menutype === "CURRENCY"
                                          ? 4
                                          : 2
                                      )}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {listValue.ResistanceName}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {Number(listValue.Resistance).toFixed(
                                        this.state.menutype === "CURRENCY"
                                          ? 4
                                          : 2
                                      )}
                                    </Table.Cell>
                                  </Table.Row>
                                );
                              }
                            )}
                          </Table.Body>
                        </Table>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Strategy
                        strategy={this.state.strategy}
                        isPositiveStrategy={this.state.isPositiveStrategy}
                        strategyHeader={this.state.strategyHeader}
                        triggerValue={this.state.triggerValue}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Table compact="very" basic className="positiveborder">
                        <Table.Header className="positivetableheader">
                          <Table.Row>
                            <Table.HeaderCell colSpan="3">
                              Trends
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {this.state.trendData.map((listValue, index) => {
                            if (listValue.State.includes("Negative")) {
                              return (
                                <Table.Row className="negative" key={index}>
                                  <Table.Cell collapsing>
                                    {listValue.Trend}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    {listValue.State}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    {Number(listValue.Calculated).toFixed(2)}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            } else {
                              return (
                                <Table.Row className="positive" key={index}>
                                  <Table.Cell collapsing>
                                    {listValue.Trend}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    {listValue.State}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    {Number(listValue.Calculated).toFixed(2)}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            }
                          })}
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Table compact basic className="positiveborder">
                        <Table.Header className="positivetableheader">
                          <Table.Row>
                            <Table.HeaderCell colSpan="3">
                              Additional Important Levels
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {this.state.swingdata.map((listValue, index) => {
                            if (index === 0) {
                              return (
                                <Table.Row className="negative" key={index}>
                                  <Table.Cell collapsing>
                                    {listValue.LevelType}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    {Number(listValue.Level1, 2).toFixed(2)}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    <b>
                                      {" "}
                                      {Number(listValue.Level2, 2).toFixed(2)}
                                    </b>
                                  </Table.Cell>
                                </Table.Row>
                              );
                            } else {
                              return (
                                <Table.Row className="positive" key={index}>
                                  <Table.Cell collapsing>
                                    {listValue.LevelType}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    {Number(listValue.Level1, 2).toFixed(2)}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    <b>
                                      {" "}
                                      {Number(listValue.Level2, 2).toFixed(2)}
                                    </b>
                                  </Table.Cell>
                                </Table.Row>
                              );
                            }
                          })}
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Table compact basic>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell colSpan={2}>
                              Indicators
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body>
                          <Table.Row>
                            <Table.Cell
                              className={
                                this.state.indicators.IsPositiveLowAvg
                                  ? "positive"
                                  : "negative"
                              }
                            >
                              {this.state.indicators.LowAverage}
                            </Table.Cell>

                            <Table.Cell
                              className={
                                this.state.indicators.IsPositiveHighAvg
                                  ? "positive"
                                  : "negative"
                              }
                            >
                              {this.state.indicators.HighAverage}
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                    <Grid.Column>
                      <Table compact basic className="margintop0">
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell colSpan={2}>
                              Likely Trading Range
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell className="positive">
                              {this.state.tradingrange.LowAverage}
                            </Table.Cell>
                            <Table.Cell className="negative">
                              {this.state.tradingrange.HighAverage}
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>

              <Grid.Column width={11}>
                <Table color="orange" basic compact>
                  <Table.Body>
                    {/* {this.state.broadcast.length > 0 && (
                    <Table.Row>
                      <Table.Cell>
                        <Message info onDismiss={this.handleDismiss}>
                          <Message.Header>Important!!!</Message.Header>
                                                    {ReactHtmlParser(this.state.broadcast[0].message)}
                        </Message>
                      </Table.Cell>
                    </Table.Row>
                  )} */}
                    <Table.Row>
                      <Table.Cell>
                        {this.state.chartdetails.length > 0 && (
                          <ChartComponent
                            type="hybrid"
                            chartdata={this.state.chartdetails}
                            width={1000}
                            height={650}
                            showRSI={true}
                            showATR={true}
                            showOptions={true}
                            showStrategy={this.state.chartStrategy}
                          />
                        )}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </BlockUi>
    );
  }
}
const mapStateToProps = state => {
  return {
    view: state
  };
};
const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Stock);
