import React from "react";
import { Redirect } from "react-router-dom";

import axios from "axios";
import {
  Menu,
  Image,
  //Popup,
  Grid,
  Header,
  Modal,
  Icon,
  Button,
  Dropdown
} from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import "./menu.css";

const keys = require("../../config/keys");
class GuestMenu extends React.Component {
  constructor(props) {
    super(props);
    console.log("constructor called");
    this.state = {
      open: false,

      redirect: false,
      broadcast: [],
      messageread: false
    };
  }
  state = {};

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });
  logout = () => {
    window.sessionStorage.setItem("isloggedin", false);
    this.setState({ redirecttologin: true });
  };
  show = () => this.setState({ open: true });
  close = () => this.setState({ open: false });
  broadcastclose = () => this.setState({ broadcastopen: false });

  login = () => {
    this.setState({
      redirect: true
    });
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }
  };
  fetchBroadcast = () => {
    axios
      .get(keys.ServicePath + "/api/GetBroadcastListUsers")
      .then(res => {
        if (res.data.Success) {
          this.setState({
            broadcast: res.data.data
          });
          if (res.data.data.length > 0) {
            this.setState({
              broadcastopen: true,
              messageread: true
            });
          }
        } else {
          // console.log("else");
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  };
  componentDidMount() {
    this.interval = setInterval(() => {
      var hour = new Date().getHours();
      if (hour >= 9 && hour < 16) {
        if (!this.state.messageread) {
          console.log("called Broadcast ");
          this.fetchBroadcast();
        }
      }
    }, 30000);
  }
  render() {
    const { open } = this.state;
    return (
      <div>
        {this.renderRedirect()}

        <Menu
          position="right"
          inverted
          pointing
          color="blue"
          className="menubg"
        >
          <Menu.Item name="Home" className="menulogo">
            <Image src="../asset/images/icon.png" width={30} height={30} />
            &nbsp;&nbsp;{" BAZAAR INDICATORS"}
          </Menu.Item>
          {/* <Menu.Item name="stock View">
          <NavLink to="/Login"> Stock View</NavLink>
        </Menu.Item> */}
          <Menu.Item name="NSEOPTION" onClick={this.show}>
            NSE Option
          </Menu.Item>
          <Modal
            size="mini"
            closeOnEscape={false}
            closeOnDimmerClick={false}
            dimmer="blurring"
            open={open}
            close={!open}
          >
            <Header
              content={
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={14}>
                      <Icon name="lock" />
                      Authorization
                    </Grid.Column>
                    <Grid.Column width={1} textAlign="right">
                      <Icon name="close" onClick={this.close} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              }
            />

            <Modal.Content>
              <Header as="h4">
                You are not authorized to view this Page.
                <br />
                You need to register yourself first
                <br />
                <br />
                {/* {<NavLink to="/Login">Click here </NavLink>}
              &nbsp; Or &nbsp;
              {<Button color="red" onClick={this.close}>
                  Later - Cancel
                </Button>} */}
                {
                  <Button.Group>
                    <Button className="positivecell" onClick={this.login}>
                      Register{" "}
                    </Button>
                    <Button.Or />
                    <Button className="negativecell" onClick={this.close}>
                      Later - Cancel
                    </Button>
                  </Button.Group>
                }
              </Header>
            </Modal.Content>
          </Modal>
          <Dropdown item simple text="Reports">
            <Dropdown.Menu>
              <Dropdown.Item className="ddwhite" onClick={this.show}>
                Trend Report
              </Dropdown.Item>
              <Dropdown.Item onClick={this.show}>
                Volume Increase/Decrease
              </Dropdown.Item>
              <Dropdown.Item onClick={this.show}>Summary Report</Dropdown.Item>
              <Dropdown.Item onClick={this.show}>
                Summary Report By Levels
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* <Popup trigger={<Menu.Item name="upcomingEvents" content="Reports" />} flowing hoverable content={<Menu vertical>
              <Menu.Item name="Trend Report">Trend Report</Menu.Item>
              <Menu.Item name="Volume Increase/Decrease" />
              <Menu.Item name="Summary Report" />
              <Menu.Item name="Summary Report By Levels" />
            </Menu>} position="bottom left" /> */}

          <Menu.Item name="Login" position="right">
            <NavLink to="/Login">Login</NavLink>
          </Menu.Item>
          <Menu.Item name="">Welcome Guest</Menu.Item>
        </Menu>
        {this.state.broadcast.length > 0 && (
          <Modal
            closeIcon
            open={this.state.broadcastopen}
            onClose={
              this.broadcastclose // onOpen={this.open}
            }
            size="small"
            closeOnEscape={true}
            closeOnDimmerClick={true}
          >
            <Header icon="chart line" content="Important" />
            <Modal.Content>
              {ReactHtmlParser(this.state.broadcast[0].message)}
            </Modal.Content>
          </Modal>
        )}
      </div>
    );
  }
}

export default GuestMenu;
