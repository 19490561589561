import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
//import { ModalContainer } from "react-router-modal";

import LoginAuth from "./components/login/LoginAuth";
import MainMenu from "./components/Common/MainMenu";
import StockView from "./components/stockview/view";
// import Beforeunload from 'react-beforeunload';
import "react-router-modal/css/react-router-modal.css";

class App extends Component {
  render() {
    return (
      <header>
        <BrowserRouter>
          <div>
            {/* <MainMenu routepath={routepath} /> */}

            <Route path="/" exact component={StockView} />
            <Route path="/Login" exact component={LoginAuth} />
            <Route path="/View" exact component={MainMenu} />
            <Route path="/stockview" component={MainMenu} />
            <Route path="/Upload" exact component={MainMenu} />
            <Route path="/bse" exact component={MainMenu} />
            <Route path="/nse" exact component={MainMenu} />
            <Route path="/nseoption" exact component={MainMenu} />
            <Route path="/Dashboard" exact component={MainMenu} />
            <Route path="/settings" exact component={MainMenu} />
            <Route path="/usermanagement" exact component={MainMenu} />
            <Route path="/userloginaudit" exact component={MainMenu} />
            <Route path="/broadcast" exact component={MainMenu} />
            <Route path="/notifications" exact component={MainMenu} />
            <Route path="/myfolder" exact component={MainMenu} />
            <Route path="/newui" exact component={MainMenu} />
            <Route path="/trendreport" exact component={MainMenu} />
            <Route path="/volumeincdec" exact component={MainMenu} />
            <Route path="/dailysummary" exact component={MainMenu} />
            <Route path="/dailyindicator" exact component={MainMenu} />
            <Route path="/dailypivot" exact component={MainMenu} />
            <Route path="/paths" exact component={MainMenu} />
            <Route path="/changecode" exact component={MainMenu} />
            <Route path="/theorykey" exact component={MainMenu} />
            {/* <Beforeunload onBeforeunload={() => "You'll lose your data!"}/> */}
            {/* <ModalContainer /> */}
          </div>
        </BrowserRouter>
      </header>
    );
  }
}

export default App;
