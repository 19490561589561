import * as actionTypes from "./actionTypes";
import axios from "axios";
const keys = require("../../config/keys");
//var querystring = require('querystring');

export const uploadBSE = objUpload => async dispatch => {
  objUpload.BSEUpload.showLoader = true;
  dispatch({ type: actionTypes.LOADER, payload: objUpload });
  await axios
    .get(
      keys.ServicePath +
        "/api/UploadBSE?date=" +
        objUpload.BSEUpload.Datetoupload
    )
    .then(res => {
      objUpload.BSEUpload.Success = res.data.Success;
      if (res.data.Success) {
        objUpload.buttonState = "success";
        objUpload.BSEUpload.showLoader = false;
        objUpload.BSEUpload.Message = res.data.Msg;
        objUpload.BSEUpload.Header = res.data.Header;
        objUpload.BSEUpload.scriptlist = res.data.scriptList;
        // dispatch({ type: actionTypes.UPLOADBSE, payload: objUpload });
        //objPersonalDetails.data.showLoader = false;
        //localStorage.setItem('StudentId', res.data.data);
      } else {
        objUpload.BSEUpload.showLoader = false;
        objUpload.BSEUpload.Message = res.data.Msg;
        objUpload.BSEUpload.Header = res.data.Header;
        //dispatch({ type: actionTypes.UPLOADBSE, payload: objUpload });
      }
    })
    .catch(e => {
      objUpload.BSEUpload.showLoader = false;
      // dispatch({ type: actionTypes.UPLOADBSE, payload: objUpload });
      console.log("catch error", JSON.stringify(e));
    });
  dispatch({ type: actionTypes.UPLOADBSE, payload: objUpload });
};

export const uploadNSE = objUpload => async dispatch => {
  objUpload.BSEUpload.showLoader = true;
  dispatch({ type: actionTypes.LOADER, payload: objUpload });
  await axios
    .get(
      keys.ServicePath +
        "/api/UploadNSE?date=" +
        objUpload.BSEUpload.Datetoupload
    )
    .then(res => {
      objUpload.BSEUpload.Success = res.data.Success;
      if (res.data.Success) {
        // dispatch({ type: actionTypes.UPLOADBSE, payload: res.data });
        objUpload.buttonState = "success";
        objUpload.BSEUpload.showLoader = false;
        objUpload.BSEUpload.Message = res.data.Msg;
        objUpload.BSEUpload.Header = res.data.Header;
        objUpload.BSEUpload.scriptlist = res.data.scriptList;
      } else {
        //objPersonalDetails.data.showLoader = false;
        objUpload.BSEUpload.showLoader = false;
        objUpload.BSEUpload.Message = res.data.Msg;
        objUpload.BSEUpload.Header = res.data.Header;
        //  dispatch({ type: actionTypes.UPLOADBSE, payload: res.data });
      }
    })
    .catch(e => {
      console.log("catch error", JSON.stringify(e));
    });
  dispatch({ type: actionTypes.UPLOADBSE, payload: objUpload });
};

export const uploadCurrency = objUpload => async dispatch => {
  objUpload.BSEUpload.showLoader = true;
  dispatch({ type: actionTypes.LOADER, payload: objUpload });
  await axios
    .get(
      keys.ServicePath +
        "/api/UploadCurrency?date=" +
        objUpload.BSEUpload.Datetoupload
    )
    .then(res => {
      objUpload.BSEUpload.Success = res.data.Success;
      if (res.data.Success) {
        // dispatch({ type: actionTypes.UPLOADBSE, payload: res.data });
        objUpload.buttonState = "success";
        objUpload.BSEUpload.showLoader = false;
        objUpload.BSEUpload.Message = res.data.Msg;
        objUpload.BSEUpload.Header = res.data.Header;
        //objUpload.BSEUpload.scriptlist = res.data.scriptList;
      } else {
        //objPersonalDetails.data.showLoader = false;
        objUpload.BSEUpload.showLoader = false;
        objUpload.BSEUpload.Message = res.data.Msg;
        objUpload.BSEUpload.Header = res.data.Header;
        //  dispatch({ type: actionTypes.UPLOADBSE, payload: res.data });
      }
    })
    .catch(e => {
      console.log("catch error", JSON.stringify(e));
    });
  dispatch({ type: actionTypes.UPLOADBSE, payload: objUpload });
};

export const splitbonus = objUpload => async dispatch => {
  //dispatch({ type: actionTypes.VERIFYOTP, payload: objVerify });

  var objUserdet = {
    orgQty: objUpload.orgQty,
    newQty: objUpload.newQty,
    script: objUpload.script,
    sdate: objUpload.sdate,
    scriptype: objUpload.scriptype
  };

  await axios
    .post(keys.ServicePath + "/api/SplitBonus", objUserdet)
    .then(res => {
      var obj = res.data;
      dispatch({ type: actionTypes.UPLOADBSE, obj });
    })
    .catch(e => {
      console.log("catch error", JSON.stringify(e));
    });

  // dispatch({ type: actionTypes.SPLITBONUS, objUserdet });
};

export const UploadNSENew = objUpload => async dispatch => {
  objUpload.BSEUpload.showLoader = true;
  dispatch({ type: actionTypes.LOADER, payload: objUpload });
  await axios
    .get(
      keys.ServicePath +
        "/api/UploadNSENew?date=" +
        objUpload.BSEUpload.Datetoupload
    )
    .then(res => {
      objUpload.BSEUpload.Success = res.data.Success;
      if (res.data.Success) {
        // dispatch({ type: actionTypes.UPLOADBSE, payload: res.data });
        objUpload.buttonState = "success";
        objUpload.BSEUpload.showLoader = false;
        objUpload.BSEUpload.Message = res.data.Msg;
        objUpload.BSEUpload.Header = res.data.Header;
      } else {
        //objPersonalDetails.data.showLoader = false;
        objUpload.BSEUpload.showLoader = false;
        objUpload.BSEUpload.Message = res.data.Msg;
        objUpload.BSEUpload.Header = res.data.Header;
        //  dispatch({ type: actionTypes.UPLOADBSE, payload: res.data });
      }
    })
    .catch(e => {
      console.log("catch error", JSON.stringify(e));
    });
  dispatch({ type: actionTypes.UPLOADBSE, payload: objUpload });
};

export const uploadCurrencyNew = objUpload => async dispatch => {
  objUpload.BSEUpload.showLoader = true;
  dispatch({ type: actionTypes.LOADER, payload: objUpload });
  await axios
    .get(
      keys.ServicePath +
        "/api/UploadCurrencyNew?date=" +
        objUpload.BSEUpload.Datetoupload
    )
    .then(res => {
      objUpload.BSEUpload.Success = res.data.Success;
      if (res.data.Success) {
        // dispatch({ type: actionTypes.UPLOADBSE, payload: res.data });
        objUpload.buttonState = "success";
        objUpload.BSEUpload.showLoader = false;
        objUpload.BSEUpload.Message = res.data.Msg;
        objUpload.BSEUpload.Header = res.data.Header;
      } else {
        //objPersonalDetails.data.showLoader = false;
        objUpload.BSEUpload.showLoader = false;
        objUpload.BSEUpload.Message = res.data.Msg;
        objUpload.BSEUpload.Header = res.data.Header;
        //  dispatch({ type: actionTypes.UPLOADBSE, payload: res.data });
      }
    })
    .catch(e => {
      console.log("catch error", JSON.stringify(e));
    });
  dispatch({ type: actionTypes.UPLOADBSE, payload: objUpload });
};

export const uploadBSESensex = (objUpload, sensexdata) => async dispatch => {
  objUpload.BSEUpload.showLoader = true;
  dispatch({ type: actionTypes.LOADER, payload: objUpload });
  console.log(objUpload, sensexdata);
  var SensexData = {
    objList: sensexdata,
    date: objUpload.BSEUpload.Datetoupload
  };
  console.log("SensexData", SensexData);
  await axios
    .post(keys.ServicePath + "/api/uploadBSESensex", SensexData)
    .then(res => {
      objUpload.BSEUpload.Success = res.data.Success;
      if (res.data.Success) {
        // dispatch({ type: actionTypes.UPLOADBSE, payload: res.data });
        objUpload.buttonState = "success";
        objUpload.BSEUpload.showLoader = false;
        objUpload.BSEUpload.Message = res.data.Msg;
        objUpload.BSEUpload.Header = res.data.Header;
      } else {
        //objPersonalDetails.data.showLoader = false;
        objUpload.BSEUpload.showLoader = false;
        objUpload.BSEUpload.Message = res.data.Msg;
        objUpload.BSEUpload.Header = res.data.Header;
        //  dispatch({ type: actionTypes.UPLOADBSE, payload: res.data });
      }
    })
    .catch(e => {
      console.log("catch error", JSON.stringify(e));
    });
  dispatch({ type: actionTypes.UPLOADBSE, payload: objUpload });
};
