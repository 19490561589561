import axios from "axios";
import * as actionTypes from "./actionTypes";
const keys = require("../../config/keys");

export const saveUser = objUser => async dispatch => {
  // dispatch({ type: actionTypes.VERIFYOTP, payload: objVerify });

  var objUserdet = {
    FirstName: objUser.firstname,
    LastName: objUser.lastname,
    Mobile: objUser.mobile,
    Email: objUser.email,
    State: objUser.selectedState,
    City: objUser.selectedCity,
    IPAddress: objUser.ip
  };
  await axios
    .post(keys.ServicePath + "/api/SaveUserDetails", objUserdet)
    .then(res => {
      if (res.data.Success) {
        window.localStorage.setItem("regno", res.data.uat);
        objUser.showLoader = false;
        objUser.Message = res.data.Message;
        objUser.MessageHeader = "Success";
        objUser.MessageType = "Success";
        objUser.mobileopen = false;
        objUser.otpopen = false;
        objUser.showlogin = true;
        objUser.passwordError = false;
        objUser.firstname = "";
        objUser.lastname = "";
        objUser.mobile = "";
        objUser.email = "";
      }
    })
    .catch(e => {
      console.log("catch error", JSON.stringify(e));
    });

  dispatch({ type: actionTypes.ADDUSER, objUser });
};

export const generateOTP = objUser => async dispatch => {
  //dispatch({ type: actionTypes.VERIFYOTP, payload: objVerify });

  var objUserdet = {
    FirstName: objUser.firstname,
    LastName: objUser.lastname,
    Mobile: objUser.mobile,
    Email: objUser.email,
    State: objUser.selectedState,
    City: objUser.selectedCity,
    IPAddress: objUser.ip
  };
  await axios
    .post(keys.ServicePath + "/api/GenerateOTP", objUserdet)
    .then(res => {
      if (res.data.Success) {
        window.localStorage.setItem("regno", res.data.uat);
        objUser.showLoader = false;
        objUser.Message = res.data.Message;
        objUser.MessageHeader = "Success";
        objUser.MessageType = "Success";
        objUser.mobileopen = false;
        objUser.otpopen = res.data.otp === "" ? false : true;
        objUser.otp = res.data.otp;
        objUser.showlogin = true;
        objUser.passwordError = false;
        objUser.firstname = res.data.data.FirstName;
        objUser.lastname = res.data.data.LastName;
        objUser.mobile = res.data.data.Mobile;
        objUser.email = res.data.data.Email;
      }
    })
    .catch(e => {
      console.log("catch error", JSON.stringify(e));
    });

  dispatch({ type: actionTypes.ADDUSER, objUser });
};

export const saveBroadcast = obj => async dispatch => {
  // dispatch({ type: actionTypes.VERIFYOTP, payload: objVerify });
  var objBrodcastmessage = {
    message: obj.brodcastmessage.message,
    expiresin: obj.brodcastmessage.expiredays
  };
  var result = {};
  await axios
    .post(keys.ServicePath + "/api/SaveBroadcast", objBrodcastmessage)
    .then(res => {
      if (res.data.Success) {
        result = res.data;
      }
    })
    .catch(e => {
      console.log("catch error", JSON.stringify(e));
    });

  dispatch({ type: actionTypes.SAVEBROADCAST, result });
};

export const getBroadcast = obj => async dispatch => {
  // dispatch({ type: actionTypes.VERIFYOTP, payload: objVerify });

  var result = {};
  await axios
    .post(keys.ServicePath + "/api/GetBroadcastList")
    .then(res => {
      if (res.data.Success) {
        result = res.data;
      }
    })
    .catch(e => {
      console.log("catch error", JSON.stringify(e));
    });

  dispatch({ type: actionTypes.SAVEBROADCAST, result });
};

export const savePath = obj => async dispatch => {
  // dispatch({ type: actionTypes.VERIFYOTP, payload: objVerify });
  console.log(obj);
  var result = {};
  await axios
    .post(keys.ServicePath + "/api/UpdatePaths")
    .then(res => {
      if (res.data.Success) {
        result = res.data;
      }
    })
    .catch(e => {
      console.log("catch error", JSON.stringify(e));
    });

  dispatch({ type: actionTypes.SAVEBROADCAST, result });
};
