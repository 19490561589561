import React, { Component } from "react";
import { Table, Grid } from "semantic-ui-react";

class Pivot extends Component {
  constructor(props) {
    super(props);
    this.state = this.props;
  }
  render() {
    return (
      <Grid columns="equal">
        <Grid.Row>
          {this.props.showDaily && (
            <Grid.Column>
              <Table
                basic
                compact="very"
                className={
                  this.props.data.dpivotLevel.IsPositive
                    ? "positiveborder"
                    : "negativeborder"
                }
              >
                <Table.Header>
                  <Table.Row className="pivotHeader">
                    <Table.HeaderCell colSpan="2">Daily Pivot</Table.HeaderCell>
                    <Table.HeaderCell
                      colSpan="2"
                      className={
                        this.props.data.dpivotLevel.IsPositive
                          ? "positivecell"
                          : "negativecell"
                      }
                    >
                      {Number(this.props.data.dpivotLevel.CenterPoint).toFixed(
                        this.props.menutype === "CURRENCY" ? 4 : 2
                      )}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row className="pivotsubheader">
                    <Table.Cell colSpan={2}>Support</Table.Cell>
                    <Table.Cell colSpan={2}>Resist</Table.Cell>
                  </Table.Row>
                  {this.props.data.dpivotLevel.objpivotlevel.map(
                    (listValue, index) => {
                      return (
                        <Table.Row key={index}>
                          <Table.Cell>{listValue.SupportName}</Table.Cell>
                          <Table.Cell>
                            {Number(listValue.Support).toFixed(
                              this.props.menutype === "CURRENCY" ? 4 : 2
                            )}
                          </Table.Cell>
                          <Table.Cell>{listValue.ResistanceName}</Table.Cell>
                          <Table.Cell>
                            {Number(listValue.Resistance).toFixed(
                              this.props.menutype === "CURRENCY" ? 4 : 2
                            )}
                          </Table.Cell>
                        </Table.Row>
                      );
                    }
                  )}
                </Table.Body>
              </Table>
            </Grid.Column>
          )}
        </Grid.Row>
        {!this.props.showDaily && (
          <Grid.Row columns={2}>
            {this.props.showWeekly && (
              <Grid.Column>
                <Table
                  basic
                  compact="very"
                  className={
                    this.props.data.wpivotLevel.IsPositive
                      ? "positiveborder"
                      : "negativeborder"
                  }
                >
                  <Table.Header>
                    <Table.Row className="pivotHeader">
                      <Table.HeaderCell colSpan="2">
                        Weekly Pivot
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        colSpan="2"
                        className={
                          this.props.data.wpivotLevel.IsPositive
                            ? "positivecell"
                            : "negativecell"
                        }
                      >
                        {Number(
                          this.props.data.wpivotLevel.CenterPoint
                        ).toFixed(this.props.menutype === "CURRENCY" ? 4 : 2)}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row className="pivotsubheader">
                      <Table.Cell colSpan={2}>Support</Table.Cell>
                      <Table.Cell colSpan={2}>Resist</Table.Cell>
                    </Table.Row>
                    {this.props.data.wpivotLevel.objpivotlevel &&
                      this.props.data.wpivotLevel.objpivotlevel.map(
                        (listValue, index) => {
                          return (
                            <Table.Row key={index}>
                              <Table.Cell>{listValue.SupportName}</Table.Cell>
                              <Table.Cell>
                                {Number(listValue.Support).toFixed(
                                  this.props.menutype === "CURRENCY" ? 4 : 2
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                {listValue.ResistanceName}
                              </Table.Cell>
                              <Table.Cell>
                                {Number(listValue.Resistance).toFixed(
                                  this.props.menutype === "CURRENCY" ? 4 : 2
                                )}
                              </Table.Cell>
                            </Table.Row>
                          );
                        }
                      )}
                  </Table.Body>
                </Table>
              </Grid.Column>
            )}
            {this.props.showMonthly && (
              <Grid.Column>
                <Table
                  basic
                  compact="very"
                  className={
                    this.props.data.mpivotLevel.IsPositive
                      ? "positiveborder"
                      : "negativeborder"
                  }
                >
                  <Table.Header>
                    <Table.Row className="pivotHeader">
                      <Table.HeaderCell colSpan="2">
                        Monthly Pivot
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        colSpan="2"
                        className={
                          this.props.data.mpivotLevel.IsPositive
                            ? "positivecell"
                            : "negativecell"
                        }
                      >
                        {Number(
                          this.props.data.mpivotLevel.CenterPoint
                        ).toFixed(this.props.menutype === "CURRENCY" ? 4 : 2)}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row className="pivotsubheader">
                      <Table.Cell colSpan={2}>Support</Table.Cell>
                      <Table.Cell colSpan={2}>Resist</Table.Cell>
                    </Table.Row>
                    {this.props.data.mpivotLevel.objpivotlevel &&
                      this.props.data.mpivotLevel.objpivotlevel.map(
                        (listValue, index) => {
                          return (
                            <Table.Row key={index}>
                              <Table.Cell>{listValue.SupportName}</Table.Cell>
                              <Table.Cell>
                                {Number(listValue.Support).toFixed(
                                  this.props.menutype === "CURRENCY" ? 4 : 2
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                {listValue.ResistanceName}
                              </Table.Cell>
                              <Table.Cell>
                                {Number(listValue.Resistance).toFixed(
                                  this.props.menutype === "CURRENCY" ? 4 : 2
                                )}
                              </Table.Cell>
                            </Table.Row>
                          );
                        }
                      )}
                  </Table.Body>
                </Table>
              </Grid.Column>
            )}
          </Grid.Row>
        )}
        {!this.props.showDaily && (
          <Grid.Row columns={2}>
            {this.props.showQuarterly && (
              <Grid.Column>
                <Table
                  basic
                  compact="very"
                  className={
                    this.props.data.qpivotLevel.IsPositive
                      ? "positiveborder"
                      : "negativeborder"
                  }
                >
                  <Table.Header>
                    <Table.Row className="pivotHeader">
                      <Table.HeaderCell colSpan="2">
                        Quarterly Pivot
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        colSpan="2"
                        className={
                          this.props.data.qpivotLevel.IsPositive
                            ? "positivecell"
                            : "negativecell"
                        }
                      >
                        {Number(
                          this.props.data.qpivotLevel.CenterPoint
                        ).toFixed(this.props.menutype === "CURRENCY" ? 4 : 2)}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row className="pivotsubheader">
                      <Table.Cell colSpan={2}>Support</Table.Cell>
                      <Table.Cell colSpan={2}>Resist</Table.Cell>
                    </Table.Row>
                    {this.props.data.qpivotLevel.objpivotlevel &&
                      this.props.data.qpivotLevel.objpivotlevel.map(
                        (listValue, index) => {
                          return (
                            <Table.Row key={index}>
                              <Table.Cell>{listValue.SupportName}</Table.Cell>
                              <Table.Cell>
                                {Number(listValue.Support).toFixed(
                                  this.props.menutype === "CURRENCY" ? 4 : 2
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                {listValue.ResistanceName}
                              </Table.Cell>
                              <Table.Cell>
                                {Number(listValue.Resistance).toFixed(
                                  this.props.menutype === "CURRENCY" ? 4 : 2
                                )}
                              </Table.Cell>
                            </Table.Row>
                          );
                        }
                      )}
                  </Table.Body>
                </Table>
              </Grid.Column>
            )}

            {this.props.showYearly && (
              <Grid.Column>
                <Table
                  basic
                  compact="very"
                  className={
                    this.props.data.ypivotLevel.IsPositive
                      ? "positiveborder"
                      : "negativeborder"
                  }
                >
                  <Table.Header>
                    <Table.Row className="pivotHeader">
                      <Table.HeaderCell colSpan="2">
                        Yearly Pivot
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        colSpan="2"
                        className={
                          this.props.data.ypivotLevel.IsPositive
                            ? "positivecell"
                            : "negativecell"
                        }
                      >
                        {Number(
                          this.props.data.ypivotLevel.CenterPoint
                        ).toFixed(this.props.menutype === "CURRENCY" ? 4 : 2)}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row className="pivotsubheader">
                      <Table.Cell colSpan={2}>Support</Table.Cell>
                      <Table.Cell colSpan={2}>Resist</Table.Cell>
                    </Table.Row>
                    {this.props.data.ypivotLevel.objpivotlevel &&
                      this.props.data.ypivotLevel.objpivotlevel.map(
                        (listValue, index) => {
                          return (
                            <Table.Row key={index}>
                              <Table.Cell>{listValue.SupportName}</Table.Cell>
                              <Table.Cell>
                                {Number(listValue.Support).toFixed(
                                  this.props.menutype === "CURRENCY" ? 4 : 2
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                {listValue.ResistanceName}
                              </Table.Cell>
                              <Table.Cell>
                                {Number(listValue.Resistance).toFixed(
                                  this.props.menutype === "CURRENCY" ? 4 : 2
                                )}
                              </Table.Cell>
                            </Table.Row>
                          );
                        }
                      )}
                  </Table.Body>
                </Table>
              </Grid.Column>
            )}
          </Grid.Row>
        )}
      </Grid>
    );
  }
}

export default Pivot;
