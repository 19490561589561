import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Grid, Table } from "semantic-ui-react";

class OpenIntOutlook extends Component {
  constructor(props) {
    super(props);
    this.state = this.props;
  }

  render() {
    return <Container>
        <Grid divided>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Table compact basic celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan={2}>
                      Open Interest
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Current OpenInt</Table.Cell>
                    <Table.Cell>
                      {this.state.data.CurrentOpenInt}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Previous OpenInt</Table.Cell>
                    <Table.Cell>
                      {this.state.data.PreviousOpenInt}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Increse Decrease % </Table.Cell>
                    <Table.Cell>
                      {this.state.data.OpenIntIncDecPer}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column>
              <Table compact basic celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan={2}>Volume</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Current Volume</Table.Cell>
                    <Table.Cell>{this.state.data.CurrentVolume}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Previous Volume</Table.Cell>
                    <Table.Cell>
                      {this.state.data.PreviousVOlume}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Increse Decrease % </Table.Cell>
                    <Table.Cell>{this.state.data.VolIncDecPer}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Table compact="very" basic className={this.props.isPositiveStrategy ? "positiveborder" : "negativeborder"}>
              <Table.Header className={this.props.isPositiveStrategy ? "positivetableheader" : "negativetableheader"}>
                <Table.Row>
                  <Table.HeaderCell colSpan="3">
                    Trading Strategy - {this.props.strategyHeader}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
              <Table.Row className={this.props.isPositiveStrategy?'positive':'negative'}>
                  <Table.Cell>{this.state.data.Strategy}</Table.Cell>
                </Table.Row>               
              </Table.Body>
            </Table>
          </Grid.Row>
        </Grid>
       
      </Container>;
  }
}
const mapStateToProps = state => {
  return {
    OpenIntOutlook: state
  };
};
const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenIntOutlook);
