import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Header,
  Segment,
  Icon,
  Button,
  Dimmer,
  Loader,
  Message,
  Divider,
  Input,
  Grid
} from "semantic-ui-react";
//import { Form } from 'formsy-semantic-ui-react';
import DatePicker from "react-datepicker";
//import XLSX from "xlsx";
//import ProgressButton from 'react-progress-button'
import * as actionCreators from "../../_store/action/index";
//import { isMoment } from '../../../node_modules/moment';
import moment from "moment";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";
//mport "react-datepicker/dist/react-datepicker.css";
//import CsvParse from "@vtex/react-csv-parse";
const keys = require("../../config/keys");
let newData = [];
class Upload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Datetoupload: moment(),
      showLoader: false,
      Message: "",
      Success: false,
      bsedata: "",
      bsegrossdata: "",
      nse: "",
      nsemto: "",
      currencynse: "",
      currencymcx: "",
      currencynsenew: "",
      FOandOP: "",
      sensexdata: [],
      colDefs: [
        {
          field: "code",
          headerName: "CODE",
          checkboxSelection: true
        },
        { field: "name", headerName: "NAME" },
        { field: "exchange", headerName: "EXCHANGE" }
      ],
      overlayNoRowsTemplate:
        '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">No rows found</span>',
      scriptlist: []
    };
    this.props.state.bse = this.state;
  }
  // handleChange = (e, { name, value }) =>{
  //     console.log(value);
  //     this.setState({ [name]: value })
  // };
  componentDidMount() {
    axios
      .get(keys.ServicePath + "/api/GetPaths")
      .then(res => {
        if (res.data.Success) {
          this.setState({
            bsedata: res.data.paths[0].bse,
            bsegrossdata: res.data.paths[0].bsegrossdata,
            nse: res.data.paths[0].nse,
            nsemto: res.data.paths[0].nsemto,
            currencynse: res.data.paths[0].currencynse,
            currencymcx: res.data.paths[0].currencymcx,
            FOandOP: res.data.paths[0].FOandOP,
            currencynsenew: res.data.paths[0].currencynsenew
          });
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  }

  handleChange = date => {
    this.setState({
      startDate: date,
      Datetoupload: moment(date).format("DD/MM/YYYY")
    });
  };

  getFormData() {
    this.setState({
      buttonState: "loading",
      showLoader: true,
      sensexdata: newData
    });
    this.props.state.bse.showLoader = true;
    return {
      BSEUpload: this.state
    };
  }

  getSensexData = () => {
    return {
      BSEUpload: this.state
    };
  };
  handleFile = file => {
    newData = [];
    if (file.target.files.length > 0) {
      for (var cnt = 0; cnt < file.target.files.length; cnt++) {
        const newFile = file.target.files[cnt];
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        reader.onload = function(e) {
          // Use reader.result
          // console.log("result", reader.result);
          // console.log(JSON.parse(reader.result));
          var a = reader.result;

          var cells = a.split("\n").map(function(el) {
            return el.split(",");
          });

          var headings = [
            "Index",
            "Open",
            "High",
            "Low",
            "CurrentValue",
            "PrevClose",
            "Chpts",
            "Chper",
            "Wk52High",
            "WK52Low",
            "Turnover",
            "PerTotalTurnover"
          ]; // cells.shift();
          // headings = headings.map(s => s.split(" ").join(""));
          //console.log(headings);
          var out = cells.map(function(el) {
            var obj = {};
            for (var i = 0, l = el.length; i < l; i++) {
              obj[headings[i]] = isNaN(Number(el[i])) ? el[i] : +el[i];
            }
            return obj;
          });

          newData = newData.concat(out);
        };
        reader.readAsText(newFile);
      }
    }
  };
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }
  render() {
    let data = this.props.state.bse; // this.state;
    return (
      <Container fluid>
        <Header as="h3" attached="top">
          Upload
        </Header>
        <Segment color="orange" attached padded>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-10">
                      <DatePicker
                        dateFormat="DD/MM/YYYY"
                        selected={this.state.startDate}
                        onChange={this.handleChange}
                        shouldCloseOnSelect={true}
                        customInput={
                          <Input
                            placeholder="Date"
                            icon="calendar alternate"
                            iconPosition="left"
                          />
                        }
                      />
                    </div>
                  </div>
                  <Divider horizontal>
                    <Header as="h4">
                      <Icon name="tag" />
                      BSE
                    </Header>
                  </Divider>
                  <div className="row">
                    <div className="col-md-8">
                      <Input
                        label="BSE Bhavcopy"
                        placeholder=""
                        labelPosition="left"
                        fluid
                        name="bse"
                        disabled
                        value={this.state.bsedata}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <Input
                        label="BSE Data"
                        placeholder=""
                        labelPosition="left"
                        fluid
                        disabled
                        name="bsegrossdata"
                        value={this.state.bsegrossdata}
                      />

                      <Button
                        icon
                        labelPosition="left"
                        primary
                        size="small"
                        onClick={() => this.props.uploadBSE(this.getFormData())}
                      >
                        <Icon name="upload" />
                        Upload BSE
                      </Button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <input
                        type="file"
                        className="form-control-file"
                        id="excelFileInput"
                        aria-describedby="fileHelp"
                        name="excelFileInput"
                        onChange={this.handleFile}
                        multiple
                        accept="xls,xlsx,csv"
                      />
                      <Button
                        icon
                        labelPosition="left"
                        primary
                        size="small"
                        onClick={() =>
                          this.props.uploadBSESensex(
                            this.getFormData(),
                            newData
                          )
                        }
                      >
                        <Icon name="upload" />
                        Upload BSE Sensex
                      </Button>
                    </div>
                  </div>
                  <Divider horizontal>
                    <Header as="h4">
                      <Icon name="tag" />
                      NSE
                    </Header>
                  </Divider>
                  <div className="row">
                    <div className="col-md-8">
                      <Input
                        label="NSE"
                        placeholder=""
                        labelPosition="left"
                        fluid
                        disabled
                        value={this.state.nse}
                        name="nse"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <Input
                        label="NSE MTO"
                        placeholder=""
                        labelPosition="left"
                        fluid
                        disabled
                        value={this.state.nsemto}
                        name="nsemto"
                      />
                      <Button
                        icon
                        labelPosition="left"
                        color="green"
                        size="small"
                        onClick={() => this.props.uploadNSE(this.getFormData())}
                      >
                        <Icon name="upload" />
                        Upload NSE
                      </Button>
                    </div>
                  </div>

                  <Divider horizontal>
                    <Header as="h4">
                      <Icon name="tag" />
                      Currency
                    </Header>
                  </Divider>
                  <div className="row">
                    <div className="col-md-8">
                      <Input
                        label="Currency NSE"
                        placeholder=""
                        labelPosition="left"
                        fluid
                        disabled
                        value={this.state.currencynse}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <Input
                        label="Currency MCX"
                        placeholder=""
                        labelPosition="left"
                        fluid
                        disabled
                        value={this.state.currencymcx}
                      />
                      <Button
                        icon
                        labelPosition="left"
                        color="green"
                        size="small"
                        onClick={() =>
                          this.props.uploadCurrency(this.getFormData())
                        }
                      >
                        <Icon name="upload" />
                        Upload Currency
                      </Button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <Input
                        label="Currency NSE New"
                        placeholder=""
                        labelPosition="left"
                        fluid
                      >
                        <input value={this.state.currencynsenew} disabled />
                        <Button
                          icon
                          labelPosition="left"
                          color="green"
                          size="small"
                          onClick={() =>
                            this.props.uploadCurrencyNew(this.getFormData())
                          }
                        >
                          <Icon name="upload" />
                          Upload Currency New
                        </Button>
                      </Input>
                    </div>
                  </div>
                  <Divider horizontal>
                    <Header as="h4">
                      <Icon name="tag" />
                      FO & OP
                    </Header>
                  </Divider>
                  <div className="row">
                    <div className="col-md-8">
                      <Input
                        label="FO & OP"
                        placeholder=""
                        labelPosition="left"
                        fluid
                        name="FOandOP"
                      >
                        <input value={this.state.FOandOP} disabled />
                        <Button
                          icon
                          labelPosition="left"
                          color="green"
                          size="small"
                          onClick={() =>
                            this.props.uploadNSENew(this.getFormData())
                          }
                        >
                          <Icon name="upload" />
                          Upload Nse New
                        </Button>
                      </Input>
                    </div>
                  </div>
                  <Dimmer active={data.showLoader} inverted>
                    <Loader size="large">Uploading Data</Loader>
                  </Dimmer>

                  {data.Success && (
                    <Message info>
                      <Message.Header>{data.Header}</Message.Header>
                      <p>{data.Message}</p>
                    </Message>
                  )}
                </div>
              </Grid.Column>
              <Grid.Column width={4}>
                <div className="col-md-12">
                  <div className="grid_height_600  ag-theme-balham">
                    <AgGridReact
                      className="height240"
                      rowData={data.scriptlist}
                      enableSorting
                      suppressColumnVirtualisation={true}
                      enableColResize={true}
                      columnDefs={this.state.colDefs}
                      overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                      onGridReady={this.onGridReady.bind(this)}
                    />
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>{" "}
        </Segment>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    state
  };
};
const mapDispatchToProps = dispatch => {
  return {
    uploadBSE: objFormData => dispatch(actionCreators.uploadBSE(objFormData)),
    uploadNSE: objFormData => dispatch(actionCreators.uploadNSE(objFormData)),
    uploadCurrency: objFormData =>
      dispatch(actionCreators.uploadCurrency(objFormData)),
    uploadNSENew: objFormData =>
      dispatch(actionCreators.UploadNSENew(objFormData)),
    uploadCurrencyNew: objFormData =>
      dispatch(actionCreators.uploadCurrencyNew(objFormData)),
    uploadBSESensex: (objFormData, newdata) =>
      dispatch(actionCreators.uploadBSESensex(objFormData, newdata))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Upload);
