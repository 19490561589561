import React, { Component } from "react";
import {
  Input,
  Container,
  Grid,
  Divider,
  Table,
  Button,
  Icon,
  Label,
  Message
} from "semantic-ui-react";
import * as actionCreators from "../../../_store/action/index";
import { connect } from "react-redux";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import "../../nse/nsestyle.css";
const keys = require("../../../config/keys");

class SplitBonus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      high: 0,
      low: 0,
      close: 0,
      symbol: "",
      orgQty: 0,
      newQty: 0,
      Downtrendfibbo: [],
      Uptrendfibbo: [],
      nsedetails: [],
      updatemessage: "",
      colDefs: [
        { field: "dtDate", filter: "agTextColumnFilter", headerName: "Date" },
        {
          field: "open",
          valueGetter: function decimal(params) {
            return Number(params.data.open, 2).toFixed(2);
          }
        },
        {
          field: "high",
          valueGetter: function decimal(params) {
            return Number(params.data.high, 2).toFixed(2);
          }
        },
        {
          field: "low",
          valueGetter: function decimal(params) {
            return Number(params.data.low, 2).toFixed(2);
          }
        },
        {
          field: "close",
          valueGetter: function decimal(params) {
            return Number(params.data.close, 2).toFixed(2);
          }
        },
        { field: "volume", headerName: "Vo.." },
        { field: "del_qty", headerName: "de.." }
      ]
    };
  }
  componentDidMount() {
    this.setState({
      high: this.props.high,
      low: this.props.low,
      close: this.props.close,
      symbol: this.props.symbol,
      sdate: this.props.sdate,
      previousRow: this.props.previousRow
    });

    console.log(this.props.previousRow);
  }

  getFormData() {
    var obj = {
      orgQty: this.state.orgQty,
      newQty: this.state.newQty,
      script: this.props.symbol,
      sdate: this.props.sdate,
      scriptype: this.props.stype
    };

    return {
      login: obj
    };
  }
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  getUpdatedRecords = () => {
    var obj = {
      orgQty: this.state.orgQty,
      newQty: this.state.newQty,
      script: this.props.symbol,
      sdate: this.props.sdate,
      scriptype: this.props.stype
    };
    // var objUserdet = {
    //   orgQty: objUpload.orgQty,
    //   newQty: objUpload.newQty,
    //   script: objUpload.script,
    //   sdate: objUpload.sdate,
    //   scriptype: objUpload.scriptype,
    // };
    axios
      .post(keys.ServicePath + "/api/SplitBonus", obj)
      .then(res => {
        this.setState({ nsedetails: res.data.updatedDetails });
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  };

  UpdatedRecords = () => {
    var obj = {
      orgQty: this.state.orgQty,
      newQty: this.state.newQty,
      script: this.props.symbol,
      sdate: this.props.sdate,
      scriptype: this.props.stype
    };
    // var objUserdet = {
    //   orgQty: objUpload.orgQty,
    //   newQty: objUpload.newQty,
    //   script: objUpload.script,
    //   sdate: objUpload.sdate,
    //   scriptype: objUpload.scriptype,
    // };
    axios
      .post(keys.ServicePath + "/api/UpdateSplitBonus", obj)
      .then(res => {
        this.setState({
          nsedetails: res.data.updatedDetails,
          updatemessage: "Records Updated"
        });
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  };
  CalculateFibboLevel = () => {
    var high = parseFloat(this.state.high);
    var low = parseFloat(this.state.low);
    if (high <= 0 || low <= 0) {
      high = parseFloat(this.props.high);
      low = parseFloat(this.props.low);
    }
    // var levelValue = [0.236, 0.382, 0.5, 0.618, 1, 1.382, 1.618, 2];
    var rlevelValue = [0.236, 0.382, 0.5, 0.618, 0.764, 1, 1.382];
    var elevelValue = [0.236, 0.382, 0.5, 0.618, 1, 1.382, 1.618];
    var rlevelName = [
      "23.60%",
      "38.20%",
      "50.00%",
      "61.80%",
      "76.40%",
      "100%",
      "138.20%"
    ];
    var elevelName = [
      "23.60%",
      "38.20%",
      "50.00%",
      "61.80%",
      "100%",
      "138.20%",
      "161.80%"
    ];
    var objUptrend = [],
      objdowntrend = [];
    //for (var i = levelName.length - 1; i >= 0; i--) {
    for (var i = 0; i < rlevelName.length; i++) {
      //var rval = Number(low + (high - low) * levelValue[i], 2);
      var rval = Number(high - (high - low) * rlevelValue[i], 2);
      var exval = Number(high + (high - low) * elevelValue[i], 2);
      var obj = {
        rlabel: rlevelName[i],
        rvalue: Number(rval, 2),
        elabel: elevelName[i],
        evalue: Number(exval, 2)
      };

      objUptrend.push(obj);
    }

    this.setState({ Uptrendfibbo: objUptrend });

    for (var j = 0; j < rlevelName.length; j++) {
      var obj1 = {
        rlabel: rlevelName[j],
        rvalue: Number(low + (high - low) * rlevelValue[j], 2),
        elabel: elevelName[j],
        evalue: Number(low - (high - low) * elevelValue[j], 2)
      };

      objdowntrend.push(obj1);
    }
    this.setState({ Downtrendfibbo: objdowntrend });
  };
  render() {
    return (
      <Container fluid>
        <Grid divided>
          <Grid.Row>
            <Grid.Column>
              <Table basic="very">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Symbol</Table.HeaderCell>
                    <Table.HeaderCell>High</Table.HeaderCell>
                    <Table.HeaderCell>Low</Table.HeaderCell>
                    <Table.HeaderCell>Close</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>{this.state.sdate}</Table.Cell>
                    <Table.Cell>{this.state.symbol}</Table.Cell>
                    <Table.Cell>
                      {parseFloat(this.state.high).toFixed(2)}
                    </Table.Cell>
                    <Table.Cell>
                      {parseFloat(this.state.low).toFixed(2)}
                    </Table.Cell>
                    <Table.Cell>
                      {parseFloat(this.state.close).toFixed(2)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>{this.props.previousRow.dtDate}</Table.Cell>
                    <Table.Cell>{this.state.symbol}</Table.Cell>
                    <Table.Cell>
                      {parseFloat(this.props.previousRow.high).toFixed(2)}
                    </Table.Cell>
                    <Table.Cell>
                      {parseFloat(this.props.previousRow.low).toFixed(2)}
                    </Table.Cell>
                    <Table.Cell>
                      {parseFloat(this.props.previousRow.close).toFixed(2)}
                    </Table.Cell>
                    .
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        <Grid Column={2}>
          <Grid.Row>
            <Grid.Column width={6}>
              <Input
                placeholder="Original Quantity"
                name="orgQty"
                onChange={this.handleChange}
                className="alignright"
              />{" "}
              {/* onChange={(e, { value }) => this.setState({ orgQty: value })} */}
            </Grid.Column>
            <Grid.Column width={6}>
              <Input
                placeholder="New Quantity"
                name="newQty"
                onChange={this.handleChange}
                className="alignright"
              />{" "}
              {/* onChange={(e, { value }) => this.setState({ newQty: value })} */}
            </Grid.Column>
            <Grid.Column width={2}>
              <Label>
                {" "}
                {parseInt(this.state.orgQty, 0) +
                  parseInt(this.state.newQty, 0)}
              </Label>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button
                icon
                labelPosition="left"
                color="green"
                size="small"
                onClick={
                  this.getUpdatedRecords //onClick={() => this.props.splitbonus(this.getFormData())}
                }
              >
                <Icon name="upload" />
                Process
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div className="grid_height_fix  ag-theme-balham">
                <AgGridReact
                  className="ag-theme-balham"
                  rowData={this.state.nsedetails}
                  enableSorting
                  enableColResize={true}
                  columnDefs={this.state.colDefs}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button
                icon
                labelPosition="left"
                color="green"
                size="small"
                onClick={
                  this.UpdatedRecords //onClick={() => this.props.splitbonus(this.getFormData())}
                }
                disabled={!this.state.nsedetails.length > 0}
              >
                <Icon name="upload" />
                Update Data
              </Button>
            </Grid.Column>
          </Grid.Row>
          {this.state.updatemessage !== "" && (
            <Grid.Row>
              <Grid.Column>
                <Message color="green">
                  <Message.Header>Succes</Message.Header>
                  {this.state.updatemessage}
                </Message>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Container>
    );
  }
}
const mapStateToProps = state => {
  return {
    state
  };
};
const mapDispatchToProps = dispatch => {
  return {
    splitbonus: objFormData =>
      dispatch(actionCreators.splitbonus(objFormData.login))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SplitBonus);
