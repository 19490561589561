import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Header,
  Segment,
  Grid
  // Dimmer,
  // Loader,
} from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";
//import DatePicker from "react-datepicker";
import * as actionCreators from "../../_store/action/index";
import axios from "axios";
import Select from "react-virtualized-select";
const keys = require("../../config/keys");

class ChangeCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      Message: "",
      Success: false,
      oldscrips: [],
      newscrips: [],
      selectedoldscrip: "",
      selectednewscrip: "",
      rowdata: [],
      colDefs: [
        { field: "dtDate", filter: "agTextColumnFilter", headerName: "Date" },
        {
          field: "open",

          valueGetter: function decimal(params) {
            return Number(params.data.open, 2).toFixed(2);
          }
        },
        {
          field: "high",
          valueGetter: function decimal(params) {
            return Number(params.data.high, 2).toFixed(2);
          }
        },
        {
          field: "low",
          valueGetter: function decimal(params) {
            return Number(params.data.low, 2).toFixed(2);
          }
        },
        {
          field: "close",
          valueGetter: function decimal(params) {
            return Number(params.data.close, 2).toFixed(2);
          }
        },
        {
          field: "volume",
          headerName: "Vo.."
        },
        { field: "del_qty", headerName: "de.." }
      ],

      rowSelection: "single"
    };
    this.props.state.bse = this.state;
  }
  componentDidMount = () => {
    axios
      .get(keys.ServicePath + "/api/getallscripts")
      .then(res => {
        if (res.data.Success) {
          console.log(res.data.scrips);
          this.setState({
            oldscrips: res.data.scrips,
            newscrips: res.data.scrips
          });
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
    console.log(this.state.oldscrips);
  };

  getOldValue = oldValue => {
    this.setState({ selectedoldscrip: oldValue });

    axios
      .get(
        keys.ServicePath + "/api/GetnsebseScriptdetails?scriptname=" + oldValue
      )
      .then(res => {
        if (res.data.Success) {
          this.setState({
            olddetails: res.data.details,
            isindex: res.data.isIndex
          });

          this.gridApi
            .getModel()
            .getRow(0)
            .setSelected(true);
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  };
  getnewValue = oldValue => {
    this.setState({ selectednewscrip: oldValue });

    axios
      .get(
        keys.ServicePath + "/api/GetnsebseScriptdetails?scriptname=" + oldValue
      )
      .then(res => {
        if (res.data.Success) {
          this.setState({
            newdetails: res.data.details,
            isindex: res.data.isIndex
          });

          this.gridApi
            .getModel()
            .getRow(0)
            .setSelected(true);
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  };
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
  };

  render() {
    // let data = this.props.state.login;
    //const { editorState } = this.state;
    // const databasescript = this.state.oldscrips;
    console.log(this.state.oldscrips);
    return (
      <Container fluid>
        <Header as="h3" attached="top">
          Change Code
        </Header>

        <Segment color="orange" attached padded className="whitebg">
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column>{"Old Scrip"}</Grid.Column>
              <Grid.Column>{"New Scrip"}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Select
                  options={this.state.oldscrips}
                  simpleValue
                  clearable
                  name="select"
                  value={this.state.selectedoldscrip}
                  onChange={this.getOldValue}
                  searchable
                  labelKey="name"
                  valueKey="code"
                />
                <div className="grid_height_fix  ag-theme-balham">
                  <AgGridReact
                    className="ag-theme-balham"
                    rowData={this.state.olddetails}
                    enableSorting
                    enableColResize={true}
                    columnDefs={this.state.colDefs}
                    onGridReady={this.onGridReady.bind(this)}
                    rowSelection={this.state.rowSelection}
                    rowMultiSelectWithClick={true}
                  />
                </div>
              </Grid.Column>
              <Grid.Column>
                <Select
                  options={this.state.newscrips}
                  simpleValue
                  clearable
                  name="select"
                  value={this.state.selectednewscrip}
                  onChange={this.getnewValue}
                  searchable
                  labelKey="name"
                  valueKey="code"
                />
                <div className="grid_height_fix  ag-theme-balham">
                  <AgGridReact
                    className="ag-theme-balham"
                    rowData={this.state.newdetails}
                    enableSorting
                    enableColResize={true}
                    columnDefs={this.state.colDefs}
                    onGridReady={this.onGridReady.bind(this)}
                    rowSelection={this.state.rowSelection}
                    rowMultiSelectWithClick={true}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    state
  };
};
const mapDispatchToProps = dispatch => {
  return {
    saveBroadcast: objFormData =>
      dispatch(actionCreators.saveBroadcast(objFormData))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeCode);
