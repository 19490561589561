import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';

import registerServiceWorker from './registerServiceWorker';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './_store/reducer/reducer';
import thunk from 'redux-thunk';
import { LicenseManager } from "ag-grid-enterprise";

import './index.css';
import 'semantic-ui-css/semantic.min.css';
// import './css/bootstrap.min.css';
import "./css/custom.css";
import "./css/icons.css";


LicenseManager.setLicenseKey("sdfgsdfg4234452345kj2g5jh23kj4h5b245");

const logger = store => {
    return next => {
        return action => {

            //console.log('[Middleware] dispatching', action);
            const result = next(action);
            //console.log('[Middleware] next state', store.getState());
            return result;
        }
    }
};


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger, thunk)));

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
registerServiceWorker();
