import React from "react";
// import Chart from "../Common/Chart";
import { Grid, Dropdown } from "semantic-ui-react";
import Chart from "../Common/charts/chart";
const keys = require("../../config/keys");

class ChartComponent extends React.Component {
  componentDidMount() {
    this.setState({ chartdata: this.props.chartdata });
  }

  componentDidUpdate() {    
  }

  chartChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  
  render() {
    // const { chartdata: initialData, width, ratio } = this.props;

    if (this.state == null) {
      return <div>Loading...</div>;
    }
    return (
      <Grid>
            {this.props.showOptions && (
        <Grid.Row columns={4}>
          <Grid.Column>
            <Dropdown
              placeholder="Chart Type"
              search
              selection
              options={keys.ChartOptions.chartType}
              onChange={this.chartChange}
              name="chartType"
            />
          </Grid.Column>
          <Grid.Column>
            <Dropdown
              placeholder="Trend Type"
              search
              selection
              options={keys.ChartOptions.Trend}
              name="chartTrendType"
            />
          </Grid.Column>
          <Grid.Column>
            <Dropdown
              placeholder="Strategy"
              search
              selection
              options={keys.ChartOptions.Strategy}
              onChange={this.chartChange}
              name="chartStrategy"
            />
          </Grid.Column>
          <Grid.Column>
            <Dropdown
              placeholder="Oscillators"
              search
              selection
              options={keys.ChartOptions.Oscillators}
              onChange={this.chartChange}
              name="chartOscillators"
            />
          </Grid.Column>
        </Grid.Row>
          )}
        <Grid.Row>
          <Grid.Column>
            <Chart
              type={this.props.type}
              data={this.props.chartdata}
              width={this.props.width}
              height={this.props.height}
              showRSI={this.props.showRSI}
              showATR={this.props.showATR}
              showStrategy={this.state.chartStrategy}
              chartType={this.state.chartType}
            />
          </Grid.Column>
        </Grid.Row>{" "}
      </Grid>
    );
  }
}

export default ChartComponent;
