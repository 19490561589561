
const chartOptions = {
  chartType: [
    { key: "Candlestick", value: "Candlestick", text: "Candlestick" },
    { key: "OHLC", value: "OHLC", text: "OHLC" },
    { key: "ClosingPrice", value: "ClosingPrice", text: "Closing Price" }
  ],
  Trend: [
    { key: "N", value: "N", text: "None" },
    { key: "ST", value: "ST", text: "Short Term" },
    { key: "MT", value: "MT", text: "Medium Term" },
    { key: "LT", value: "LT", text: "Long Term" },
    { key: "SPT", value: "SPT", text: "Super Trend" }
  ],
  Strategy: [
    { key: "N", value: "N", text: "None" },
    { key: "Bollinger", value: "Bollinger", text: "Bollinger Band" },
    { key: "Volumebar", value: "Volumebar", text: "Volume bar" },
    { key: "SAR", value: "SAR", text: "Parabolic SAR" }
  ],
  Oscillators:[
     { key: "N", value: "N", text: "None" },
    { key: "RSI", value: "RSI", text: "RSI" },
    { key: "MACD", value: "MACD", text: "MACD" },
    { key: "ATR", value: "ATR", text: "ATR " }
  ]
};

const enums = {
  frequency: [
    { key: "Daily", value: "daily", text: "Daily" },
    { key: "Weekly", value: "weekly", text: "Weekly" },
    { key: "Monthly", value: "monthly", text: "Monthly" }
  ]
};


module.exports = {
  ServicePath: 'https://api.bazaarindicators.com/',
 // ServicePath: "https://api.bazaarindicators.in/",
  TEST_SITE_KEY: "6Lc9oGwUAAAAACVe90U_3IY5RfHKecRpWSQ_Xbe8",
  DELAY: 1500,
  ChartOptions: chartOptions,
  enums: enums
};
