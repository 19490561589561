import React, { Component } from "react";
import {
  Container,
  Grid,
  Button,
  Modal,
  Header,
  Table,
  Dropdown,
  Responsive,
  Segment,
  Image,
  Icon
} from "semantic-ui-react";

import axios from "axios";
import ChartComponent from "../Common/ChartComponent";
import FibboLevel from "../Common/FibboLevel";
import BILevel from "../Common/biLevel";
import OpenIntOutlook from "../nseoption/openIntOutlook";
import DeliveryQty from "../nseoption/deliveryQty";
import Pivot from "../Common/pivot/pivot";
import { connect } from "react-redux";
import Strategy from "../Common/Strategy/Strategy";
import GuestMenu from "../Common/GuestMenu";
import AverageDays from "../Common/AvgDays/avgDays";
import HighLowStats from "../Common/AllTimeHigh/highlowstats";
import BlockUi from "react-block-ui";

import "react-block-ui/style.css";
// const ReactHighcharts = require("react-highcharts");

// import HighChart from "../Common/charts/highChart";

//import { PieChart } from "react-easy-chart";

const keys = require("../../config/keys");
// const domContainer = document.querySelector('#chart-container');
// var chart = new gocharting.GoChartsEmbed();
class StockView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isindex: false,
      nsedetails: [],
      selectedscript: "Nifty50|NSE|Index",
      rowSelection: "single",
      highlowddata: [],
      trendData: [],
      indicators: {},
      tradingrange: {},
      dashboardtype: "daily",
      swingdata: [],
      strategy: [],
      chartdetails: [],
      pivotCenterPoint: [],
      pivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      dpivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      wpivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      mpivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      qpivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      ypivotLevel: { Frequency: "", CenterPoint: 0, objpivotlevel: [] },
      high: 0.0,
      low: 0.0,
      close: 0.0,
      Downtrendfibbo: [],
      Uptrendfibbo: [],
      biLevels: {},
      IsFutur: false,
      width: 1024,
      avgVals: { open: 0, high: 0, low: 0, close: 0, volume: 0 },
      scriptDetails: { dtDate: "" },
      alltimehighlow: [],
      dimmeractive: false
    }; // scriptname: [],
  }

  fetchData = () => {
    // var url = " https://www.google.co.uk/search?q=NSE:nifty50&source=lnms&sa=X&ved=0ahUKEwjXrIDA8aveAhUVfisKHbqvCZAQ_AUIDSgA&biw=1360&bih=632";
    // url ="https://www.nseindia.com/live_market/dynaContent/live_watch/stock_watch/niftyStockWatch.json"
    // //url ="https://query1.finance.yahoo.com/v8/finance/chart/Nifty50.NS?range=1d&includePrePost=false&interval=1d&corsDomain=in.finance.yahoo.com&.tsrc=finance"
    // fetch(url,).then(response => {
    //   console.log(response.json());
    //   response.json();
    // }).then(data => {
    //   console.log("data",data);
    //   this.setState({ livedata: data })
    // });

    axios
      .get(
        keys.ServicePath +
          "/api/GetLiveStock?scrip=" +
          this.state.selectedscript.split("|")[0]
      )
      .then(res => {
        if (res.data.Success) {
          this.setState({
            livedata: res.data.LiveData,
            latestdata: res.data.NSEUpdated
          });
        } else {
          // console.log("else");
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });

    // $.ajax({
    //   url: url,
    //   dataType: "jsonp",
    //   success: function (res, err) {
    //     console.log('Response ' + res);
    //   },//this.parseResponse,
    //   error: function (req, err) {
    //     console.log('API call failed ' + err);
    //   }
    // })
  };

  fetchBroadcast = () => {
    axios
      .get(keys.ServicePath + "/api/GetBroadcastListUsers")
      .then(res => {
        if (res.data.Success) {
          this.setState({
            broadcast: res.data.data
          });
          if (res.data.data.length > 0) {
            this.setState({
              open: true
            });
          }
        } else {
          // console.log("else");
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });
  };
  componentDidMount() {
    this.setState({ dimmeractive: true });
    axios
      .get(keys.ServicePath + "/api/GuestScript")
      .then(res => {
        // console.log(res.data.ScriptList);

        if (res.data.Success) {
          this.setState(
            {
              scriptname: res.data.ScriptList,
              scriptDetails: res.data.ScriptDetails
            },
            () => {
              this.GetScripTrenDetails();
            }
          );
        }
      })
      .catch(e => {
        console.log("catch error", JSON.stringify(e));
      });

    this.interval = setInterval(() => {
      var hour = new Date().getHours();
      if (hour >= 9 && hour < 16) {
        this.fetchData();
      }
    }, 5000);
    this.fetchData();
    //this.fetchBroadcast();
  }
  frequencyChange = (e, { name, value }) => {
    this.setState({ dashboardtype: value, dimmeractive: true }, () => {
      this.GetScripTrenDetails();
    });
  };
  GetScripTrenDetails() {
    var objProg = [];
    axios
      .get(
        keys.ServicePath +
          "/api/HighLowTrend?date=" +
          this.state.scriptDetails.dtDate +
          "&script=" +
          this.state.selectedscript.split("|")[0] +
          "&dbType=" +
          this.state.selectedscript.split("|")[1].toLowerCase() +
          "&close=" +
          this.state.scriptDetails.close +
          "&type=" +
          this.state.dashboardtype
      )
      .then(res => {
        if (res.data.Success) {
          this.setState({
            highlowddata: res.data.highlowddata,
            trendData: res.data.trend,
            indicators: res.data.indicator,
            tradingrange: res.data.tradingrange,
            swingdata: res.data.swing,
            strategy: res.data.strategy,
            isPositiveStrategy: res.data.isPositiveStrategy,
            strategyHeader: res.data.strategyHeader,
            triggerValue: res.data.triggerValue,
            pivotCenterPoint: res.data.pivotCenterPoint,
            dpivotLevel: res.data.dailypivotLevel,
            wpivotLevel: res.data.weeklypivotLevel,
            mpivotLevel: res.data.monthlypivotLevel,
            qpivotLevel: res.data.quarterlypivotLevel,
            ypivotLevel: res.data.yearlypivotLevel,
            biLevels: res.data.biLevels,
            openIntOutlook: res.data.openIntOutlook,
            deliveryQty: res.data.deliveryQty,
            cycleStatus: res.data.cycleStatus,
            xdaysAvg: res.data.lastxdays,
            selectedavg: res.data.lastxdays[0].value,
            scriptDetails: res.data.ScriptDetails,
            alltimehighlow: res.data.alltimehighlow
          });
          for (var i = 0; i < res.data.chartdata.length; i++) {
            var obj = {
              date: new Date(Date.parse(res.data.chartdata[i].dtDate)),
              open: res.data.chartdata[i].open,
              high: res.data.chartdata[i].high,
              low: res.data.chartdata[i].low,
              close: res.data.chartdata[i].close,
              volume: res.data.chartdata[i].volume
            };

            objProg.push(obj);
          }
          var vals = res.data.lastxdays[0].value.split("|");
          var obj1 = {
            open: vals[0],
            high: vals[1],
            low: vals[2],
            close: vals[3],
            volume: vals[4]
          };
          this.setState({
            avgVals: obj1,
            selectedavg: res.data.lastxdays[0].value
          });
          this.setState({ chartdetails: objProg, dimmeractive: false });
        } else {
          this.setState({ dimmeractive: false });
          console.log("else");
        }
      })
      .catch(e => {
        this.setState({ dimmeractive: false });
        console.log("catch error", JSON.stringify(e));
      });
  }

  scripchnage = (e, { value, text }) => {
    this.setState({ selectedscript: value, dimmeractive: true }, () => {
      axios
        .get(keys.ServicePath + "/api/GuestScriptChange?scripValue=" + value)
        .then(res => {
          if (res.data.Success) {
            this.setState(
              {
                scriptDetails: res.data.ScriptDetails
              },
              () => {
                this.GetScripTrenDetails();
              }
            );
          }
        })
        .catch(e => {
          this.setState({ dimmeractive: false });
          console.log("catch error", JSON.stringify(e));
        });
    });
  };

  render() {
    return (
      <BlockUi tag="div" blocking={this.state.dimmeractive}>
        <Responsive
          as={Container}
          fluid
          minWidth={Responsive.onlyTablet.minWidth}
        >
          <GuestMenu />

          <Grid className="padding5">
            <Grid.Row>
              <Grid.Column width={5}>
                <Dropdown
                  placeholder="Select Script"
                  fluid
                  selection
                  options={this.state.scriptname}
                  onChange={this.scripchnage}
                  value={this.state.selectedscript}
                />
              </Grid.Column>
              <Grid.Column width={2}>
                <Dropdown
                  placeholder="Chart Type"
                  fluid
                  search
                  selection
                  options={keys.enums.frequency}
                  onChange={this.frequencyChange}
                  value={this.state.dashboardtype}
                  name="dashboardtype"
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <Modal
                  trigger={
                    <Button color="blue" size="small">
                      BI Levels
                    </Button>
                  }
                  closeIcon
                  size="mini"
                >
                  <Header icon="chart line" content="BI Levels" />
                  <Modal.Content>
                    <BILevel data={this.state.biLevels} />
                  </Modal.Content>
                </Modal>
                <Modal
                  trigger={
                    <Button color="blue" size="small">
                      Pivot
                    </Button>
                  }
                  closeIcon
                  size="small"
                >
                  <Header icon="chart line" content="Pivot" />
                  <Modal.Content>
                    <Pivot
                      data={this.state}
                      showDaily={true}
                      showWeekly={true}
                      showMonthly={true}
                      showQuarterly={true}
                      showYearly={true}
                    />
                  </Modal.Content>
                </Modal>
                <Modal
                  trigger={
                    <Button color="blue" size="small">
                      Fibbo Level
                    </Button>
                  }
                  size="small"
                  closeIcon
                >
                  <Header icon="chart line" content="Fibbo Levels" />
                  <Modal.Content>
                    <FibboLevel
                      high={this.state.scriptDetails.high}
                      low={this.state.scriptDetails.low}
                      close={this.state.scriptDetails.close}
                      symbol={this.state.selectedscript}
                    />
                  </Modal.Content>
                </Modal>

                <Modal
                  trigger={
                    <Button
                      color="blue"
                      size="small"
                      disabled={
                        !this.state.selectedscript
                          .toLowerCase()
                          .includes("mnth")
                      }
                    >
                      OpenInt
                    </Button>
                  }
                  closeIcon
                  size="small"
                >
                  <Header icon="chart line" content="Open Interest Outlook" />
                  <Modal.Content>
                    <OpenIntOutlook
                      data={this.state.openIntOutlook}
                      strategyHeader={this.state.strategyHeader}
                      isPositiveStrategy={this.state.isPositiveStrategy}
                    />
                  </Modal.Content>
                </Modal>

                <Modal
                  trigger={
                    <Button
                      color="blue"
                      size="small"
                      disabled={this.state.deliveryQty == null ? true : false}
                    >
                      DelQty
                    </Button>
                  }
                  closeIcon
                  size="small"
                >
                  <Header icon="chart line" content="Delivery Quantity" />
                  <Modal.Content>
                    <DeliveryQty data={this.state.deliveryQty} />
                  </Modal.Content>
                </Modal>

                {/* <Dropdown
              button
              className='icon'
              floating
              labeled
              icon='world'
              options={languageOptions}

              text='Select Language'
            /> */}

                <Button.Group color="blue">
                  <Dropdown button text="Others" floating>
                    <Dropdown.Menu>
                      <Modal
                        trigger={<Dropdown.Item text="Average Days" />}
                        closeIcon
                        size="small"
                      >
                        <Header icon="chart line" content="Average Days" />
                        <Modal.Content>
                          <AverageDays data={this.state.xdaysAvg} />
                        </Modal.Content>
                      </Modal>
                      {/* <Modal trigger={<Dropdown.Item text="Cycle Status" />} closeIcon size="small">
                      <Header icon="chart line" content="Cycle Status" />
                      <Modal.Content>
                       
                      </Modal.Content>
                    </Modal> */}
                      <Modal
                        trigger={<Dropdown.Item text="High Low Stats" />}
                        closeIcon
                        size="mini"
                        closeOnEscape={true}
                        closeOnDimmerClick={true}
                      >
                        <Header icon="chart line" content="High Low Stats" />
                        <Modal.Content>
                          <HighLowStats data={this.state.alltimehighlow} />
                        </Modal.Content>
                      </Modal>
                    </Dropdown.Menu>
                  </Dropdown>
                </Button.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={5}>
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Table color="orange" basic compact>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell colSpan={2}>
                              {this.state.scriptDetails.dtDate}
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>Open</Table.Cell>
                            <Table.Cell>
                              {" "}
                              {Number(this.state.scriptDetails.open).toFixed(2)}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>High</Table.Cell>
                            <Table.Cell>
                              {" "}
                              {Number(this.state.scriptDetails.high).toFixed(2)}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Low</Table.Cell>
                            <Table.Cell>
                              {Number(this.state.scriptDetails.low).toFixed(2)}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Close</Table.Cell>
                            <Table.Cell>
                              {Number(this.state.scriptDetails.close).toFixed(
                                2
                              )}
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell>Volume</Table.Cell>
                            <Table.Cell>
                              {!this.state.selectedscript
                                .toLowerCase()
                                .includes("index")
                                ? Number(
                                    this.state.scriptDetails.volume
                                  ).toFixed(0)
                                : "-"}
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell>Delivery Qty</Table.Cell>
                            <Table.Cell>
                              {!this.state.selectedscript
                                .toLowerCase()
                                .includes("index")
                                ? Number(
                                    this.state.scriptDetails.del_qty
                                  ).toFixed(0)
                                : "-"}
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                    <Grid.Column>
                      <Table color="orange" basic compact className="nomargin">
                        <Table.Header>
                          <Table.Row>
                            {this.state.livedata && (
                              <Table.HeaderCell colSpan={2}>
                                {/* {this.state.scriptDetails.dtDate} */}

                                <span>{this.state.livedata.ltP}</span>
                                <span className="inr">{" INR"}</span>

                                <span
                                  className={
                                    this.state.livedata.iislPtsChange < 0
                                      ? "datachangenegative"
                                      : "datachangepositive"
                                  }
                                >
                                  {" " + this.state.livedata.iislPtsChange}
                                </span>
                                <span
                                  className={
                                    this.state.livedata.iislPercChange < 0
                                      ? "datachangenegative"
                                      : "datachangepositive"
                                  }
                                >
                                  {" (" +
                                    this.state.livedata.iislPercChange +
                                    "%) "}
                                </span>

                                {this.state.livedata.iislPercChange < 0 && (
                                  <Icon
                                    className="datachangenegative"
                                    name="arrow down"
                                  />
                                )}
                                {this.state.livedata.iislPercChange > 0 && (
                                  <Icon
                                    className="datachangepositive"
                                    name="arrow up"
                                  />
                                )}

                                {/* { <svg version="1.1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" xmlns: xlink="http://www.w3.org/1999/xlink"><path d="M6,0.002L0 6.002 4.8 6.002 4.8 11.9996 7.2 11.9996 7.2 6.002 12 6.002z"></path>
                            </svg>
                           } */}
                                {/* {this.state.scriptDetails.dtDate} */}
                              </Table.HeaderCell>
                            )}
                          </Table.Row>
                        </Table.Header>
                      </Table>
                      {this.state.dashboardtype === "daily" && (
                        <Table
                          basic
                          compact="very"
                          className={
                            this.state.dpivotLevel.IsPositive
                              ? "nomargin positiveborder "
                              : "nomargin negativeborder "
                          }
                        >
                          <Table.Header>
                            <Table.Row className="pivotHeader">
                              <Table.HeaderCell colSpan="2">
                                Daily Pivot
                              </Table.HeaderCell>
                              <Table.HeaderCell
                                colSpan="2"
                                className={
                                  this.state.dpivotLevel.IsPositive
                                    ? "positivecell"
                                    : "negativecell"
                                }
                              >
                                {Number(
                                  this.state.dpivotLevel.CenterPoint
                                ).toFixed(2)}
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row className="pivotsubheader">
                              <Table.Cell colSpan={2}>Support</Table.Cell>
                              <Table.Cell colSpan={2}>Resist</Table.Cell>
                            </Table.Row>
                            {this.state.dpivotLevel.objpivotlevel.map(
                              (listValue, index) => {
                                return (
                                  <Table.Row key={index}>
                                    <Table.Cell>
                                      {listValue.SupportName}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {Number(listValue.Support).toFixed(2)}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {listValue.ResistanceName}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {Number(listValue.Resistance).toFixed(2)}
                                    </Table.Cell>
                                  </Table.Row>
                                );
                              }
                            )}
                          </Table.Body>
                        </Table>
                      )}
                      {this.state.dashboardtype === "weekly" && (
                        <Table
                          basic
                          compact="very"
                          className={
                            this.state.wpivotLevel.IsPositive
                              ? "nomargin positiveborder "
                              : "nomargin negativeborder "
                          }
                        >
                          <Table.Header>
                            <Table.Row className="pivotHeader">
                              <Table.HeaderCell colSpan="2">
                                Weekly Pivot
                              </Table.HeaderCell>
                              <Table.HeaderCell
                                colSpan="2"
                                className={
                                  this.state.wpivotLevel.IsPositive
                                    ? "positivecell"
                                    : "negativecell"
                                }
                              >
                                {Number(
                                  this.state.wpivotLevel.CenterPoint
                                ).toFixed(2)}
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row className="pivotsubheader">
                              <Table.Cell colSpan={2}>Support</Table.Cell>
                              <Table.Cell colSpan={2}>Resist</Table.Cell>
                            </Table.Row>
                            {this.state.wpivotLevel.objpivotlevel.map(
                              (listValue, index) => {
                                return (
                                  <Table.Row key={index}>
                                    <Table.Cell>
                                      {listValue.SupportName}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {Number(listValue.Support).toFixed(2)}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {listValue.ResistanceName}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {Number(listValue.Resistance).toFixed(2)}
                                    </Table.Cell>
                                  </Table.Row>
                                );
                              }
                            )}
                          </Table.Body>
                        </Table>
                      )}
                      {this.state.dashboardtype === "monthly" && (
                        <Table
                          basic
                          compact="very"
                          className={
                            this.state.mpivotLevel.IsPositive
                              ? "nomargin positiveborder "
                              : "nomargin negativeborder "
                          }
                        >
                          <Table.Header>
                            <Table.Row className="pivotHeader">
                              <Table.HeaderCell colSpan="2">
                                Monthly Pivot
                              </Table.HeaderCell>
                              <Table.HeaderCell
                                colSpan="2"
                                className={
                                  this.state.mpivotLevel.IsPositive
                                    ? "positivecell"
                                    : "negativecell"
                                }
                              >
                                {Number(
                                  this.state.mpivotLevel.CenterPoint
                                ).toFixed(2)}
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row className="pivotsubheader">
                              <Table.Cell colSpan={2}>Support</Table.Cell>
                              <Table.Cell colSpan={2}>Resist</Table.Cell>
                            </Table.Row>
                            {this.state.mpivotLevel.objpivotlevel.map(
                              (listValue, index) => {
                                return (
                                  <Table.Row key={index}>
                                    <Table.Cell>
                                      {listValue.SupportName}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {Number(listValue.Support).toFixed(2)}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {listValue.ResistanceName}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {Number(listValue.Resistance).toFixed(2)}
                                    </Table.Cell>
                                  </Table.Row>
                                );
                              }
                            )}
                          </Table.Body>
                        </Table>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Strategy
                        strategy={this.state.strategy}
                        isPositiveStrategy={this.state.isPositiveStrategy}
                        strategyHeader={this.state.strategyHeader}
                        triggerValue={this.state.triggerValue}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Table compact="very" basic className="positiveborder">
                        <Table.Header className="positivetableheader">
                          <Table.Row>
                            <Table.HeaderCell colSpan="3">
                              Trends
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {this.state.trendData.map((listValue, index) => {
                            if (listValue.State.includes("Negative")) {
                              return (
                                <Table.Row className="negative" key={index}>
                                  <Table.Cell collapsing>
                                    {listValue.Trend}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    {listValue.State}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    {Number(listValue.Calculated).toFixed(2)}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            } else {
                              return (
                                <Table.Row className="positive" key={index}>
                                  <Table.Cell collapsing>
                                    {listValue.Trend}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    {listValue.State}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    {Number(listValue.Calculated).toFixed(2)}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            }
                          })}
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Table compact basic className="positiveborder">
                        <Table.Header className="positivetableheader">
                          <Table.Row>
                            <Table.HeaderCell colSpan="3">
                              Additional Important Levels
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {this.state.swingdata.map((listValue, index) => {
                            if (index === 0) {
                              return (
                                <Table.Row className="negative" key={index}>
                                  <Table.Cell collapsing>
                                    {listValue.LevelType}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    {Number(listValue.Level1, 2).toFixed(2)}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    <b>
                                      {" "}
                                      {Number(listValue.Level2, 2).toFixed(2)}
                                    </b>
                                  </Table.Cell>
                                </Table.Row>
                              );
                            } else {
                              return (
                                <Table.Row className="positive" key={index}>
                                  <Table.Cell collapsing>
                                    {listValue.LevelType}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    {Number(listValue.Level1, 2).toFixed(2)}
                                  </Table.Cell>
                                  <Table.Cell collapsing>
                                    <b>
                                      {" "}
                                      {Number(listValue.Level2, 2).toFixed(2)}
                                    </b>
                                  </Table.Cell>
                                </Table.Row>
                              );
                            }
                          })}
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Table compact basic>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell colSpan={2}>
                              Indicators
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body>
                          <Table.Row>
                            <Table.Cell
                              className={
                                this.state.indicators.IsPositiveLowAvg
                                  ? "positive"
                                  : "negative"
                              }
                            >
                              {this.state.indicators.LowAverage}
                            </Table.Cell>

                            <Table.Cell
                              className={
                                this.state.indicators.IsPositiveHighAvg
                                  ? "positive"
                                  : "negative"
                              }
                            >
                              {this.state.indicators.HighAverage}
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                    <Grid.Column>
                      <Table compact basic className="margintop0">
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell colSpan={2}>
                              Likely Trading Range
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell className="positive">
                              {this.state.tradingrange.LowAverage}
                            </Table.Cell>
                            <Table.Cell className="negative">
                              {this.state.tradingrange.HighAverage}
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>

              <Grid.Column width={11}>
                <Table color="orange" basic compact>
                  <Table.Body>
                    <Table.Cell>
                      {this.state.chartdetails.length > 0 && (
                        <ChartComponent
                          type="hybrid"
                          chartdata={this.state.chartdetails}
                          width={1000}
                          height={650}
                          showRSI={true}
                          showATR={true}
                          showOptions={false}
                        />
                      )}
                      {/* <HighChart /> */}

                      {/* <div class="wrapper">
                        <div id="chart-container"></div>



                        {chart.mount('#chart-container')}
                      </div> */}
                    </Table.Cell>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Responsive>
        <Responsive
          as={Segment}
          content="Coming Soon For Mobile version"
          icon="mobile"
          labelPosition="left"
          {...Responsive.onlyMobile}
        >
          <Image src="../asset/images/BI.jpg" size="small" rounded />
          <br />
          <Segment inverted color="blue">
            Coming Soon For Mobile version
          </Segment>
        </Responsive>
      </BlockUi>
    );
  }
}
const mapStateToProps = state => {
  return {
    view: state
  };
};
const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockView);
