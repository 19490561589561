import React, { Component } from "react";
import { Table, Grid } from "semantic-ui-react";

class DailyPivot extends Component {
  constructor(props) {
    super(props);
    this.state = { rowData: null, objpivotlevel:[]  }
  }

  componentWillReceiveProps(nextProps) {
    
    if(nextProps.data!=null){
      this.setState({ rowData: nextProps.data }, () => {
        this.calculatePivot(nextProps.data);    
      })

    }
  }

  calculatePivot(data){
    
    //var open = data.open;
    var high = parseFloat(data.HIGH);
    var low = parseFloat(data.LOW);
    var close = parseFloat(data.CLOSE);

    var objpivotlevel = [];
    
    
    var centerpoint =parseFloat((high + low + close) / 3);
    
    //var IsPositive = (centerpoint <= close ? true : false);
    var r1 =  (2 * centerpoint) - low;
    var s1 =  (2 * centerpoint) - high;
    var obj = { ResistanceName: 'R1', Resistance:r1,SupportName:'S1',Support:s1};
    objpivotlevel.push(obj);

    var r2 = centerpoint + (r1 - s1);
    var s2 = centerpoint - (r1 - s1);
    obj = { ResistanceName: 'R2', Resistance: r2, SupportName: 'S2', Support: s2 };
    objpivotlevel.push(obj);

    var r3 = (2 * centerpoint) + (high - 2 * low);
    var s3 = (2 * centerpoint) - (2 * high - low);
    obj = { ResistanceName: 'R3', Resistance: r3, SupportName: 'S3', Support: s3 };
    objpivotlevel.push(obj);

    var r4 = centerpoint - s1 + r3;
    var s4 = centerpoint - r3 + s1;
    obj = { ResistanceName: 'R4', Resistance: r4, SupportName: 'S4', Support: s4 };
    objpivotlevel.push(obj);
    this.setState({ rowData: data, centerpoint: centerpoint, objpivotlevel: objpivotlevel})
    
    // console.log(this.state.rowData.close , this.state.centerpoint)
  }
  render() {
    if(this.state.rowData===null) return null;
    return <Grid columns="equal" className="marginbottom0">
      <Grid.Row>
          <Grid.Column>
            <Table basic compact="very" className={this.state.rowData.CLOSE > this.state.centerpoint ? "positiveborder" : "negativeborder"}>
              <Table.Header>
                <Table.Row className="pivotHeader">
                  <Table.HeaderCell colSpan="4">
                    {this.state.rowData.CATEGORY.trim() === "CE"
                      ? "PIVOT (CALL OPTION)"
                    : "PIVOT (PUT OPTION)"}
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row className="pivotHeader">
                  <Table.HeaderCell colSpan="2">
                    {this.state.rowData.STRPRICE}
                  </Table.HeaderCell>
                <Table.HeaderCell colSpan="2" className={this.state.rowData.CLOSE > this.state.centerpoint ? "positivecell" : "negativecell"}>
                    {Number(this.state.centerpoint).toFixed(2)}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row className="pivotsubheader">
                  <Table.Cell colSpan={2}>Support</Table.Cell>
                  <Table.Cell colSpan={2}>Resist</Table.Cell>
                </Table.Row>
                {this.state.objpivotlevel.map((listValue, index) => {
                  return <Table.Row key={index}>
                      <Table.Cell>{listValue.SupportName}</Table.Cell>
                      <Table.Cell>
                        {Number(listValue.Support).toFixed(2)}
                      </Table.Cell>
                      <Table.Cell>{listValue.ResistanceName}</Table.Cell>
                      <Table.Cell>
                        {Number(listValue.Resistance).toFixed(2)}
                      </Table.Cell>
                    </Table.Row>;
                })}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>;
  }
}

export default DailyPivot;
