import React, { Component } from "react";
import { connect } from "react-redux";
import { Container,  Table } from "semantic-ui-react";

class BILevel extends Component {
  constructor(props) {
    super(props);
    this.state = this.props;
    
  }

  render() {
    return <Container>
          <Table compact>
              <Table.Header >
                <Table.Row>
                  <Table.HeaderCell>PIVOT </Table.HeaderCell>
            <Table.HeaderCell >   {Number(this.state.data.centerpoint,2).toFixed(2)}
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Support</Table.HeaderCell>
                  <Table.HeaderCell>Resistance</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {
                this.state.data.support.map((listValue, index) => {
                  return <Table.Row key={index}>
                    <Table.Cell className={['support', listValue.style].join(' ')} >
                      {listValue.vals>0?Number(listValue.vals, 2).toFixed(2):""}
                      </Table.Cell>
                    <Table.Cell className={['resistance', this.state.data.resistance[index].style].join(' ')}>
                        
                      {this.state.data.resistance[index].vals>0? Number(this.state.data.resistance[index].vals,2).toFixed(2):""}
                      </Table.Cell>
                    </Table.Row>;
                })
              }
                
              </Table.Body>
            </Table>
          
      </Container>;
  }
}
const mapStateToProps = state => {
  return {
    OpenIntOutlook: state
  };
};
const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BILevel);
